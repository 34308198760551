var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"isOpen":_vm.isModalOpen(),"content-classes":"px-4 sm:px-0 pt-8 sm:pt-16 md:pt-32 mb-12","z-index-number":_vm.getZIndex()}},[[_c('div',{staticClass:"modal-inner w-full text-gray-700",class:[0, null].includes(_vm.invoice.total) ? 'max-w-xl' : 'max-w-3xl'},[_c('div',{staticClass:"p-4 sm:p-6 sm:pt-5"},[_c('h3',{staticClass:"font-medium text-lg mb-3"},[_vm._v("Répartition par catégorie")]),_vm._v(" "),_c('ModalCloseButton',{nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}}),_vm._v(" "),([0, null].includes(_vm.invoice.total))?_c('div',{staticClass:"text-center text-gray-600 text-sm"},[_c('img',{staticClass:"w-32 mx-auto mb-2",attrs:{"src":require("assets/img/wall-construction.svg")}}),_vm._v(" "),_c('p',{staticClass:"max-w-sm mx-auto mb-2"},[_vm._v("\n            Vous devez indiquer le montant de cette facture pour pouvoir le répartir.\n          ")])]):[_c('div',{staticClass:"space-y-3 mb-3"},[_vm._l((_vm.categories),function(category,index){
var _obj;
return _c('div',{key:("invoice_futureCategory_" + (category.category.id)),staticClass:"border border-gray-200 rounded-lg p-2 flex space-x-2"},[_c('div',{staticClass:"flex items-center shrink-0 w-36"},[_c('CategoryBadge',{attrs:{"category":category.category,"max-width":"max-w-[7.5rem]"}})],1),_vm._v(" "),_c('div',{staticClass:"w-full flex items-center pl-2 pr-4"},[_c('div',{staticClass:"w-full"},[_c('PercentSlider',{attrs:{"total":_vm.invoice.total,"min":0,"max":_vm.invoice.total,"step":0.01,"available":_vm.availableProgress > 0 ? _vm.availableProgress : 0,"styles":( _obj = {}, _obj["--barColor"] = _vm.darken(_vm.pickColor(category.category.id, true), 30), _obj["--availableColor"] = _vm.darken(_vm.pickColor(category.category.id, true), 10), _obj )},on:{"input":function($event){return _vm.updateAmount(category, $event)}},model:{value:(category.amount),callback:function ($$v) {_vm.$set(category, "amount", $$v)},expression:"category.amount"}})],1)]),_vm._v(" "),_c('div',[_c('div',{staticClass:"relative flex items-center border border-gray-300 rounded-md"},[_c('CurrencyInput',{staticClass:"w-20 text-gray-500 p-2 number-input bg-transparent text-sm block focus:outline-none text-right",attrs:{"value":category.weight,"placeholder":"0","updateModel":false,"external-updates":"","options":{
                      currency: 'EUR',
                      currencyDisplay: 'hidden',
                      locale: 'fr',
                      precision: { min: 0, max: 4 },
                      distractionFree: false,
                      allowNegative: false,
                      autoDecimalDigits: false,
                      valueRange: { min: 0, max: 100 },
                    }},on:{"input":function($event){return _vm.updatePercent(category, $event)}}}),_vm._v(" "),_c('div',{staticClass:"flex h-full items-center pr-2"},[_vm._v("%")])],1)]),_vm._v(" "),_c('div',[_c('div',{staticClass:"relative flex items-center border border-gray-300 rounded-md"},[_c('CurrencyInput',{staticClass:"p-2 text-gray-700 number-input bg-transparent text-sm block w-full focus:outline-none text-right",attrs:{"value":category.amount,"placeholder":"0","updateModel":false,"options":{
                      currency: 'EUR',
                      currencyDisplay: 'hidden',
                      locale: 'fr',
                      precision: { min: 0, max: 2 },
                      distractionFree: false,
                      allowNegative: false,
                      autoDecimalDigits: false,
                      valueRange: { min: 0, max: _vm.invoice.total },
                    }},on:{"input":function($event){return _vm.updateAmount(category, $event)}}}),_vm._v(" "),_c('div',{staticClass:"flex h-full items-center pr-2"},[_vm._v("€")])],1)])])}),_vm._v(" "),_c('div',{staticClass:"px-3 py-2 text-sm rounded-md duration-100",class:_vm.allocatedAmount < _vm.invoice.total ? 'bg-orange-100 text-orange-500' : 'bg-green-100 text-green-600'},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-sm"},[_vm._v("Répartition totale")]),_vm._v(" "),_c('span',{staticClass:"ml-auto"},[(_vm.allocatedAmount < _vm.invoice.total)?_c('span',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'triangle-exclamation']}})],1):_vm._e(),_vm._v("\n                  "+_vm._s(_vm.$format.number(_vm.allocatedPercent))+" %")])]),_vm._v(" "),(_vm.allocatedAmount < _vm.invoice.total)?_c('div',{staticClass:"flex mt-2"},[_c('div',{staticClass:"text-sm"},[_vm._v("Reste à répartir")]),_vm._v(" "),_c('span',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(_vm.$format.currency((_vm.invoice.total - _vm.allocatedAmount) * 100)))])]):_vm._e()])],2),_vm._v(" "),_c('div',{staticClass:"flex"},[_c('Button',{staticClass:"ml-auto mr-2",attrs:{"minimal":""},on:{"clicked":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("layout.action.cancel")))]),_vm._v(" "),_c('Button',{attrs:{"color":"brand","disabled":_vm.allocatedAmount !== _vm.invoice.total,"loading":_vm.isLoading('delete_item'),"error":_vm.hasErrorState('delete_item')},on:{"clicked":_vm.updateCategories}},[_vm._v(_vm._s(_vm.$t("layout.action.save")))])],1)]],2)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }