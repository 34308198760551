import { cloneDeep as _cloneDeep, get as _get, has as _has, set as _set } from 'lodash-es'
import { nanoid } from 'nanoid/non-secure'
import { kebabCase } from 'scule'
import { add, differenceInHours, format, isValid, parse, set } from 'date-fns'

import {
  convertSupplies,
  DOCUMENT_FIELDS_NULLABLE,
  DOCUMENT_FIELDS_PROCESS,
  DOCUMENT_FIELDS_READ_ONLY,
  DOCUMENT_FIELDS_TYPE_DATE,
  DOCUMENT_FIELDS_TYPE_NUMERIC,
  DOCUMENT_FIELDS_WRITE_ONLY,
  getDefaultState,
  getLineIndex,
  getNormalizedLines,
  getRandomString,
  newLine,
} from '~/utils/documents'

import {
  AMOUNT_TYPE_PERCENT,
  AMOUNT_TYPE_SUBTOTAL,
  DISCOUNT_TYPE_AMOUNT,
  DISCOUNT_TYPE_PERCENT,
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_SUBTYPE_DOWN_PAYMENT,
  DOCUMENT_SUBTYPE_SITUATION,
  DOWN_PAYMENT_TIME_SIGNATURE,
  INVOICE,
  LINE_TYPE_DOWN_PAYMENT,
  LINE_TYPE_GROUP,
  LINE_TYPE_LINE_BREAK,
  LINE_TYPE_PAGE_BREAK,
  LINE_TYPE_PRODUCT,
  LINE_TYPE_SITUATION,
  LINE_TYPE_SUBTOTAL,
  LINE_TYPE_TEXT,
  ORDER_FORM,
  QUOTE,
  WORK_ORDER,
} from '@/constants/documents'

import {
  PRODUCT_TYPE_SUPPLY,
  PRODUCT_TYPE_WORK,
  PRODUCT_TYPE_WORK_DETAILED,
  PRODUCT_TYPE_WORKFORCE,
  PRODUCT_TYPES_WORKS,
} from '@/constants/products'
import { pluralizeType } from '@/api/resources'
import { roundToPrecision, roundToTwo } from '@/utils/numbers'
import { getAddressString } from '@/utils/normalizers'
import { withQuery } from 'ufo'

export const state = () => getDefaultState()

const getInsertionParentOrNull = (state) => {
  let currentTree = [...state.tree]

  let lastOfTree = currentTree.pop()
  let lastLineType = null

  const index = state.lines.findIndex((el) => el.key === lastOfTree)
  if (index !== -1) {
    lastLineType = _get(state.lines, `${index}.type`, 'product')
  }

  if (lastLineType === 'group') {
    return lastOfTree
  }

  return null
}

export const getters = {
  getData: (state) => (key) => state[key],
  hasGlobalVat: (state) => state.settings.globalVat,
  getChildTree2: (state) => (key) => _get(state.childTree, key, []),
  getChildTree: (state) => {
    return state.childTree
    // WTF
    /*return Object.keys(state.childTree).reduce((obj, key) => {
      obj[key] = state.childTree[key]
      return obj
    }, {})*/
  },
  getChildrenRecursive: (state) => (key) => {
    let childTree = { ...state.childTree }
    let children = []
    ;(function findChildren(group) {
      let currentChildren = _get(childTree, group, [])

      for (const child of currentChildren) {
        children.push(child)
        findChildren(child)
      }
    })(key)

    return [...new Set(children)]
  },
  getAncestors: (state) => (key) => {
    let childTree = { ...state.childTree }
    let ancestors = []
    ;(function findParent(child) {
      let parent = null
      for (const [k, v] of Object.entries(childTree)) {
        if (v.includes(child)) {
          parent = state.lines.find((el) => el.key === k)
        }
      }

      if (parent) {
        ancestors.push(parent)
        findParent(parent.key)
      }
    })(key)

    return [...new Set(ancestors)]
  },
  //getLine: (state) => (key) => state.lines.find((line) => line.key === key),
  getLine: (state) => {
    let lines = {}
    for (const item of state.lines) {
      lines[item.key] = item
    }
    return lines
  },
  getLineTotal2:
    (state) =>
    (key, ratio = 1) => {
      const defaultCoefficient = _get(state.company, 'defaultCoefficient', 0)

      const round = (value, currency) => {
        if (['XAF', 'XOF', 'XPF'].includes((currency || _get(state.company, 'currency', 'EUR')).toUpperCase())) {
          return Math.round(Math.round(value / 100) * 100)
        }

        return Math.round(value)
      }

      let line = state.lines.find((el) => el.key === key)

      let data = {
        subtotal: 0,
        grossSubtotal: 0,
        taxAmount: 0,
        plannedSubtotal: 0,
        plannedGrossSubtotal: 0,
        plannedTaxAmount: 0,
        margin: 0,
        grossMargin: 0,
        marginRate: 0,
        grossMarginRate: 0,
        quantity: 0,
        buyPrice: 0,
        totalCost: 0,
        total: 0,
      }

      let sellPrice = line.sellPrice

      if (line.quoteQuantity) {
        data.plannedSubtotal = data.total = round(sellPrice * line.quoteQuantity * ratio)
        data.plannedGrossSubtotal = data.total = round(sellPrice * line.quoteQuantity * ratio)

        let percentOff = Number(line.percentOff)
        let amountOff = Number(line.amountOff)

        if (line.sellPrice > 0) {
          if (percentOff > 0) {
            let amount = (data.plannedSubtotal * percentOff) / 100
            data.plannedSubtotal -= amount
          } else if (amountOff > 0) {
            data.plannedSubtotal -= amountOff
          }
        }

        if (_has(line.tax, 'rate')) {
          // tax.rate is a monetary integer (20% = 2000)
          const taxRate = line.tax.rate / 10000
          data.plannedTaxAmount = round(data.plannedSubtotal * taxRate)
          //console.log(data.subtotal, taxRate)
          data.plannedTotal += data.plannedTaxAmount
        }
      }

      if (line.quantity) {
        data.subtotal = data.total = round(sellPrice * line.quantity * ratio)
        data.grossSubtotal = data.total = round(sellPrice * line.quantity * ratio)

        if (line.sellPrice > 0) {
          let percentOff = Number(line.percentOff)
          let amountOff = Number(line.amountOff)

          if (percentOff > 0) {
            let amount = (data.subtotal * percentOff) / 100
            data.subtotal -= amount
          } else if (amountOff > 0) {
            data.subtotal -= amountOff
          }
        }

        if (_has(line.tax, 'rate')) {
          // tax.rate is a monetary integer (20% = 2000)
          const taxRate = line.tax.rate / 10000
          data.taxAmount = round(data.subtotal * taxRate)
          //console.log(data.subtotal, taxRate)
          data.total += data.taxAmount
        }
      }

      if (line.buyPrice) {
        data.buyPrice = line.buyPrice
        data.totalCost =
          defaultCoefficient > 0 ? line.buyPrice + (line.buyPrice * defaultCoefficient) / 100 : line.buyPrice

        data.margin = sellPrice - data.totalCost
        data.grossMargin = sellPrice - line.buyPrice

        data.marginRate = (data.grossMargin / data.totalCost) * 100
        data.grossMarginRate = (data.grossMargin / data.buyPrice) * 100
      } else {
        data.margin = sellPrice
        data.grossMargin = sellPrice
      }

      return data
    },
  getLineTotal: (state) => {
    let total = {}
    let lines = _cloneDeep(state.lines)

    const defaultCoefficient = _get(state.company, 'defaultCoefficient', 0)

    const round = (value, currency) => {
      if (['XAF', 'XOF', 'XPF'].includes((currency || _get(state.company, 'currency', 'EUR')).toUpperCase())) {
        return Math.round(Math.round(value / 100) * 100)
      }

      return Math.round(value)
    }

    for (const line of lines.filter((el) =>
      [LINE_TYPE_SITUATION, LINE_TYPE_DOWN_PAYMENT, LINE_TYPE_PRODUCT].includes(el.type)
    )) {
      let data = {
        subtotal: 0,
        grossSubtotal: 0,
        taxAmount: 0,
        plannedSubtotal: 0,
        plannedGrossSubtotal: 0,
        plannedTaxAmount: 0,
        margin: 0,
        grossMargin: 0,
        marginRate: 0,
        grossMarginRate: 0,
        quantity: line.quantity || 0,
        buyPrice: 0,
        totalCost: 0,
        quotedSubtotal: 0,
        quotedTotal: 0,
        total: 0,
      }

      let sellPrice = line.sellPrice

      if (line.quoteQuantity) {
        data.plannedSubtotal = data.total = round(sellPrice * line.quoteQuantity)
        data.plannedGrossSubtotal = data.total = round(sellPrice * line.quoteQuantity)

        if (line.sellPrice > 0) {
          let percentOff = Number(line.percentOff)
          let amountOff = Number(line.amountOff)

          if (percentOff > 0) {
            let amount = (data.plannedSubtotal * percentOff) / 100
            data.plannedSubtotal -= amount
          } else if (amountOff > 0) {
            data.plannedSubtotal -= amountOff
          }
        }

        if (_has(line.tax, 'rate')) {
          // tax.rate is a monetary integer (20% = 2000)
          const taxRate = line.tax.rate / 10000
          data.plannedTaxAmount = round(data.plannedSubtotal * taxRate)
          //console.log(data.subtotal, taxRate)
          data.plannedTotal += data.plannedTaxAmount
        }
      }

      if (line.quantity) {
        data.subtotal = data.total = round(sellPrice * line.quantity)
        data.grossSubtotal = data.total = round(sellPrice * line.quantity)
        if (line.sellPrice > 0) {
          let percentOff = Number(line.percentOff)
          let amountOff = Number(line.amountOff)

          if (percentOff > 0) {
            let amount = (data.subtotal * percentOff) / 100
            data.subtotal -= amount
          } else if (amountOff > 0) {
            data.subtotal -= amountOff
          }
        }

        if (_has(line.tax, 'rate')) {
          // tax.rate is a monetary integer (20% = 2000)
          const taxRate = line.tax.rate / 10000
          data.taxAmount = round(data.subtotal * taxRate)
          //console.log(data.subtotal, taxRate)
          data.total += data.taxAmount
        }
      }

      if (line.quoteQuantity) {
        data.quotedSubtotal = data.total = round(sellPrice * line.quantity)

        let percentOff = Number(line.percentOff)
        let amountOff = Number(line.amountOff)

        if (percentOff > 0) {
          let amount = (data.subtotal * percentOff) / 100
          data.quotedSubtotal -= amount
        } else if (amountOff > 0) {
          data.quotedSubtotal -= amountOff
        }

        if (_has(line.tax, 'rate')) {
          // tax.rate is a monetary integer (20% = 2000)
          const taxRate = line.tax.rate / 10000
          data.taxAmount = round(data.subtotal * taxRate)
          //console.log(data.subtotal, taxRate)
          data.quotedTotal += data.taxAmount
        }
      }

      if (line.buyPrice) {
        data.buyPrice = line.buyPrice
        data.totalCost =
          defaultCoefficient > 0 ? line.buyPrice + (line.buyPrice * defaultCoefficient) / 100 : line.buyPrice

        data.margin = sellPrice - data.totalCost
        data.grossMargin = sellPrice - line.buyPrice

        data.marginRate = (data.grossMargin / data.totalCost) * 100
        data.grossMarginRate = (data.grossMargin / data.buyPrice) * 100
      } else {
        data.margin = sellPrice
        data.grossMargin = sellPrice
      }

      total[line.key] = data
    }

    return { ...total }
  },
  getGroupTotal:
    (state, getters) =>
    ({ key, groupIsOptional: parentIsOptional }) => {
      let groupSubtotal = 0
      let group = state.lines.find((el) => el.key === key)
      let childTree = { ...state.childTree }
      let lines = [].concat(state.lines)

      ;(function findChildren(group, groupIsOptional = false) {
        if (group) {
          let currentChildren = _get(childTree, group.key, [])
          for (const child of currentChildren) {
            let line = lines.find((el) => el.key === child)
            if (line) {
              if (!line.optional || group.optional || groupIsOptional) {
                const childTotal = getters.getLineTotal2(child)

                if (childTotal) {
                  groupSubtotal += childTotal.subtotal
                }

                if (line.type === LINE_TYPE_GROUP) {
                  findChildren(line, group.optional)
                }
              }
            }
          }
        }
      })(group, parentIsOptional)

      /*const uniqueChildren = [...new Set(children)]

    for (const child of uniqueChildren) {
      let line = lines.find((el) => el.key === child)
      if (!line.optional || group.optional) {
        const childTotal = getters.getLineTotal2(child)

        if (childTotal) {
          groupSubtotal += childTotal.subtotal
        }
      }
    }*/

      return groupSubtotal
    },
  getTreeType: (state) => {
    const firstOfTree = _get(state, 'tree[0]', null)
    if (firstOfTree !== null) {
      const index = state.lines.findIndex((el) => el.key === firstOfTree)
      if (index !== -1) {
        return _get(state.lines, `${index}.type`, 'product')
      }
    }
    return 'product'
  },
  getIndexes: (state) => {
    let index = 1
    let indexes = {}

    for (const key of state.tree) {
      const line = state.lines.find((el) => el.key === key)
      indexes[key] = index
      ;(function makeChildIndexes(parentKey, parentIndex) {
        const childTree = state.childTree[parentKey]
        if (childTree) {
          let childIndex = 1
          for (const key of childTree) {
            const childLine = state.lines.find((el) => el.key === key)
            indexes[key] = `${parentIndex}.${childIndex}`
            makeChildIndexes(key, `${parentIndex}.${childIndex}`)
            if (
              childLine &&
              ![LINE_TYPE_TEXT, LINE_TYPE_PAGE_BREAK, LINE_TYPE_LINE_BREAK, LINE_TYPE_SUBTOTAL].includes(childLine.type)
            ) {
              ++childIndex
            }
          }
          indexes[`${parentKey}_next`] = childIndex++
        }
      })(key, index)
      if (
        line &&
        ![LINE_TYPE_TEXT, LINE_TYPE_PAGE_BREAK, LINE_TYPE_LINE_BREAK, LINE_TYPE_SUBTOTAL].includes(line.type)
      ) {
        ++index
      }
    }
    return indexes
  },
  getMaxDepth: (state, getters) => {
    let indexes = getters.getIndexes
    return Object.values(indexes).reduce((length, index) => {
      const currentLength = index.toString().split('.').length
      if (currentLength > length) length = currentLength

      return length
    }, 0)
  },
  getDepth: (state) => (key) => {
    let depth = 0
    ;(function findChildren(group) {
      let currentChildren = _get(state.childTree, group, [])
      for (const child of currentChildren) {
        const line = state.lines.find((line) => line.key === child)
        if (line && line.type === 'group') depth++
        findChildren(child)
      }
    })(key)

    return depth
  },
  isSituationComplete: (state) => {
    if (state.subtype !== DOCUMENT_SUBTYPE_SITUATION) return false
    for (const line of state.lines.filter((el) => el.type === LINE_TYPE_PRODUCT)) {
      if (line.invoicedQuantity + line.quantity < line.quoteQuantity) {
        return false
      }
    }
    return true
  },
  getInvoicesSummary: (state) => (totals) => {
    return state.invoicesSummary.reduce(
      (obj, invoice) => {
        if (state.downPaymentsDeduction === 'subtotal' || invoice.subtype !== DOCUMENT_SUBTYPE_DOWN_PAYMENT) {
          obj.acc.subtotal += invoice.subtotal
          obj.acc.additionalSubtotal += invoice.additionalSubtotal
          obj.acc.additionalTotal += invoice.additionalTotal
          obj.acc.depreciationSubtotal += invoice.additionalSubtotal
          obj.acc.depreciationTotal += invoice.additionalTotal
          obj.acc.total += invoice.total
        }
        obj.invoices.push({ ...invoice, acc: { ...obj.acc } })
        return obj
      },
      {
        invoices: [],
        acc: {
          total: 0,
          subtotal: 0,
          additionalTotal: 0,
          additionalSubtotal: 0,
          depreciationTotal: 0,
          depreciationSubtotal: 0,
        },
      }
    )
  },
  hasDiscountedLines: (state) => {
    for (const line of state.lines) {
      if (line.showDiscount === true && (Number(line.amountOff) > 0 || Number(line.percentOff) > 0)) {
        return true
      }
    }
    return false
  },
  hasOptionalLines: (state) => {
    let count = 0
    for (const line of state.lines) {
      if (line.optional === true) {
        count++
      }
    }
    return count
  },
  hasAdditionalLines: (state) => {
    for (const line of state.lines) {
      if (line.additional === true) {
        return true
      }
    }
    return false
  },
}

export const mutations = {
  setId(state, id) {
    state.id = id
  },

  setVisible(state, { line, isVisible }) {
    let visible = new Set(state.visibleLines)

    if (isVisible) {
      visible.add(line)
    } else {
      visible.delete(line)
    }
    state.visibleLines = Array.from(visible)
  },

  loadUnits(state, units) {
    state.units = units
  },

  loadPlanItems(state, planItems) {
    state.planItems = planItems
  },

  setAutoSave(state, params) {
    let builderAutoSave = state.company.builderAutoSave
    let builderAutoSaveOverwrite = state.company.builderAutoSaveOverwrite

    state.company.builderAutoSave = params.builderAutoSave
    state.company.builderAutoSaveOverwrite = params.builderAutoSaveOverwrite

    for (const line of state.lines) {
      const index = getLineIndex(state, line.key)

      if (builderAutoSave !== state.company.builderAutoSave) {
        if (state.company.builderAutoSave === false) {
          _set(state, `lines.${index}.persist`, false)
        } else {
          if (line.isNew) {
            _set(state, `lines.${index}.persist`, true)
          }
        }
      }

      if (builderAutoSaveOverwrite !== state.company.builderAutoSaveOverwrite && !line.isNew) {
        _set(state, `lines.${index}.persist`, state.company.builderAutoSaveOverwrite)
      }
    }
  },

  detachFromQuote(state) {
    state.quote = null
    state.subtype = null
    state.dirty = true
    state.touched = true
  },

  setCompany(state, company) {
    state.company = company
  },

  setAssignee(state, assignee) {
    state.assignee = assignee
  },

  setDragging(state, dragging) {
    state.dragging = dragging
  },

  convertLineSupplies(state, params) {
    if (PRODUCT_TYPES_WORKS.includes(params.to)) {
      const index = getLineIndex(state, params.key)
      const line = _get(state, `lines.${index}`, null)

      if (line) {
        const { supplies, sellPrices, buyPrices } = convertSupplies(_get(line, 'supplies', []), params)

        if (params.from === PRODUCT_TYPE_WORK) {
          if (line.sellPriceLocked && sellPrices > 0) {
            line.sellPrice = sellPrices
          }

          if (buyPrices > 0) {
            line.buyPrice = buyPrices
          }
        }

        line.supplies = supplies
      }
    }
  },

  removeCustomer(state) {
    state.customer = null
    // TODO use actions / commit
    state.address = {
      street: '',
      complement: '',
      postal_code: '',
      city: '',
      country: _get(state.company, 'country', 'fr'),
    }
    state.dirty = true
    state.touched = true
  },

  removeSupplier(state) {
    state.supplier = null
    // TODO use actions / commit
    state.address = {
      street: '',
      complement: '',
      postal_code: '',
      city: '',
      country: _get(state.company, 'country', 'fr'),
    }
    state.dirty = true
    state.touched = true
  },

  getPrimaryAddress(state, type = 'customer') {
    let primaryAddress = _get(state, `${type}.addresses`, []).filter((el) => el.primary === true)[0]
    if (primaryAddress) {
      state.address = primaryAddress.address
    } else {
      // TODO use actions / commit
      state.address = {
        street: '',
        complement: '',
        postal_code: '',
        city: '',
        country: '',
      }
    }
  },

  removeAddress(state) {
    // TODO use actions / commit
    state.address = {
      street: '',
      complement: '',
      postal_code: '',
      city: '',
      country: '',
    }
  },
  setAddress(state, { address, addresses }) {
    if (address.name) {
      state.addressLabel = address.name
    } else {
      const currentLabel = state.addressLabel
      if ([].concat(addresses.map((el) => el.name)).includes(currentLabel)) {
        state.addressLabel = null
      }
    }

    state.address = address.address
    state.dirty = true
    state.touched = true
  },

  setDeliveryAddress(state, { address, addresses }) {
    if (address.name) {
      state.deliveryAddressLabel = address.name
    } else {
      const currentLabel = state.deliveryAddressLabel
      if ([].concat(addresses.map((el) => el.name)).includes(currentLabel)) {
        state.deliveryAddressLabel = null
      }
    }

    state.deliveryAddress = address.address
    state.dirty = true
    state.touched = true
  },

  setWorkAddress(state, { address, addresses }) {
    if (address.name) {
      state.workAddressLabel = address.name
    } else {
      const currentLabel = state.workAddressLabel
      if ([].concat(addresses.map((el) => el.name)).includes(currentLabel)) {
        state.workAddressLabel = null
      }
    }

    state.workAddress = address.address
    state.dirty = true
    state.touched = true
  },

  moveLine(state, params) {
    if (_get(params, 'parent')) {
      if (_get(state.childTree, params.parent)) {
        const childTree = { ...state.childTree }

        const parentIndex = state.tree.findIndex((el) => el === params.parent)
        const childIndex = childTree[params.parent].findIndex((el) => el === params.key)

        switch (params.direction) {
          case 'up':
            if (childIndex >= 1) {
              let siblingKey = _get(childTree[params.parent], childIndex - 1, null)
              if (siblingKey) {
                // Has sibling
                let siblingLine = state.lines.find((el) => el.key === siblingKey)
                let line = state.lines.find((el) => el.key === params.key)

                if (siblingLine && siblingLine.type === LINE_TYPE_GROUP && line && line.type !== LINE_TYPE_GROUP) {
                  // If moving a line and sibling is a group, push inside
                  childTree[siblingLine.key].push(childTree[params.parent].splice(childIndex, 1)[0])
                } else {
                  childTree[params.parent].splice(childIndex - 1, 0, childTree[params.parent].splice(childIndex, 1)[0])
                }
              } else {
                childTree[params.parent].splice(childIndex - 1, 0, childTree[params.parent].splice(childIndex, 1)[0])
              }
            } else {
              // Element is first of its parent
              let parentParent = Object.entries(state.childTree).reduce((key, [k, v]) => {
                if (v.includes(params.parent)) {
                  key = k
                }
                return key
              }, null)

              // If element parent has a parent
              if (parentParent) {
                const siblingParentIndex = childTree[parentParent].findIndex((el) => el === params.parent)

                if (siblingParentIndex !== -1) {
                  childTree[parentParent].splice(
                    siblingParentIndex,
                    0,
                    childTree[params.parent].splice(childIndex, 1)[0]
                  )
                }
              } else {
                state.tree.splice(parentIndex, 0, childTree[params.parent].splice(childIndex, 1)[0])
              }

              // Pull to the previous parent
              /*if (parentIndex >= 1) {
                const previousParent = state.tree[parentIndex - 1]
                let currentChild = childTree[params.parent].splice(childIndex, 1)[0]
                if (currentChild) {
                  if (_has(childTree, previousParent)) {
                    childTree[previousParent].push(currentChild)
                  } else {
                    childTree[previousParent] = [currentChild]
                  }
                }
              }*/
            }
            break
          case 'down':
            if (childIndex < childTree[params.parent].length - 1) {
              let siblingKey = _get(childTree[params.parent], childIndex + 1, null)
              if (siblingKey) {
                // Has sibling
                let siblingLine = state.lines.find((el) => el.key === siblingKey)
                let line = state.lines.find((el) => el.key === params.key)
                if (siblingLine && siblingLine.type === LINE_TYPE_GROUP && line && line.type !== LINE_TYPE_GROUP) {
                  // If sibling is a group, pop inside
                  childTree[siblingLine.key].splice(0, 0, childTree[params.parent].splice(childIndex, 1)[0])
                } else {
                  childTree[params.parent].splice(childIndex + 1, 0, childTree[params.parent].splice(childIndex, 1)[0])
                }
              } else {
                childTree[params.parent].splice(childIndex + 1, 0, childTree[params.parent].splice(childIndex, 1)[0])
              }

              //childTree[params.parent].splice(childIndex + 1, 0, childTree[params.parent].splice(childIndex, 1)[0])
            } else {
              let parentParent = Object.entries(state.childTree).reduce((key, [k, v]) => {
                if (v.includes(params.parent)) {
                  key = k
                }
                return key
              }, null)

              // If element parent has a parent
              if (parentParent) {
                const siblingParentIndex = childTree[parentParent].findIndex((el) => el === params.parent)
                if (siblingParentIndex !== -1) {
                  childTree[parentParent].splice(
                    siblingParentIndex + 1,
                    0,
                    childTree[params.parent].splice(childIndex, 1)[0]
                  )
                }
              } else {
                state.tree.splice(parentIndex + 1, 0, childTree[params.parent].splice(childIndex, 1)[0])
              }

              // Push to the next parent
              /*if (parentIndex + 1 < state.tree.length) {
                const previousParent = state.tree[parentIndex + 1]
                let currentChild = childTree[params.parent].splice(childIndex, 1)[0]
                if (currentChild) {
                  if (_has(childTree, previousParent)) {
                    childTree[previousParent].unshift(currentChild)
                  } else {
                    childTree[previousParent] = [currentChild]
                  }
                }
              }*/
            }
            break
        }

        state.childTree = childTree
      }
    } else {
      const index = state.tree.findIndex((el) => el === params.key)
      const childTree = { ...state.childTree }
      switch (params.direction) {
        case 'up':
          if (index >= 1) {
            let siblingKey = _get(state.tree, index - 1, null)
            if (siblingKey) {
              // Has sibling
              let siblingLine = state.lines.find((el) => el.key === siblingKey)
              let line = state.lines.find((el) => el.key === params.key)
              if (siblingLine && siblingLine.type === LINE_TYPE_GROUP && line && line.type !== LINE_TYPE_GROUP) {
                // If sibling is a group, push inside
                childTree[siblingLine.key].push(state.tree.splice(index, 1)[0])
              } else {
                state.tree.splice(index - 1, 0, state.tree.splice(index, 1)[0])
              }
            } else {
              state.tree.splice(index - 1, 0, state.tree.splice(index, 1)[0])
            }
          }
          break
        case 'down':
          if (index <= state.tree.length && index !== -1) {
            let siblingKey = _get(state.tree, index + 1, null)
            if (siblingKey) {
              // Has sibling
              let siblingLine = state.lines.find((el) => el.key === siblingKey)
              let line = state.lines.find((el) => el.key === params.key)
              if (siblingLine && siblingLine.type === LINE_TYPE_GROUP && line && line.type !== LINE_TYPE_GROUP) {
                // If sibling is a group, pop inside
                childTree[siblingLine.key].splice(0, 0, state.tree.splice(index, 1)[0])
              } else {
                state.tree.splice(index + 1, 0, state.tree.splice(index, 1)[0])
              }
            } else {
              state.tree.splice(index + 1, 0, state.tree.splice(index, 1)[0])
            }
          }
          break
      }

      state.childTree = childTree
    }
  },

  setInvoicesSummary(state, data) {
    state.invoicesSummary = data
  },

  resetState(state) {
    state.init = false
    state.dirty = false
    state.broken = false
    state.confirmCloseRevision = false
    state.saving = false
    state.closing = false
    state.deleting = false
    state.company = null
    state.invoicesSummary = []
    state.visibleLines = []
    for (const [k, v] of Object.entries(getDefaultState())) {
      if (!['units', 'planItems', 'company'].includes(k) && k !== 'revisionReason') {
        _set(state, k, v)
      }
    }
    try {
      sessionStorage.removeItem('lockedId')
    } catch (err) {}
  },

  addDownPayment(state) {
    state.downPayments.push({
      key: getRandomString(4),
      value: 10,
      time: DOWN_PAYMENT_TIME_SIGNATURE,
    })
  },

  setData(state, params) {
    if (params.key && _has(params, 'val')) {
      _set(state, params.key, params.val)
      if (_get(params, 'untracked', undefined) !== true) {
        state.dirty = true
        state.touched = true
      }
    }
  },

  selectLine(state, line = null) {
    state.activeLine = line
  },

  selectGroup(state, group = null) {
    state.activeGroup = group
  },

  setSaving(state, saving) {
    state.saving = saving
  },

  setClosing(state, closing) {
    state.closing = closing
  },

  setDeleting(state, deleting) {
    state.deleting = deleting
  },

  setUpdatedAt(state, date) {
    state.updatedAt = date
  },

  applyMarginRate(state, params) {
    state.lines.forEach((line) => {
      // Applies margin on work_detailed supplies
      console.log(line)

      if (line.productType === PRODUCT_TYPE_WORK_DETAILED && !line.sellPriceForced) {
        for (let supply of line.supplies) {
          if (
            !supply.lockSellPrice &&
            (params.marginType === 'all' || _get(supply, 'element.type') === params.marginType)
          ) {
            const buyPrice = _get(supply, 'element.buyPrice', 0)
            if (buyPrice > 0) {
              const grossMargin = (buyPrice * params.marginRate) / 100
              supply.element.sellPrice = roundToTwo(buyPrice + grossMargin)
            }
          }
        }

        line.sellPrice = line.supplies.reduce((total, item) => {
          if (item.element.sellPrice) {
            total += Math.round(item.element.sellPrice * item.quantity * 100) / 100
          }
          return total
        }, 0)

        line.dirty = true
      } else if (
        !line.sellPriceLocked &&
        (line.productType !== PRODUCT_TYPE_WORK_DETAILED || line.sellPriceForced) &&
        (params.marginType === 'all' ||
          params.marginType === line.productType ||
          (params.marginType === PRODUCT_TYPE_WORK && line.productType === PRODUCT_TYPE_WORK_DETAILED))
      ) {
        const buyPrice = _get(line, 'buyPrice', 0)
        if (buyPrice > 0) {
          const grossMargin = Math.round((buyPrice * params.marginRate) / 100)
          line.sellPrice = roundToTwo(buyPrice + grossMargin)
          line.dirty = true
        }
      }
    })
    state.dirty = true
    state.touched = true
  },

  applyAdjust(state, params) {
    state.lines.forEach((line) => {
      if (line.productType === PRODUCT_TYPE_WORK_DETAILED && !line.sellPriceForced) {
        for (let supply of line.supplies) {
          if (!supply.lockSellPrice) {
            const sellPrice = _get(supply, 'element.sellPrice', 0)
            const adjust = Math.round(sellPrice * params.adjustAmount) / 100
            if (sellPrice > 0) {
              if (params.adjustDirection === 'plus') {
                supply.element.sellPrice = roundToTwo(sellPrice + adjust)
              } else {
                supply.element.sellPrice = roundToTwo(sellPrice - adjust)
              }
            }
          }
        }

        line.sellPrice = line.supplies.reduce((total, item) => {
          if (item.element.sellPrice) {
            total += Math.round(item.element.sellPrice * item.quantity * 100) / 100
          }
          return total
        }, 0)

        line.dirty = true
      } else if (!line.sellPriceLocked && (line.productType !== PRODUCT_TYPE_WORK_DETAILED || line.sellPriceForced)) {
        const sellPrice = _get(line, 'sellPrice', 0)
        const adjust = Math.round(sellPrice * params.adjustAmount) / 100
        if (sellPrice > 0) {
          if (params.adjustDirection === 'plus') {
            line.sellPrice = roundToTwo(sellPrice + adjust)
          } else {
            line.sellPrice = roundToTwo(sellPrice - adjust)
          }
          line.dirty = true
        }
      }
    })
    state.dirty = true
    state.touched = true
  },

  applyTax(state, tax) {
    state.lines.forEach((line) => {
      if (line.type === LINE_TYPE_PRODUCT && tax) {
        line.tax = tax
        line.dirty = true
      }
    })
    state.dirty = true
    state.touched = true
  },

  setLineBuyPrice(state, params) {
    const { company } = this.$auth

    const index = getLineIndex(state, params.key)
    if (index !== -1) {
      const line = _get(state, `lines.${index}`, null)
      if (params.val === null) {
        line.buyPrice = null
      } else {
        line.buyPrice = Math.round(params.val * 100 * 100) / 100

        if (!line.sellPriceLocked && line.buyPrice > 0) {
          //const defaultCoefficient = _get(state.company, 'defaultCoefficient', 0)
          //const correctedBuyPrice = _get(line, 'buyPrice', 0) * (1 + defaultCoefficient / 100)

          const buyPrice = _get(line, 'buyPrice', 0)

          let marginType = line.productType

          if (marginType === PRODUCT_TYPE_WORK_DETAILED) {
            marginType = PRODUCT_TYPE_WORK
          }

          const applyMargin = _get(
            company.value,
            `defaultMargins.${marginType}`,
            _get(company.value, 'defaultMargin', 30)
          )
          const grossMargin = roundToTwo((buyPrice * applyMargin) / 100)

          line.sellPrice = roundToTwo(buyPrice + grossMargin)
        }
      }

      line.dirty = true
      state.dirty = true
      state.touched = true
    }
  },

  setLineSellPrice(state, params) {
    const index = getLineIndex(state, params.key)

    if (index !== -1) {
      const line = _get(state, `lines.${index}`, null)
      line.sellPrice = Math.round(params.val * 100 * 100) / 100
      line.dirty = true
      state.dirty = true
      state.touched = true
    }
  },

  setLineMargin(state, params) {
    const index = getLineIndex(state, params.key)
    if (index !== -1) {
      const line = _get(state, `lines.${index}`, null)

      if (line && !line.sellPriceLocked) {
        //const defaultCoefficient = _get(state.company, 'defaultCoefficient', 0)
        //const buyPrice = _get(line, 'buyPrice', 0) * (1 + defaultCoefficient / 100)
        const buyPrice = _get(line, 'buyPrice', 0)
        if (buyPrice > 0) {
          const grossMargin = (buyPrice * params.val) / 100
          //if (_get(window, 'document.activeElement.dataset.sp', null) !== line.key) {
          line.sellPrice = roundToTwo(buyPrice + grossMargin)
          //}
        }
      }
      line.dirty = true
      state.dirty = true
      state.touched = true
    }
  },

  setLineProduct(state, { params, index, defaultUnit, defaultTax }) {
    if (index !== -1) {
      _set(state, `lines.${index}.isNew`, false)
      _set(state, `lines.${index}.persist`, state.company.builderAutoSaveOverwrite)
      _set(state, `lines.${index}.dirty`, false)
      _set(
        state,
        `lines.${index}.description`,
        // See also emit('selectedResult') in useProductsSearch composable
        params.product.type === 'batichiffrage' ? params.product.longName : params.product.name
      )

      const productPropsDefaults = {
        libraryId: null,
        sellPrice: null,
        buyPrice: null,
        reference: null,
        sellPriceLocked: false,
        sellPriceForced: false,
        supplies: [],
        images: [],
        type: null,
        tax: defaultTax,
        unit: defaultUnit,
        source: null,
        sourceId: null,
      }

      _set(state, `lines.${index}.product`, _get(params.product, 'id', _get(params.product, 'uuid', null)))

      // Set product values
      for (const prop of Object.keys(productPropsDefaults)) {
        // Sets product.type as productType for the new Line
        let lineProp = prop === 'type' ? 'productType' : prop
        // Gets product value with default
        let val = _get(params.product, prop, productPropsDefaults[prop])

        if (val !== null) {
          /* Forces null tax if document is not taxable.
             This prevents an error if a product has a tax on a non-taxable company
             Happens if we switched from taxable to non-taxable on customer's request
           */
          if (state.taxable === false && lineProp === 'tax') {
            val = null
          }

          if (lineProp === 'supplies') {
            const defaultWorkItem =
              params.product.type === PRODUCT_TYPE_WORK
                ? { name: '' }
                : {
                    element: {
                      name: '',
                      buyPrice: null,
                      sellPrice: null,
                      reference: null,
                      planItem: null,
                      id: null,
                      unit: defaultUnit,
                      type: PRODUCT_TYPE_SUPPLY,
                    },
                    key: nanoid(),
                    id: null,
                    quantity: 1,
                    isNew: true,
                    lockSellPrice: false,
                  }

            if (params.product.type === PRODUCT_TYPE_WORK) {
              val = params.product.supplies.reduce((arr, el) => {
                arr.push({ name: el.name })
                return arr
              }, [])
            }

            if (params.product.type === PRODUCT_TYPE_WORK_DETAILED) {
              val = params.product.elements.reduce((arr, el) => {
                arr.push({
                  element: {
                    name: el.element.name,
                    reference: el.element.reference,
                    buyPrice: el.element.buyPrice,
                    sellPrice: el.element.sellPrice,
                    unit: el.element.unit,
                    type: el.element.type,
                    planItem: _get(el.element, 'planItem.id', null),
                    id: el.element.id,
                  },
                  key: nanoid(),
                  quantity: el.quantity,
                  lockSellPrice: false,
                })
                return arr
              }, [])
            }

            if (state.object !== ORDER_FORM) {
              if (val.length === 0) {
                val = [defaultWorkItem]
              }

              if (_get(val, `${val.length - 1}.element.name`, null) !== '') {
                val = val.concat([defaultWorkItem])
              }
            }
          }

          if (lineProp === 'productType' && val === 'batichiffrage') {
            _set(state, `lines.${index}.${lineProp}`, PRODUCT_TYPE_WORK_DETAILED)
          } else {
            // Don't import sellPrice for Order Forms
            if (!(lineProp === 'sellPrice' && state.object === ORDER_FORM)) {
              // Import buyPrice as sellPrice for Order Forms
              if (state.object === ORDER_FORM && lineProp === 'buyPrice') {
                lineProp = 'sellPrice'
              }
              _set(state, `lines.${index}.${lineProp}`, val)
            }
          }
        }
      }
    }

    let planItem = _get(params, 'product.planItem', null)
    if (planItem) {
      _set(state, `lines.${index}.planItem`, planItem)
    }

    state.dirty = true
    state.touched = true
  },

  populateData(state, data) {
    const { company } = this.$auth

    // Merge every needed field instead of whole data object (keeping reactivity)
    for (const key of DOCUMENT_FIELDS_PROCESS.concat(DOCUMENT_FIELDS_READ_ONLY)) {
      if (_has(data, key)) {
        let set = true
        let val = data[key]

        if (['expireAt', 'beginAt'].includes(key)) {
          set = val !== null
          if (set) {
            val = new Date(val)
          }
        }

        if (key === 'options') {
          set = false
          let options = _get(state, key)
          for (const optKey of Object.keys(options)) {
            if (_has(val, optKey)) {
              options[optKey] = val[optKey]
            }
          }
        }

        if (['workDuration'].includes(key)) {
          set = val !== null
        }

        if ('footer' === key && typeof val === 'string') {
          if (val.indexOf('<div>') === -1) {
            val = val.replaceAll('\n\n\n', '<div></div>')
            val = val.replaceAll('\n\n', '<div></div>')
            val = val
              .split('\n')
              .map((el) => `<div>${el}</div>`)
              .join('')
          }
        }

        if (DOCUMENT_FIELDS_TYPE_DATE.includes(key)) {
          if (val !== null) {
            if (val instanceof Date) val = val.toISOString()
            val = val.slice(0, 10)
            val = parse(val, 'yyyy-MM-dd', new Date())
          }
        }
        if (set) {
          _set(state, key, val)
        }
      } else {
        _set(state, key, getDefaultState()[key])
      }
    }

    if (state.issuedAt === null && state.expireAt === null && data.status === DOCUMENT_STATUS_DRAFT) {
      let expirationDays = _get(
        company.value,
        state.object === QUOTE ? 'quoteDefaultExpirationDays' : 'invoiceDefaultDaysUntilDue',
        30
      )
      state.expireAt = add(new Date(), { days: expirationDays })
    }

    if (_get(data, 'holdbackAmount', null) !== null) {
      let holdbackReleasedAt = _get(state, 'holdbackReleasedAt', null)
      if (holdbackReleasedAt !== null) holdbackReleasedAt = new Date(holdbackReleasedAt)
      else {
        holdbackReleasedAt = add(new Date(), { years: 1 })
      }
      state.holdbackReleasedAt = holdbackReleasedAt
    }

    state.lines = []
    state.childTree = {}
    state.tree = []

    const defaultUnit = _get(company.value, 'defaultUnit', [])
    const defaultTax = state.taxable ? company.value.defaultTax : null

    let lines = data.lines || []

    for (const line of lines) {
      const key = getRandomString(4)

      let isNew = !_get(line, 'product.id')

      if (Number(line.amountOff) > 0 || line.percentOff > 0) {
        line.showDiscount = true
        line.discountType = Number(line.amountOff) > 0 ? DISCOUNT_TYPE_AMOUNT : DISCOUNT_TYPE_PERCENT
      }

      let currentLine = newLine({ ...line, ...{ key: key, isNew } }, state.units)

      if (_get(currentLine, 'product.id')) {
        currentLine.persist = _get(company.value, 'builderAutoSaveOverwrite', false)
      }

      if (currentLine.unit === null) {
        currentLine.unit = defaultUnit
      }

      if (currentLine.tax === null) {
        currentLine.tax = defaultTax
      }

      state.lines.push(currentLine)
      state.tree.push(key)

      if (line.type === 'group') {
        if (!_get(state.childTree, key)) {
          state.childTree[key] = []
        }
        if (_has(line, 'lines')) {
          ;(function makeLines(lines, parentKey) {
            if (lines) {
              lines.forEach((childLine) => {
                const childKey = getRandomString(4)
                let isNew = !_get(childLine, 'product.id')

                if (Number(childLine.amountOff) > 0 || childLine.percentOff > 0) {
                  childLine.showDiscount = true
                  childLine.discountType =
                    Number(childLine.amountOff) > 0 ? DISCOUNT_TYPE_AMOUNT : DISCOUNT_TYPE_PERCENT
                }

                let currentChildLine = newLine(
                  {
                    ...childLine,
                    ...{ key: childKey, isNew: isNew },
                  },
                  state.units
                )

                if (currentChildLine.unit === null) {
                  currentChildLine.unit = defaultUnit
                }

                if (currentChildLine.tax === null) {
                  currentChildLine.tax = defaultTax
                }

                state.lines.push(currentChildLine)
                if (_has(state.childTree, parentKey)) {
                  state.childTree[parentKey].push(childKey)
                } else {
                  state.childTree[parentKey] = [childKey]
                }

                if (childLine.type === 'group') {
                  if (!_get(state.childTree, childKey)) {
                    state.childTree[childKey] = []
                  }
                  makeLines(childLine.lines, childKey)
                }
              })
            }
          })(line.lines, key)
        }
      }
    }
  },

  insertFromLines(state, lines) {
    const { company } = this.$auth

    const defaultUnit = _get(company.value, 'defaultUnit', [])
    const defaultTax = state.taxable ? company.value.defaultTax : null

    for (let line of lines) {
      if (!line.selected) continue
      const key = getRandomString(4)

      let isNew = !_get(line, 'product.id')

      if (Number(line.amountOff) > 0 || line.percentOff > 0) {
        line.showDiscount = true
        line.discountType = Number(line.amountOff) > 0 ? DISCOUNT_TYPE_AMOUNT : DISCOUNT_TYPE_PERCENT
      }

      if (state.subtype === DOCUMENT_SUBTYPE_SITUATION) {
        line.quoteQuantity = line.quantity
        line.quantity = 0
        line.additional = true
      }

      if (state.object !== QUOTE) {
        line.optional = false
      }

      if (state.object === ORDER_FORM) {
        line.sellPrice = line.buyPrice
        line.buyPrice = null
      }

      line.id = null
      let currentLine = newLine({ ...line, ...{ key: key, isNew } }, state.units)

      if (_get(currentLine, 'product.id')) {
        currentLine.persist = _get(company.value, 'builderAutoSaveOverwrite', false)
      }

      if (currentLine.unit === null) {
        currentLine.unit = defaultUnit
      }

      if (currentLine.tax === null) {
        currentLine.tax = defaultTax
      }

      state.lines.push(currentLine)
      state.tree.push(key)

      if (line.type === 'group') {
        if (!_get(state.childTree, key)) {
          state.childTree[key] = []
        }
        if (_has(line, 'lines')) {
          ;(function makeLines(lines, parentKey) {
            if (lines) {
              lines.forEach((childLine) => {
                if (childLine.selected) {
                  const childKey = getRandomString(4)
                  let isNew = !_get(childLine, 'product.id')

                  if (Number(childLine.amountOff) > 0 || childLine.percentOff > 0) {
                    childLine.showDiscount = true
                    childLine.discountType =
                      Number(childLine.amountOff) > 0 ? DISCOUNT_TYPE_AMOUNT : DISCOUNT_TYPE_PERCENT
                  }

                  if (state.subtype === DOCUMENT_SUBTYPE_SITUATION) {
                    childLine.quoteQuantity = childLine.quantity
                    childLine.quantity = 0
                    childLine.additional = true
                  }

                  if (state.object !== QUOTE) {
                    childLine.optional = false
                  }

                  childLine.id = null

                  let currentChildLine = newLine(
                    {
                      ...childLine,
                      ...{ key: childKey, isNew: isNew },
                    },
                    state.units
                  )

                  if (state.object === ORDER_FORM) {
                    currentChildLine.sellPrice = currentChildLine.buyPrice
                    currentChildLine.buyPrice = null
                  }

                  if (currentChildLine.unit === null) {
                    currentChildLine.unit = defaultUnit
                  }

                  if (currentChildLine.tax === null) {
                    currentChildLine.tax = defaultTax
                  }

                  state.lines.push(currentChildLine)
                  if (_has(state.childTree, parentKey)) {
                    state.childTree[parentKey].push(childKey)
                  } else {
                    state.childTree[parentKey] = [childKey]
                  }

                  if (childLine.type === 'group') {
                    if (!_get(state.childTree, childKey)) {
                      state.childTree[childKey] = []
                    }
                    makeLines(childLine.lines, childKey)
                  }
                }
              })
            }
          })(line.lines, key)
        }
      }
    }
  },

  init(state) {
    state.init = true
    state.dirty = false
  },

  unInit(state) {
    state.init = false
    state.dirty = false
  },

  setTouched(state, value) {
    state.touched = value
  },

  setDirty(state, value) {
    state.dirty = value
  },

  updateReadonlyFields(state, data) {
    state.remainingSubtotal = _get(data, 'remainingSubtotal', 0)
    state.remainingTotal = _get(data, 'remainingTotal', 0)
  },

  setVersion(state, value) {
    state.version = value
  },

  updatePrices(state, lines) {
    for (const line of lines) {
      ;(function walkLines(line) {
        if (line.product !== null) {
          const index = state.lines.findIndex((el) => el.id === line.id)
          if (index !== -1) {
            state.lines[index].sellPriceForced = line.sellPriceForced
            state.lines[index].sellPrice = line.sellPrice
            state.lines[index].buyPrice = line.buyPrice
            state.lines[index].supplies = line.supplies
          }
        }
        if (line.type === 'group') {
          line.lines.forEach((childLine) => {
            walkLines(childLine)
          })
        }
      })(line)
    }
  },

  setLockedId(state, value) {
    state.lockedId = value
  },

  setBroken(state, value) {
    state.broken = value
  },

  setConfirmCloseRevision(state, value) {
    state.confirmCloseRevision = value
  },

  setRevisionReason(state, value) {
    state.revisionReason = value
  },

  setReadOnly(state, value) {
    state.readOnly = value
  },

  // TODO maybe not used anymore
  resetPersistStates(state) {
    for (const line of state.lines) {
      const index = getLineIndex(state, line.key)
      _set(state, `lines.${index}.state`, {})
    }
  },

  /**
   * Updates a single line data
   */
  setLineData(state, params) {
    const index = getLineIndex(state, params.key)
    if (index !== -1) {
      if (params.prop === 'quantity') {
        //console.log(params.val.replace(/[^0-9.,]+/g, ''))
      }

      /*if (_get(state.lines, `${index}.isDuplicate`) && ['description', 'productType', 'reference', 'supplies']) {
        // TODO persist duplicated lines if touched
      }*/

      if (params.prop === 'productType') {
        if (PRODUCT_TYPE_WORK_DETAILED === params.val) {
          _set(state, `lines.${index}.sellPriceLocked`, true)
        }

        if (params.val === PRODUCT_TYPE_WORKFORCE) {
          if (_get(state.lines, `${index}.unit.symbol`) === _get(params, `company.defaultUnit.symbol`)) {
            let hourUnit = _get(params.company, 'units', []).find((el) => el.symbol === 'h')
            if (hourUnit) {
              _set(state, `lines.${index}.unit`, hourUnit)
            }
          }
        }
      }

      _set(state, `lines.${index}.${params.prop}`, params.val)

      if (!['showDiscount', 'optional', 'percentOff', 'amountOff', 'quantity'].includes(params.prop)) {
        _set(state, `lines.${index}.dirty`, true)
      }

      state.dirty = true
      state.touched = true
    }
  },

  setLineId(state, params) {
    const index = getLineIndex(state, params.key)
    if (index !== -1) {
      _set(state, `lines.${index}.id`, params.val)
    }
  },

  /**
   * Updates a single line props
   */
  setLineProps(state, params) {
    const index = getLineIndex(state, params.key)
    if (index !== -1) {
      Object.entries(params.props).forEach(([key, val]) => {
        _set(state, `lines.${index}.${key}`, val)
      })
      state.dirty = true
      state.touched = true
    }
  },

  updateTree(state, tree) {
    state.tree = tree
    state.dirty = true
    state.touched = true
  },

  updateChildTree(state, params) {
    const { key, val } = params
    if (key && val) {
      _set(state, `childTree`, {
        ...state.childTree,
        [key]: val,
      })
      state.dirty = true
      state.touched = true
    }
  },

  addLine(state, { parent, line, key, sibling, autofocus }) {
    state.lines.push(line)

    if (autofocus) {
      state.autofocusLine = line.key
    }
    const siblingLine = _get(sibling, 'line', null)
    const siblingMethod = _get(sibling, 'method', null)

    // Adds the line in base tree
    if (parent === null) {
      // Inserting before or after a sibling

      if (siblingLine !== null) {
        let index = state.tree.findIndex((el) => el === siblingLine)
        if (index !== -1) {
          if (['after', 'duplicate'].includes(siblingMethod)) {
            index += 1
          }
          state.tree.splice(index, 0, key)
        }
      } else {
        state.tree.push(key)
      }
    } else {
      // Adds the line in a group
      if (_has(state.childTree, parent)) {
        // Inserting before or after a sibling
        if (siblingLine && siblingMethod) {
          let index = state.childTree[parent].findIndex((el) => el === siblingLine)
          if (index !== -1) {
            if (['after', 'duplicate'].includes(siblingMethod)) {
              index += 1
            }
            state.childTree[parent].splice(index, 0, key)
          }
        } else {
          state.childTree[parent].push(key)
        }
      } else {
        state.childTree = {
          ...state.childTree,
          ...{ [parent]: [key] },
        }
      }
    }

    state.dirty = true
    state.touched = true
  },

  removeLine(state, { key, parent = null, keepOrphans = undefined, moveOrphans = undefined }) {
    const lineIndex = state.lines.findIndex((line) => line.key === key)
    let orphans = []

    if (lineIndex !== -1) {
      const lineType = state.lines[lineIndex].type

      // Removes a line reference from the base tree
      if (parent === null) {
        const treeIndex = state.tree.indexOf(key)
        if (treeIndex !== -1) {
          state.tree.splice(treeIndex, 1)
        }
      } else {
        // Removes a child line reference from its group
        if (_has(state.childTree, parent)) {
          const childTreeIndex = state.childTree[parent].indexOf(key)
          if (childTreeIndex !== -1) {
            state.childTree[parent].splice(childTreeIndex, 1)
          }
        }
      }

      if (lineType === 'group') {
        if (keepOrphans === true) {
          if (moveOrphans !== undefined) {
            // Moves orphans to the specified group
            let currentChildTree = state.childTree[key]
            state.childTree[moveOrphans] = [].concat(state.childTree[moveOrphans], currentChildTree)
            this._vm.$delete(state.childTree, key)
          } else {
            // Moves orphans to the first available group
            let newGroup = state.lines.filter(
              (el) => el.type === 'group' && el.key !== key && !state.childTree[key].includes(el.key)
            )[0]

            if (newGroup) {
              state.childTree[newGroup.key] = []
                .concat(state.childTree[newGroup.key], state.childTree[key])
                .filter(Boolean)
            } else {
              // Moves orphans to the root tree
              state.tree = [].concat(state.tree, state.childTree[key]).filter(Boolean)
            }

            this._vm.$delete(state.childTree, key)
          }
        } else {
          // Removes orphan lines if any (for groups)
          orphans = orphans.concat(_get(state.childTree, key, []))
          ;(function removeChildren(currentParent, deleteFunction) {
            if (Object.keys(state.childTree).includes(currentParent)) {
              const children = [...state.childTree[currentParent]]

              for (const child of children) {
                removeChildren(child, deleteFunction)
              }
              orphans = orphans.concat([...state.childTree[currentParent]])
              deleteFunction(state.childTree, currentParent)
            }
          })(key, this._vm.$delete)
        }
      }

      // Removes actual line data
      this._vm.$delete(state.lines, lineIndex)

      // Remove orphan lines data
      for (const childKey of orphans) {
        const childLineIndex = state.lines.findIndex((line) => line.key === childKey)
        if (childLineIndex !== -1) {
          // Removes child line data
          this._vm.$delete(state.lines, childLineIndex)
        }
      }
    }

    state.dirty = true
    state.touched = true
  },

  addGroup(state, params = { key: null, parent: null, sibling: null }) {
    let key = params.key

    let line = {
      type: 'group',
      id: null,
      key: key,
      data: '',
      description: '',
      collapsed: false,
      optional: false,
      additional: state.subtype === DOCUMENT_SUBTYPE_SITUATION,
    }

    /* if (state.tree.length) {
      // Gets the first item of tree
       const firstKey = [].concat(state.tree).shift()

       // If the first item of tree is not a group, move all existing item in the created group
       const firstOfTree = state.lines.find((line) => line.key === firstKey)
       if (firstOfTree && _has(firstOfTree, 'type') && firstOfTree.type !== 'group') {
         let newTree = [].concat(state.tree)
         let newLines = state.lines.filter((el) => el.type !== 'page_break')

         for (const pageBreak of state.lines.filter((el) => el.type === 'page_break')) {
           let index = newTree.findIndex((el) => el === pageBreak.key)
           if (index !== -1) {
             newTree.splice(index, 1)
           }
         }

         state.lines = newLines

         state.childTree = {
           [key]: [].concat(newTree),
         }
         state.tree = []
       }
    }*/

    // Duplicates current group and its lines
    if (params.sibling && params.sibling.line && params.sibling.method === 'duplicate') {
      const childTree = { ...state.childTree }

      key = getRandomString(4)

      const fromLine = state.lines.find((el) => el.key === params.sibling.line)
      const { key: fromKey, id, ...copyLine } = fromLine

      let newLine = {
        ...copyLine,
        ...{ key, id: null, additional: state.subtype === DOCUMENT_SUBTYPE_SITUATION },
      }

      let originalTree = _get(childTree, fromKey, [])

      state.lines.push(newLine)
      ;(function duplicateGroupChildren(currentChildTree, newGroupKey) {
        for (const lineKey of currentChildTree) {
          const originalLine = state.lines.find((el) => el.key === lineKey)
          if (originalLine) {
            const { key: _, id, ...newLine } = originalLine
            let newKey = getRandomString(4)
            state.lines.push({ ...originalLine, id: null, key: newKey })

            if (!_has(childTree, newGroupKey)) {
              childTree[newGroupKey] = []
            }

            childTree[newGroupKey].push(newKey)

            if (originalLine.type === LINE_TYPE_GROUP) {
              let nestedChildTree = _get(childTree, originalLine.key, [])
              duplicateGroupChildren(nestedChildTree, newKey)
            }
          }
        }
      })(originalTree, key)

      state.childTree = childTree
    } else {
      // Creates an empty child tree for the group
      const childTree = { ...state.childTree }
      if (!_has(childTree, key)) {
        childTree[key] = []
        state.childTree = childTree
      }
      state.lines.push(line)
    }

    if (params.parent) {
      // Pushes the new line in the parent childTree
      if (_has(state.childTree, params.parent)) {
        const firstChildKey = [].concat(state.childTree[params.parent]).shift()
        const firstChild = state.lines.find((line) => line.key === firstChildKey)

        /*// If the current first child is not a group
        if (firstChild && _has(firstChild, 'type') && firstChild.type !== 'group') {
          // Gets current parent subTree
          const subTree = [].concat(state.childTree[params.parent])

          // Appends current parent subTree to the new subGroup
          state.childTree[key] = subTree

          // Sets the new subGroup as the parent only child
          state.childTree[params.parent] = [].concat(key)
        } else {*/

        if (_has(params, 'after')) {
          const index = state.childTree[params.parent].findIndex((el) => el === params.after)
          if (index !== -1) {
            state.childTree[params.parent].splice(index + 1, 0, key)
          }
        } else {
          state.childTree[params.parent].push(key)
        }

        //}
      } else {
        // If the parent doesn't have a childTree, create it
        state.childTree = Object.assign({}, state.childTree, {
          [params.parent]: [key],
        })
      }
    } else {
      if (_has(params, 'after')) {
        const index = state.tree.findIndex((el) => el === params.after)
        if (index !== -1) {
          state.tree.splice(index + 1, 0, key)
        }
      } else {
        state.tree.push(key)
      }
    }

    state.dirty = true
    state.touched = true
  },

  setSettings(state, settings) {
    state.settings = { ...state.settings, ...settings }
  },

  purgeFile(state, file) {
    console.log(file)
    console.log(state.lines)

    for (const line of state.lines) {
      if (line.images) {
        let index = line.images.findIndex((el) => el.id === file.id)
        if (index !== -1) line.images.splice(index, 1)
      }
    }
  },
}

let saveCancelTokenSource = null
let pingCancelTokenSource = null

export const actions = {
  addGroup({ state, commit }, params) {
    const key = getRandomString(4)
    commit('addGroup', { ...params, key })
    return key
  },

  makeOptional({ state, commit, dispatch, getters }, { line, optional }) {
    dispatch('updateLineData', { prop: 'optional', val: optional, key: line.key })
    /*if (line.type === LINE_TYPE_GROUP) {
      for (const childKey of getters.getChildrenRecursive(line.key)) {
        commit('setLineData', { key: childKey, prop: 'optional', val: optional })
      }
    }*/
  },

  applySituation({ state, commit }, { type, amount }) {
    let lines = [].concat(state.lines)
    const { company } = this.$auth
    const round = (value, currency) => {
      if (['XAF', 'XOF', 'XPF'].includes((currency || company.value.currency).toUpperCase())) {
        return Math.round(Math.round(value / 100) * 100)
      }

      return Math.round(value)
    }

    const invoicableTotal = lines
      .filter((el) => el.type === LINE_TYPE_PRODUCT)
      .reduce((amount, line) => {
        let subtotal = round(line.sellPrice * (line.quoteQuantity - line.invoicedQuantity))
        let percentOff = Number(line.percentOff)
        let amountOff = Number(line.amountOff)

        if (percentOff > 0) {
          let amount = (subtotal * percentOff) / 100
          subtotal -= amount
        } else if (amountOff > 0) {
          subtotal -= amountOff
        }

        amount += subtotal
        return amount
      }, 0)

    for (const line of lines.filter((el) => Math.abs(el.invoicedQuantity) < Math.abs(el.quoteQuantity))) {
      if (line.type === 'product') {
        let lineAmount = amount
        if (type === AMOUNT_TYPE_PERCENT) {
          let invoicedPercent = line.invoicedPercent || 0
          if (invoicedPercent + lineAmount > 100) {
            lineAmount = 100 - invoicedPercent
          }
          commit('setLineData', { key: line.key, prop: 'percent', val: lineAmount })
          commit('setLineData', { key: line.key, prop: 'quantity', val: line.quoteQuantity * (lineAmount / 100) })
        } else {
          let subtotal = roundToPrecision(line.sellPrice * (line.quoteQuantity - line.invoicedQuantity), 7)
          line.weight = roundToPrecision((subtotal / invoicableTotal) * 100, 7)
        }
      }
    }

    if (type === AMOUNT_TYPE_SUBTOTAL) {
      for (const line of state.lines.filter((el) => el.invoicedQuantity < el.quoteQuantity)) {
        let subtotal = roundToPrecision(line.sellPrice * line.quoteQuantity, 7)
        let linePercent = ((amount * (line.weight / 100)) / (subtotal / 100)) * 100
        commit('setLineData', { key: line.key, prop: 'percent', val: linePercent })
        commit('setLineData', { key: line.key, prop: 'quantity', val: line.quoteQuantity * (linePercent / 100) })
      }
    }
  },

  convertLineSupplies({ state, commit }, params) {
    const { company } = this.$auth
    const defaultUnit = _get(company.value, 'defaultUnit', [])

    commit('convertLineSupplies', { ...params, defaultUnit })
  },

  addLine(
    { state, commit },
    {
      parent = null,
      type = 'product',
      productType = PRODUCT_TYPE_SUPPLY,
      autofocus = false,
      sibling = { line: null, method: 'before' },
    }
  ) {
    const { company } = this.$auth

    let key = getRandomString(4)
    let line = {}

    let useUnit = _get(company.value, 'defaultUnit', [])
    const defaultTax = state.taxable ? company.value.defaultTax : null

    if (productType === PRODUCT_TYPE_WORKFORCE) {
      let hourUnit = company.value.units.find((el) => el.symbol === 'h')
      if (hourUnit) {
        useUnit = hourUnit
      }
    }

    if (sibling !== null && sibling.line && sibling.method === 'duplicate') {
      const fromLine = state.lines.find((el) => el.key === sibling.line)

      const { key: parentKey, id, originalTax, originalUnit, ...copyLine } = fromLine
      key = getRandomString(4)
      line = {
        ...copyLine,
        ...{ key, id: null, additional: state.subtype === DOCUMENT_SUBTYPE_SITUATION }, // isDuplicate: true
      }
    } else {
      line = newLine(
        {
          type: type,
          productType: productType,
          additional: state.subtype === DOCUMENT_SUBTYPE_SITUATION,
          tax: defaultTax,
          unit: useUnit,
          key: key,
          persist: company.value.builderAutoSave,
          description: '', // lorem.generateWords(5)
        },
        state.units
      )
    }

    if (line.additional && sibling.method !== 'duplicate') {
      line.quoteQuantity = 1
      line.quantity = 0
    }

    commit('addLine', { parent, line, key, sibling, autofocus })
    return key
  },

  addLineFromLibrary({ state, commit }, params) {
    let key = getRandomString(4)
    let line = {}

    const defaultUnit = _get(state.company, 'defaultUnit', [])
    const defaultTax = state.taxable ? state.company.defaultTax : null

    line = newLine(
      {
        type: 'product',
        productType: PRODUCT_TYPE_WORK_DETAILED,
        tax: defaultTax,
        unit: _get(params, 'unit', defaultUnit),
        key: key,
        work: _get(params, 'workId', null),
        persist: state.company.builderAutoSave,
        description: _get(params, 'name', ''),
        reference: _get(params, 'reference', ''),
        buyPrice: _get(params, 'buyPrice', 0),
        sellPrice: _get(params, 'sellPrice', 0),
        supplies: _get(params, 'supplies', []),
        source: _get(params, 'source', null),
        sourceId: _get(params, 'sourceId', null),
        sellPriceForced: _get(params, 'sellPriceForced', false),
        sellPriceLocked: _get(params, 'sellPriceLocked', false),
      },
      state.units
    )

    let insertParent = getInsertionParentOrNull(state)

    commit('addLine', { parent: insertParent, line, key, sibling: { line: null, method: 'before' } })
    return key
  },

  updateFromLibrary({ state, commit }, params) {
    const index = getLineIndex(state, params.key)
    const defaultUnit = _get(state.company, 'defaultUnit', [])
    const defaultTax = state.taxable ? state.company.defaultTax : null

    let product = {
      type: PRODUCT_TYPE_WORK_DETAILED,
      tax: defaultTax,
      unit: _get(params.product, 'unit', defaultUnit),
      work: _get(params.product, 'workId', null),
      persist: state.company.builderAutoSave,
      name: _get(params.product, 'name', ''),
      reference: _get(params.product, 'reference', ''),
      buyPrice: _get(params.product, 'buyPrice', 0),
      sellPrice: _get(params.product, 'sellPrice', 0),
      elements: _get(params.product, 'supplies', []),
      source: _get(params.product, 'source', null),
      sourceId: _get(params.product, 'sourceId', null),
      sellPriceForced: _get(params, 'sellPriceForced', false),
      sellPriceLocked: _get(params, 'sellPriceLocked', false),
    }

    commit('setLineProduct', { params: { product, isNew: true }, index, defaultUnit, defaultTax })
  },

  async getNumber({ state, commit }) {
    let numberData = {}
    let hasSubNumber = state.subtype === DOCUMENT_SUBTYPE_SITUATION

    let numberRequestPath = `api/numbers/${state.objectType || state.object}`

    if (hasSubNumber) {
      numberRequestPath += `/${state.id}`
    }

    if (state.status === DOCUMENT_STATUS_DRAFT && !state.forceNumber) {
      const { data: numberingData } = await this.$axios.get(numberRequestPath, {
        params: {
          issuedAt: state.issuedAt !== null ? format(new Date(state.issuedAt), 'yyyy-MM-dd') : state.issuedAt,
        },
        progress: false,
      })

      numberData = {
        number: numberingData.data.number,
      }

      if (hasSubNumber) {
        numberData.subNumber = numberingData.data.subNumber
      }
    }

    for (const [key, val] of Object.entries(numberData)) {
      commit('setData', { key, val, untracked: true })
    }
  },

  /**
   * Merges Product data in a Line
   */
  setLineProduct({ state, commit }, params) {
    const index = getLineIndex(state, params.key)
    const { company } = this.$auth

    const defaultUnit = _get(company.value, 'defaultUnit', [])
    const defaultTax = state.taxable ? company.value.defaultTax : null

    const unitId = _get(params, 'products.unitId', null)
    const taxId = _get(params, 'products.taxId', null)

    if (unitId) {
      const unit = _get(company.value, 'units', []).find((el) => el.id === unitId)
      if (unit) params.product.unit = unit
    }

    if (taxId) {
      const tax = _get(company.value, 'taxes', []).find((el) => el.id === taxId)
      if (taxId) params.product.tax = tax
    }

    commit('setLineProduct', { params, index, defaultUnit, defaultTax })
  },

  loadDocument({ commit, dispatch, state, rootState }, params) {
    const { company } = this.$auth

    if (_get(rootState, 'targetVersion')) {
      window.location.href = withQuery(window.location.href, { v: rootState.targetVersion })
    }

    commit('resetState')
    commit('setCompany', company.value)

    try {
      if (!sessionStorage.getItem('lockedId')) {
        sessionStorage.setItem('lockedId', nanoid())
      }
      commit('setLockedId', sessionStorage.getItem('lockedId'))
    } catch (err) {
      commit('setLockedId', nanoid())
    }

    return new Promise(async (resolve, reject) => {
      let repository = pluralizeType[params.object]
      const $repository = this.$getRepository(repository)

      try {
        if (params.id !== 'demo') {
          for (const item in localStorage) {
            if (item.search(/(quotes|invoices|credits)\/(inv_|quote_|credit_)/gm) !== -1) {
              const archive = localStorage.getItem(item)
              if (archive) {
                const { savedAt } = JSON.parse(archive)
                if (savedAt && differenceInHours(new Date(), new Date(savedAt)) > 48) {
                  localStorage.removeItem(item)
                }
              }
            }
          }
          const { data: lockData } = await $repository.get(params.id, 'lock')
          if (_get(lockData, 'editable') === false) {
            await this.$router.push(`/${kebabCase(pluralizeType[params.object])}/${params.id}/details`)
            return
          }
        }

        const { data: documentData } = await $repository.find(params.id, {
          _expand: [
            'lines',
            'children',
            'options',
            'from_quote',
            'customer',
            'vat_attestation',
            'attachments_count',
            params.object === INVOICE ? 'deducted_invoices' : null,
          ],
        })

        // Sets WORK_ORDER workStartTime if it's specified
        if (documentData.objectType === WORK_ORDER) {
          if (documentData.workTimeSpecified && isValid(new Date(documentData.workStartAt))) {
            documentData.workStartTime = format(new Date(documentData.workStartAt), 'HH:mm')
          }
        }

        if (documentData.subtype === DOCUMENT_SUBTYPE_SITUATION && documentData.quote) {
          const { data: invoices } = await this.$quotesRepository.get(documentData.quote.id, 'invoices', {
            _limit: 100,
            _expand: ['deducted_invoices'],
          })
          commit(
            'setInvoicesSummary',
            invoices.filter((el) => el.id !== params.id)
          )
        }

        if (documentData.percentOff > 0 || documentData.amountOff > 0) {
          if (documentData.percentOff > 0) documentData.discountType = DISCOUNT_TYPE_PERCENT
          if (documentData.amountOff > 0) documentData.discountType = DISCOUNT_TYPE_AMOUNT
        }

        // Check if we can edit invoice
        if (documentData.object === INVOICE && documentData.status !== DOCUMENT_STATUS_DRAFT) {
          let pass = false

          if (this.$auth.can('plan', 'invoices.edit_finalized')) {
            try {
              const { data: editableData } = await this.$invoicesRepository.get(params.id, 'editable')
              pass = editableData.editable
            } catch (err) {}
          }

          if (!pass) {
            await this.$router.push(
              `/${kebabCase(pluralizeType[documentData.objectType || documentData.object])}/${params.id}/details`
            )
            return
          }
        } else {
          commit('setRevisionReason', null)
        }

        if (
          _get(company.value, 'bankAccounts', []).filter((el) => ![null, undefined, ''].includes(el.account)).length ===
          0
        ) {
          documentData.bankAccount = null
        }

        commit('setId', params.id)
        commit('populateData', documentData)
        dispatch('getNumber')

        if (documentData.object !== ORDER_FORM) {
          if (documentData.customer && documentData.address !== null) {
            // Checks if current document address is up-to-date (matches any of the customer addresses)
            let hasUpToDateAddress = false
            for (const address of _get(documentData, 'customer.addresses', [])) {
              if (
                address.address !== null &&
                getAddressString(address.address) === getAddressString(documentData.address)
              ) {
                hasUpToDateAddress = true
                break
              }
            }

            if (!hasUpToDateAddress) {
              commit('getPrimaryAddress')
            }
          }
        } else {
          if (documentData.supplier && documentData.address !== null) {
            // Checks if current document address is up-to-date (matches any of the supplier addresses)
            let hasUpToDateAddress = false
            for (const address of _get(documentData, 'supplier.addresses', [])) {
              if (
                address.address !== null &&
                getAddressString(address.address) === getAddressString(documentData.address)
              ) {
                hasUpToDateAddress = true
                break
              }
            }

            if (!hasUpToDateAddress) {
              commit('getPrimaryAddress', 'supplier')
            }
          }
        }

        commit('setSettings', {
          taxable: company.value.taxable,
        })

        // Adds a first line if none is present
        /*if (!documentData.lines.length) {
              await dispatch('addLine', {})
              commit('setDirty', false)
            }*/

        commit('setReadOnly', false)
        commit('init')
        resolve(true)
      } catch (err) {
        console.log(err)
        reject(err)
      }
    })
  },

  async updatePrices({ commit, state }) {
    let repository = pluralizeType[state.object]
    const $repository = this.$getRepository(repository)
    const { data } = await $repository.update(state.id, {}, { update_prices: true, _expand: ['lines'] })

    let version = _get(data, 'data.version', _get(data, 'version', null))
    if (version) {
      commit('setVersion', version)
    }

    commit('updatePrices', data.lines)
  },

  async open({ commit, state }) {
    try {
      const { data } = await this.$axios.put(`api/${pluralizeType[state.object]}/${state.id}/status/open`)
      return data.data
    } catch (err) {
      return false
    }
  },

  async unlock({ commit, state }) {
    if (state.id !== 'demo') {
      try {
        await this.$axios.get(`api/${pluralizeType[state.object]}/${state.id}/unlock`)
        commit('setTouched', false)
      } catch (err) {}
    }
  },

  async clear({ state, commit, dispatch }) {
    if (!state.deleting) {
      if (state.id !== null) {
        commit('setSaving', true)
        if (state.status === DOCUMENT_STATUS_DRAFT) {
          await dispatch('save', { notify: false, close: true })
        }
        await dispatch('unlock')
        commit('setSaving', false)
      }
    }
  },

  makeNewLinesIds({ commit, state }, data) {
    let newLinesId = _get(data, 'data.newLinesId', {})
    for (const [key, val] of Object.entries(newLinesId)) {
      commit('setLineId', { key, val })
    }
  },

  async ping({ state, commit }, params) {
    if (!state.deleting && !state.broken) {
      return new Promise(async (resolve, reject) => {
        try {
          if (pingCancelTokenSource) {
            pingCancelTokenSource.cancel()
          }

          pingCancelTokenSource = this.$axios.CancelToken.source()

          const { data } = await this.$axios.put(
            `api/${pluralizeType[state.object]}/${state.id}/ping`,
            {
              version: state.version,
              lockedId: state.lockedId,
            },
            {
              cancelToken: pingCancelTokenSource.token,
              timeout: 30000,
            }
          )

          let version = _get(data, 'data.version', _get(data, 'version', null))
          if (version) {
            commit('setVersion', version)
          }
          resolve(data.data)
        } catch (err) {
          const data = _get(err, 'response.data', null)
          if (['idempotency_error', 'company_conflict'].includes(_get(data, 'type', null))) {
            commit('setBroken', true)
            this.$bus.emit('idempotencyError', _get(data, 'message'))
          }

          reject(false)
        }
      })
    } else {
      return new Promise((resolve) => resolve(true))
    }
  },

  // Don't double-save if we're already closing the document to avoid API errors
  async save(
    { commit, state, dispatch },
    params = { notify: true, open: false, done: false, close: false, pick: null }
  ) {
    if (!state.deleting && !state.broken) {
      return new Promise(async (resolve, reject) => {
        try {
          let document = {}
          for (const key of [].concat(DOCUMENT_FIELDS_WRITE_ONLY, DOCUMENT_FIELDS_PROCESS)) {
            if (_has(state, key)) {
              let val = state[key]
              if (DOCUMENT_FIELDS_TYPE_NUMERIC.includes(key)) {
                val = parseFloat(val)
              }
              if (DOCUMENT_FIELDS_NULLABLE.includes(key)) {
                if (val === '') {
                  val = null
                }
              }

              if (key === 'expireAt' && state.object === ORDER_FORM) {
                val = null
              }

              if (['customer', 'worker', 'project', 'supplier', 'assignee'].includes(key)) {
                val = _get(val, 'id', null)
              }

              if (['address', 'deliveryAddress', 'workAddress'].includes(key) && val !== null) {
                if (
                  Object.entries(val).filter(([k, v]) => k !== 'country' && typeof v === 'string' && v.trim() !== '')
                    .length === 0
                ) {
                  val = null
                }
              }

              if ('deliveryAddress' === key && state.object !== ORDER_FORM) {
                val = null
              }

              if ('workAddress' === key && state.objectType !== WORK_ORDER) {
                val = null
              }

              if (['issuedAt', 'expireAt', 'holdbackReleasedAt', 'beginAt', 'preVisitAt', 'deliverAt'].includes(key)) {
                if (val !== null) {
                  val = format(new Date(val), 'yyyy-MM-dd')
                }
              }

              if (['amountOff'].includes(key)) {
                if (!isNaN(val)) {
                  val = Math.round(val * 100) / 100
                }
              }

              document[key] = val
            }
          }

          // WORK_ORDER intervention date
          document.workTimeSpecified = false
          if (state.objectType === WORK_ORDER) {
            if (state.workStartTime !== null && isValid(new Date(document.workStartAt))) {
              try {
                let [startHour, startMinutes] = state.workStartTime.split(':')
                document.workStartAt = set(new Date(document.workStartAt), {
                  hours: Number(startHour),
                  minutes: Number(startMinutes),
                  seconds: 0,
                }).toISOString()
                document.workTimeSpecified = true
              } catch (err) {}
            }
          }

          if (state.quote === null) document.quote = null

          if (document.holdback === null) {
            document.holdbackReleasedAt = null
          }

          if (state.object === INVOICE && !['custom', null].includes(document.paymentDelay)) {
            document.expireAt = null
          }

          document.lines = getNormalizedLines(state)

          if (params.close) {
            commit('setClosing', true)
          }

          if (params.close && saveCancelTokenSource) {
            saveCancelTokenSource.cancel()
          }

          saveCancelTokenSource = this.$axios.CancelToken.source()

          // Use pick to select properties to update instead of passing the whole document
          let payload = Array.isArray(params.pick)
            ? params.pick.reduce((obj, key) => {
                obj[key] = document[key]
                return obj
              }, {})
            : document

          /*try {
            localStorage.setItem(
              `${pluralizeType[state.object]}/${state.id}`,
              JSON.stringify({
                savedAt: new Date(),
                data: payload,
              })
            )
          } catch (err) {}*/

          if (params.open && state.status !== DOCUMENT_STATUS_DRAFT && state.revisionReason) {
            payload.revisionReason = state.revisionReason
          }

          const { data } = await this.$axios.put(`api/${pluralizeType[state.object]}/${state.id}`, payload, {
            cancelToken: saveCancelTokenSource.token,
            progress: params.notify,
            timeout: 30000,
            params: {
              _expand: params.close ? undefined : ['new_lines'],
              open: params.open,
              done: params.done,
            },
          })

          dispatch('makeNewLinesIds', data)

          if (params.notify) {
            this.$toast.show('Le document a été enregistré')
          }

          //commit('resetPersistStates')
          commit('setUpdatedAt', new Date())
          commit('setDirty', false)
          commit('updateReadonlyFields', data.data)

          if (data.data.assignee) {
            commit('setAssignee', data.data.assignee)
          }

          let version = _get(data, 'data.version', _get(data, 'version', null))
          if (version) {
            commit('setVersion', version)
          }
          resolve(data.data)
        } catch (err) {
          const data = _get(err, 'response.data', null)
          if (['idempotency_error', 'company_conflict'].includes(_get(data, 'type', null))) {
            commit('setBroken', true)
            this.$bus.emit('idempotencyError', _get(data, 'message'))
          }
          if (_get(data, 'message', null) === 'read_only') {
            commit('setBroken', true)
            this.$bus.emit('readonlyError')
          }

          reject(err)
        } finally {
          commit('setSaving', false)
          commit('setClosing', false)
        }
      })
    } else {
      return new Promise((resolve) => resolve(true))
    }
  },

  async updateDownPaymentsDeduction({ commit, state }, params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.$axios.put(`api/${pluralizeType[state.object]}/${state.id}`, {
          ...params,
          lockedId: state.lockedId,
          version: state.version,
        })
        commit('setData', { key: 'downPaymentsDeduction', val: data.data.downPaymentsDeduction })
        commit('setData', { key: 'downPaymentsProrated', val: data.data.downPaymentsProrated })
        commit('setData', { key: 'deductedDocuments', val: data.data.deductedDocuments })
        resolve(true)
      } catch (err) {
        reject(false)
      }
    })
  },

  updateLineData({ commit }, data) {
    const { company } = this.$auth
    commit('setLineData', { ...data, company: company.value })
  },
}
