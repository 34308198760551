import { ref } from '@nuxtjs/composition-api'
import {
  DOCUMENT_SUBTYPE_DOWN_PAYMENT,
  DOCUMENT_SUBTYPE_SITUATION,
  DOCUMENT_SUBTYPE_FINAL,
} from '@/constants/documents'
import * as constants from '@/constants/documents'

export const tableColumns = ref([
  {
    key: 'number',
    width: '50px',
    class: 'col-num',
  },
  {
    key: 'product',
    class: 'grow col-label',
  },
  {
    key: 'reference',
    width: '80px',
    class: 'text-center col-reference',
  },
  {
    key: 'quantityUnit',
    width: '60px',
    class: 'text-center col-quantity-unit',
  },
  {
    key: 'quantity',
    width: '50px',
    class: 'text-center col-quantity',
  },
  {
    key: 'unit',
    width: '30px',
    class: 'text-center col-unit',
  },
  {
    key: 'sellPrice',
    width: '100px',
    class: 'text-center col-sell-price',
  },
  {
    key: 'quoted',
    width: '80px',
    class: 'col-quoted',
  },
  {
    key: 'quoted_unit',
    width: '80px',
    class: 'col-quoted',
  },
  {
    key: 'situation_unit',
    width: '50px',
    class: 'text-center col-situation-unit',
  },
  {
    key: 'already_invoiced',
    width: '80px',
    class: 'col-already_invoiced',
  },
  {
    key: 'progression',
    width: '120px',
    class: 'col-progression',
  },
  {
    key: 'accumulation',
    width: '120px',
    class: 'col-accumulation',
  },
  {
    key: 'tax',
    width: '65px',
    class: 'text-center col-vat',
  },
  {
    key: 'discount',
    width: '70px',
    class: 'text-right',
  },
  {
    key: 'total',
    width: '110px',
    class: 'text-center col-total',
  },
])

export const getSubtypeIcon = {
  [DOCUMENT_SUBTYPE_FINAL]: ['far', 'flag-checkered'],
  [DOCUMENT_SUBTYPE_SITUATION]: ['fal', 'forward-step'],
  [DOCUMENT_SUBTYPE_DOWN_PAYMENT]: ['fal', 'circle-dollar-to-slot'],
}

export const statusColors = {
  [constants.QUOTE]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'text-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'text-yellow-600',
    [constants.DOCUMENT_STATUS_ACCEPTED]: 'text-green-600',
    [constants.DOCUMENT_STATUS_REFUSED]: 'text-red-600',
    [constants.DOCUMENT_STATUS_CANCELLED]: 'text-gray-400',
  },
  [constants.ORDER_FORM]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'text-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'text-yellow-600',
    [constants.DOCUMENT_STATUS_SHIPPED]: 'text-brand-600',
    [constants.DOCUMENT_STATUS_DELIVERED]: 'text-green-600',
    [constants.DOCUMENT_STATUS_CANCELLED]: 'text-gray-400',
  },
  [constants.INVOICE]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'text-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'text-blue-600',
    [constants.DOCUMENT_STATUS_PAID]: 'text-green-600',
    [constants.DOCUMENT_STATUS_CANCELLED]: 'text-red-600',
    [constants.DOCUMENT_STATUS_UNCOLLECTIBLE]: 'text-orange-600',
  },
  [constants.CREDIT]: {
    [constants.DOCUMENT_STATUS_OPEN]: 'text-green-600',
  },
  [constants.SUPPLIER_INVOICE]: {
    [constants.DOCUMENT_STATUS_TO_REVIEW]: 'text-orange-600',
    [constants.DOCUMENT_STATUS_TO_PAY]: 'text-yellow-600',
    [constants.DOCUMENT_STATUS_SCHEDULED]: 'text-blue-600',
    [constants.DOCUMENT_STATUS_PAID]: 'text-green-600',
  },
}

export const statusBorder = {
  [constants.QUOTE]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'border-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'border-yellow-600',
    [constants.DOCUMENT_STATUS_ACCEPTED]: 'border-green-600',
    [constants.DOCUMENT_STATUS_REFUSED]: 'border-red-600',
    [constants.DOCUMENT_STATUS_CANCELLED]: 'border-gray-400',
  },
  [constants.ORDER_FORM]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'border-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'border-yellow-600',
    [constants.DOCUMENT_STATUS_SHIPPED]: 'border-brand-600',
    [constants.DOCUMENT_STATUS_DELIVERED]: 'border-green-600',
    [constants.DOCUMENT_STATUS_CANCELLED]: 'border-gray-400',
  },
  [constants.INVOICE]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'border-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'border-blue-600',
    [constants.DOCUMENT_STATUS_PAID]: 'border-green-600',
    [constants.DOCUMENT_STATUS_CANCELLED]: 'border-red-600',
    [constants.DOCUMENT_STATUS_UNCOLLECTIBLE]: 'border-orange-600',
  },
  [constants.CREDIT]: {
    [constants.DOCUMENT_STATUS_OPEN]: 'border-green-600',
  },
  [constants.SUPPLIER_INVOICE]: {
    [constants.DOCUMENT_STATUS_TO_REVIEW]: 'border-orange-600',
    [constants.DOCUMENT_STATUS_TO_PAY]: 'border-yellow-600',
    [constants.DOCUMENT_STATUS_SCHEDULED]: 'border-blue-600',
    [constants.DOCUMENT_STATUS_PAID]: 'border-green-600',
  },
}

export const statusClasses = {
  [constants.QUOTE]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'bg-gray-100 text-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'bg-yellow-100 text-yellow-600',
    [constants.DOCUMENT_STATUS_ACCEPTED]: 'bg-green-100 text-green-600',
    [constants.DOCUMENT_STATUS_REFUSED]: 'bg-red-100 text-red-600',
    [constants.DOCUMENT_STATUS_CANCELLED]: 'bg-gray-50 border border-gray-200 text-gray-400',
  },
  [constants.WORK_ORDER]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'bg-gray-100 text-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'bg-yellow-100 text-yellow-600',
    [constants.DOCUMENT_STATUS_DONE]: 'bg-green-100 text-green-600',
  },
  [constants.ORDER_FORM]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'bg-gray-100 text-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'bg-yellow-100 text-yellow-600',
    [constants.DOCUMENT_STATUS_SHIPPED]: 'bg-brand-100 text-brand-600',
    [constants.DOCUMENT_STATUS_DELIVERED]: 'bg-green-100 text-green-600',
    [constants.DOCUMENT_STATUS_CANCELLED]: 'bg-gray-50 border border-gray-200 text-gray-400',
  },
  [constants.INVOICE]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'bg-gray-100 text-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'bg-blue-100 text-blue-600',
    [constants.DOCUMENT_STATUS_PAID]: 'bg-green-100 text-green-600',
    [constants.DOCUMENT_STATUS_CANCELLED]: 'bg-gray-50 border border-gray-200 text-gray-400',
    [constants.DOCUMENT_STATUS_UNCOLLECTIBLE]: 'bg-orange-50 border border-orange-100 text-orange-600',
  },
  [constants.CREDIT]: {
    [constants.DOCUMENT_STATUS_DRAFT]: 'bg-gray-100 text-gray-500',
    [constants.DOCUMENT_STATUS_OPEN]: 'bg-blue-100 text-blue-600',
    [constants.DOCUMENT_STATUS_PAID]: 'bg-green-100 text-green-600',
    [constants.DOCUMENT_STATUS_CANCELLED]: 'bg-gray-50 border border-gray-200 text-gray-400',
  },
  [constants.SUPPLIER_INVOICE]: {
    [constants.DOCUMENT_STATUS_TO_REVIEW]: 'bg-orange-100 text-orange-600',
    [constants.DOCUMENT_STATUS_TO_PAY]: 'bg-yellow-100 text-yellow-600',
    [constants.DOCUMENT_STATUS_SCHEDULED]: 'bg-blue-100 text-blue-600',
    [constants.DOCUMENT_STATUS_PAID]: 'bg-green-100 text-green-600',
  },
}
