//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject, provide, ref, watch, onMounted, reactive, nextTick } from '@nuxtjs/composition-api'
import { format, add, isAfter, isBefore, isExists, isValid, parseISO } from 'date-fns'
import { has as _has, get as _get, cloneDeep as _cloneDeep } from 'lodash-es'
import { fr } from 'date-fns/locale'
import { nanoid } from 'nanoid/non-secure'

export default defineComponent({
  props: {
    hide: Function,
    isWeek: Boolean,
  },
  setup(props, { emit }) {
    const isDateRange = inject('isDateRange')
    const dateRange = inject('dateRange')
    const date = inject('date')
    const ranges = inject('ranges')

    const applyRange = (range) => {
      emit('updateDateRange', { start: range[0], end: range[1] })
    }

    const isCurrentRange = (range) =>
      JSON.stringify({ start: range[0], end: range[1] }) === JSON.stringify(dateRange.value)

    const calendarDate = ref(date.value !== null ? date.value : new Date())
    const selectionRangeStart = ref(null)
    const selectionRange = ref({
      start: null,
      end: null,
    })

    onMounted(() => {
      if (!isDateRange.value && isValid(date.value)) calendarDate.value = date.value
    })

    watch(date, (val) => {
      if (val) calendarDate.value = val
    })

    provide('calendarDate', calendarDate)
    provide('selectionRangeStart', selectionRangeStart)
    provide('selectionRange', selectionRange)

    const setCalendarDate = (date) => (calendarDate.value = date)

    const manualSelectionRange = ref({
      start: null,
      end: null,
    })

    watch(dateRange, (val) => {
      manualSelectionRange.value = _cloneDeep(val)
    })

    onMounted(() => {
      manualSelectionRange.value = _cloneDeep(dateRange.value)
    })

    async function handleDateInput($event, part = 'end') {
      /*let today = new Date()
      let d = parseISO($event.target.value)
      if (isValid(d)) {
        if (isExists(d.getFullYear(), d.getMonth(), d.getDay())) {
          manualSelectionRange.value[part] = parseISO($event.target.value)
        }

        let start = manualSelectionRange.value.start
        let end = manualSelectionRange.value.end

        if (isValid(start) && isValid(end)) {
          if (
            isExists(start.getFullYear(), start.getMonth(), start.getDay()) &&
            isExists(end.getFullYear(), end.getMonth(), end.getDay()) &&
            start.getFullYear() > 2000 &&
            start.getFullYear() <= today.getFullYear() &&
            end.getFullYear() > 2000 &&
            start.getFullYear() <= today.getFullYear()
          ) {
            if (isBefore(start, end)) {
              dateRange.value = {
                start,
                end,
              }
            }
          }
        }
      }*/
    }

    async function handleDateBlur($event, part = 'start') {
      let today = new Date()
      let d = parseISO($event.target.value)
      if (isValid(d)) {
        if (isExists(d.getFullYear(), d.getMonth(), d.getDate())) {
          if (manualSelectionRange.value === null) {
            manualSelectionRange.value = {
              start: null,
              end: null,
            }
          }
          manualSelectionRange.value[part] = parseISO($event.target.value)
        }

        await nextTick()

        let start = null
        let end = null

        if (part === 'start') {
          start = parseISO($event.target.value)
          end = manualSelectionRange.value.end
        } else {
          start = manualSelectionRange.value.start
          end = parseISO($event.target.value)
        }

        if (isValid(start) && isValid(end)) {
          if (
            isExists(start.getFullYear(), start.getMonth(), start.getDate()) &&
            isExists(end.getFullYear(), end.getMonth(), end.getDate()) &&
            start.getFullYear() > 2000 &&
            start.getFullYear() <= today.getFullYear() &&
            end.getFullYear() > 2000 &&
            end.getFullYear() <= today.getFullYear()
          ) {
            if (isBefore(start, end)) {
              applyRange([start, end])
            } else {
              applyRange([start, add(start, { months: 1 })])
            }
          }
        }
      }
    }

    function blurElement($event) {
      $event.target.blur()
    }

    return {
      props,
      ranges,
      dateRange,
      isDateRange,
      manualSelectionRange,
      handleDateInput,
      handleDateBlur,
      blurElement,
      setCalendarDate,
      applyRange,
      isCurrentRange,
      format: (day, stringFormat) => format(day, stringFormat, { locale: fr }),
      _has,
      _get,
    }
  },
})
