import { useUser } from '@/composables/user'
import { get as _get } from 'lodash-es'
import { useContext } from '@nuxtjs/composition-api'
import { useErrorState, useLoading } from '@/composables/loading'

export default function useFlags() {
  const { company, user, updateCompany } = useUser()
  const { $companiesRepository } = useContext()

  const { setLoading, isLoading } = useLoading()
  const { setErrorState } = useErrorState()

  const hasFlag = (flag, scope = 'company') => {
    switch (scope) {
      case 'company':
        return _get(company.value, 'flags', []).includes(flag)
      case 'user':
        return _get(user.value, 'flags', []).includes(flag)
    }

    return false
  }

  const getFlagKey = (flag) => {
    if (Array.isArray(flag)) return flag.join('_')
    return flag
  }

  const maybeRemoveFlag = async (flag, scope = 'company') => {
    if (hasFlag(flag, scope)) await removeFlag(flag, scope)
  }

  const removeFlag = async (flag, scope = 'company') => {
    let key = getFlagKey(flag)
    try {
      setLoading(true, `remove_flag_${key}`)

      const { data } = await $companiesRepository.post(company.value.id, 'remove_flag', {
        flags: Array.isArray(flag) ? flag : undefined,
        flag: Array.isArray(flag) ? undefined : flag,
      })

      updateCompany({
        flags: data.flags,
      })
    } catch (err) {
      setErrorState(`remove_flag_${key}`)
    } finally {
      setLoading(false, `remove_flag_${key}`)
    }
  }

  return { removeFlag, maybeRemoveFlag, hasFlag, getFlagKey }
}
