//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import { useUser } from '@/composables/user'
import { useLoading } from '@/composables/loading'
import useFlags from '@/composables/useFlags'

export default defineComponent({
  setup() {
    const { company } = useUser()
    const { hasFlag, removeFlag } = useFlags()
    const { isLoading } = useLoading()

    return { company, hasFlag, removeFlag, isLoading }
  },
})
