import { useContext } from '@nuxtjs/composition-api'
import { useUser } from '@/composables/user'
import { has as _has, get as _get } from 'lodash-es'
import { frDomCountries } from '@/constants/countries'
import { ACCOUNTING_MODE_DISABLED, ACCOUNTING_MODE_SOLE_PROP } from '@/constants/companies'
import { computed } from '@nuxtjs/composition-api'
import useMq from '@/composables/mq'

export default function () {
  const { app } = useContext()
  const { mq } = useMq()
  const { isRoot, can, cap, company, user } = useUser()

  const sidebar = computed(() => {
    let companyRoute = ['xs', 'sm', 'md'].includes(mq.value) ? '/account/company' : '/account/company/info'

    let capabilities = cap('role', 'company')
    if (Array.isArray(capabilities) && !capabilities.includes('manage') && _has(capabilities, '0')) {
      companyRoute = `/account/company/${capabilities[0]}`
    }

    return [
      {
        key: 'main',
        class: 'mb-4',
        items: [
          {
            key: 'dashboard',
            name: 'index',
            route: '/',
            icon: ['fad', 'chart-line'],
          },
          {
            key: 'calendar',
            route: '/calendar',
            icon: ['fad', 'calendar-days'],
          },
          {
            key: 'timesheet',
            route: '/timesheet',
            icon: ['fad', 'list-timeline'],
            class: 'mb-4',
            beta: true,
            visible: () => ['ROLE_COMPANION'].includes(user.value.companyRole),
          },
          {
            key: 'projects',
            route: '/projects',
            icon: ['fad', 'person-digging'],
          },
          {
            key: 'work_orders',
            route: '/work-orders',
            icon: ['fad', 'toolbox'],
            beta: true,
            visible: () => can('plan', 'documents.work_orders') && ['ROLE_COMPANION'].includes(user.value.companyRole),
          },
        ],
      },
      {
        key: 'sales',
        class: 'mb-4',
        items: [
          {
            key: 'work_orders',
            route: '/work-orders',
            icon: ['fad', 'toolbox'],
            beta: true,
            visible: () => can('plan', 'documents.work_orders') && !['ROLE_COMPANION'].includes(user.value.companyRole),
          },
          {
            key: 'quotes',
            route: '/quotes',
            icon: ['fad', 'ruler-triangle'],
          },
          {
            key: 'invoices',
            route: '/invoices',
            icon: ['fad', 'file-invoice'],
            items: [
              {
                key: 'credits',
                route: '/credits',
                icon: ['fad', 'hand-holding-dollar'],
              },
              {
                key: 'payments',
                route: '/payments',
                icon: ['fad', 'coins'],
              },
              {
                key: 'holdbacks',
                route: '/holdbacks',
                icon: ['fad', 'building-columns'],
              },
            ],
          },
          {
            key: 'contacts',
            route: '/contacts',
            icon: ['fad', 'book-user'],
          },
          {
            key: 'products',
            route: '/products',
            icon: ['fad', 'folders'],
            class: '!mb-4',
          },
        ],
      },
      {
        key: 'buys',
        class: 'mb-4',
        items: [
          {
            key: 'order_forms',
            route: '/order-forms',
            icon: ['fad', 'basket-shopping'],
          },
          {
            key: 'supplier_invoices',
            route: '/supplier-invoices',
            icon: ['fad', 'receipt'],
            items: [
              {
                key: 'supplier_credits',
                route: '/supplier-credits',
                icon: ['fad', 'hand-holding-dollar'],
              },
            ],
          },
          {
            key: 'suppliers',
            route: '/suppliers',
            icon: ['fad', 'industry-windows'],
          },
        ],
      },
      {
        key: 'accounting',
        class: 'mb-4',
        items: [
          {
            key: 'transactions',
            route: '/transactions',
            icon: ['fad', 'building-columns'],
            visible: () =>
              ['ROLE_OWNER', 'ROLE_ACCOUNTANT'].includes(user.value.companyRole) ||
              (can('role', `transactions.read`) &&
                _get(user.value, 'companyFeatures', []).includes('manage_transactions')),
          },
          {
            visible: () =>
              can('role', `transactions.read`) &&
              frDomCountries.includes(company.value.country) &&
              company.value.accountingMode === ACCOUNTING_MODE_SOLE_PROP,
            key: 'incomes',
            route: '/accounting/incomes',
            icon: ['fad', 'book'],
          },
        ],
      },
      {
        key: 'bottom',
        items: [
          {
            key: 'company',
            route: companyRoute,
            icon: ['fad', 'gear'],
          },
          {
            key: 'referral',
            granted: true,
            route: '/referral',
            icon: ['fad', 'heart'],
          },
          {
            key: 'help',
            granted: true,
            click: ($bus) => {
              $bus.emit('openHelp')
            },
            icon: ['fad', 'life-ring'],
          },
        ],
      },
    ]
      .reduce((arr, group) => {
        let currentGroup = group
        let showGroup = true

        if (typeof group.visible === 'function' && !group.visible()) showGroup = false
        if (group.preview === true && !isRoot()) showGroup = false

        if (showGroup) {
          currentGroup.items = group.items.filter((el) => {
            let visible = null

            if (el.preview === true && !isRoot()) return false
            if (!isRoot() && el.flag && !company.value.flags.includes(el.flag)) return false
            if (typeof el.visible === 'function') {
              visible = el.visible()
              if (visible === false) return false
            }

            if (el.granted === true) return true

            if ([null, true].includes(visible)) {
              return (
                cap('role', `${el.key}`).length > 0 ||
                can('role', `${el.key}.read`) ||
                (can('role', `documents.read`) &&
                  ['quotes', 'invoices', 'credits', 'order_forms', 'supplier_invoices'].includes(el.key))
              )
            }

            return true
          })

          arr.push(currentGroup)
        }

        return arr
      }, [])
      .filter((el) => el.items.length > 0)
  })

  const companySettingsMenu = computed(() => {
    const filterItems = (el) => {
      if (el.preview && !isRoot()) return false
      if (!can('role', 'company.manage') && !can('role', `company.${el.key}`)) return false
      if (el.flag && !company.flags.includes(el.flag)) return false
      if (el.access && !can('role', el.access)) return false
      if (el.can && !can(...el.can)) return false
      if (typeof el.visible === 'function') return el.visible(company, isRoot)
      return true
    }

    return {
      account: {
        infos: {
          icon: ['fad', 'building'],
          items: [
            {
              key: 'info',
              name: 'info',
              route: 'info',
              icon: ['fad', 'mailbox'],
            },
            {
              key: 'insurance',
              name: 'insurance',
              route: 'insurance',
              icon: ['fad', 'house-crack'],
            },
            {
              key: 'users',
              name: 'users',
              route: 'users',
              icon: ['fad', 'users'],
              can: ['role', 'users.manage'],
            },
            {
              key: 'subscription',
              name: 'subscription',
              route: 'subscription',
              icon: ['fad', 'rocket'],
            },
            {
              key: 'misc',
              name: 'misc',
              route: 'misc',
              icon: ['fad', 'gear'],
            },
          ].filter(filterItems),
        },
        accounting: {
          icon: ['fad', 'calculator-simple'],
          items: [
            {
              key: 'tax',
              name: 'tax',
              route: 'tax',
              icon: ['fad', 'percent'],
            },
            {
              key: 'profit',
              name: 'profit',
              route: 'profit',
              icon: ['fad', 'gauge-max'],
            },
            {
              key: 'banks',
              name: 'banks',
              route: 'banks',
              icon: ['fad', 'building-columns'],
              access: 'accounting.banks',
            },
            {
              visible: (company) => company.accountingMode !== ACCOUNTING_MODE_DISABLED,
              key: 'accounting',
              name: 'accounting',
              route: 'accounting',
              icon: ['fad', 'calculator-simple'],
            },
            {
              key: 'categories',
              name: 'categories',
              route: 'categories',
              icon: ['fad', 'tags'],
              access: 'accounting.supplier_invoices',
            },
          ].filter(filterItems),
        },
      },
      app: {
        sales: {
          icon: ['fad', 'file-contract'],
          items: [
            {
              key: 'work_orders',
              name: 'work_orders',
              route: 'work-orders',
              icon: ['fad', 'toolbox'],
              visible: () => can('plan', 'documents.work_orders'),
            },
            {
              key: 'quotes',
              name: 'quotes',
              route: 'quotes',
              icon: ['fad', 'ruler-triangle'],
            },
            {
              key: 'invoices',
              name: 'invoices',
              route: 'invoices',
              icon: ['fad', 'receipt'],
            },
            {
              key: 'credits',
              name: 'credits',
              route: 'credits',
              icon: ['fad', 'hand-holding-dollar'],
            },
            /*{
              key: 'clients',
              name: 'clients',
              route: 'clients',
              icon: ['fad', 'book-user'],
            },*/
            {
              key: 'payments',
              name: 'payments',
              route: 'payments',
              icon: ['fad', 'wallet'],
            },
            {
              key: 'terms',
              name: 'terms',
              route: 'terms',
              icon: ['fad', 'file-contract'],
            },
            {
              key: 'products',
              name: 'products',
              route: 'products',
              icon: ['fad', 'folders'],
              can: ['plan', 'products.stock'],
            },
          ].filter(filterItems),
        },
        buys: {
          icon: ['fad', 'wallet'],
          items: [
            {
              key: 'order_forms',
              name: 'order-forms',
              route: 'order-forms',
              icon: ['fad', 'basket-shopping'],
            },
            /*{
              key: 'suppliers',
              name: 'suppliers',
              route: 'suppliers',
              icon: ['fad', 'industry-windows'],
            },*/
          ].filter(filterItems),
        },
        documents: {
          icon: ['fad', 'file'],
          items: [
            {
              key: 'builder',
              name: 'builder',
              route: 'builder',
              icon: ['fad', 'pen'],
            },
            {
              key: 'appearance',
              name: 'appearance',
              route: 'appearance',
              icon: ['fad', 'palette'],
            },
            {
              key: 'footer',
              name: 'footer',
              route: 'footer',
              icon: ['fad', 'file-dashed-line'],
            },
            {
              key: 'emails',
              name: 'emails',
              route: 'emails',
              icon: ['fad', 'envelope'],
            },
            {
              key: 'reminder',
              name: 'reminder',
              route: 'reminder',
              icon: ['fad', 'bell-on'],
            },
          ].filter(filterItems),
        },
      },
    }
  })

  return { sidebar, companySettingsMenu }
}
