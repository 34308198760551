//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {get as _get} from 'lodash-es'
import {withQuery} from 'ufo'
import useModal from '@/composables/modal'
import {useErrorState, useLoading} from '@/composables/loading'
import {computed, defineComponent, ref, useContext, useRoute, watch} from '@nuxtjs/composition-api'
import {useUser} from '@/composables/user'
import {plansColor} from '@/config/plans'
import {
  TYPE_PRIVATE_LIMITED,
  TYPE_PUBLIC_LIMITED,
  TYPE_PUBLIC_UNLISTED,
  TYPE_SOLE_SHAREHOLDER_LIMITED
} from "@/constants/companies";

export default defineComponent({
  setup(props, { emit }) {
    const modal = useModal()

    const { $getRepository, $axios, app, env } = useContext()
    const appURL = env.APP_URL
    const route = useRoute()

    const { isLoading, setLoading } = useLoading()
    const { hasErrorState } = useErrorState()

    const { company, subscription } = useUser()

    const hasOldPlan = computed(() => _get(subscription.value, 'plan.old', false) === true)
    const displayOldPlans = ref(_get(company.value, 'newPlans', false) === false)

    const group = ref(
        ['business_plus', 'enterprise'].includes(_get(subscription.value, 'plan.slug', null)) ||
        [TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED, TYPE_SOLE_SHAREHOLDER_LIMITED].includes(company.value.type) ? 'business' : 'solo')

    const interval = ref('year')
    const users = ref(
      [displayOldPlans.value ? null : 1, 2, 5, 10, displayOldPlans.value ? null :  20].filter(Boolean).includes(_get(subscription.value, 'plan.users', 2)) ? _get(subscription.value, 'plan.users', 2) : 2
    )

    const hasCurrentPlan = (name) =>
      (name !== 'business_plus' && _get(subscription.value, 'plan.slug', null) === name) ||
      (name === 'business_plus' &&
        _get(subscription.value, 'plan.slug', null) === name &&
        users.value === _get(subscription.value, 'plan.users', null))

    const faqItems = ref(
      ['why_yearly', 'stop', 'payment_methods', 'payment_secure', 'vat_included', 'support_included', 'import_data'].reduce((obj, item) => {
        obj[item] = false
        return obj
      }, {})
    )

    const plans = computed(() => {
      return {
        starter: {
          pricing: {
            year: 0,
            month: 0,
            save: null,
          },
          mainFeatures: ['quotes', 'products', 'contacts'],
        },
        pro: {
          pricing: {
            year: 15000,
            month: 1500,
            je: {
              year: 10000,
              month: 1000
            },
            save: 30,
          },
          users: '1 utilisateur',
          upgradeFrom: 'starter',
          mainFeatures: [
            'projects',
            'down_payments',
            'profits',
            'total_deductions',
            'options',
            'payments',
            'custom_documents',
            'whitelabel',
            'vat_attestations',
            'custom_emails',
            'email_documents',
            'clients_portal',
            'export',
            'incomes_book',
            'training',
            'assistance'
          ],
          tooltips: {
            clients_portal: `Le portail client permet à vos clients de consulter leurs documents en ligne, signer les devis électroniquement, retrouver vos coordonnées...`
          },
          icons: {
            training: '💼',
            assistance: '🛟',
          },
          upcoming: [],
          new: ['incomes_book'],
        },
        business: {
          pricing: {
            year: displayOldPlans.value ? 25000 : 30000,
            month: displayOldPlans.value ? 2500 : 3000,
            je: {
              year: displayOldPlans.value ? 20000 : 25000,
              month: displayOldPlans.value ? 2000 : 2500
            },
            save: displayOldPlans.value ? 50 : 60,
          },
          users: '1 utilisateur + 1 accès comptable',
          upgradeFrom: 'pro',
          mainFeatures: [
            'employees_2',
            'calendar',
            'projects_planning',
            'projects_profitability',
            'work_orders',
            'situation_invoice',
            'holdbacks',
            'subtotal_deductions',
            'document_images',
            'attachments',
            'unpaid',
            'suppliers',
            'supplier_invoices',
            'order_forms',

            'stock',
            'certificates',
            'accounting',
            'priority_assistance'
          ],
          icons: {
            priority_assistance: '🛟',
          },
          upcoming: ['express_payment'],
          new: ['work_orders'],
        },
        business_plus: {
          pricing: {
            users: {
              1: {
                year:  35000,
                month: 3500,
                je: {
                  year:  30000,
                  month: 3000
                },
                save: 70,
              },
              2: {
                year: displayOldPlans.value ? 35000 : 45000,
                month: displayOldPlans.value ? 3500 : 4500,
                je: {
                  year: displayOldPlans.value ? 30000 : 40000,
                  month: displayOldPlans.value ? 3000 : 400
                },
                save: displayOldPlans.value ? 70 : 90,
              },
              5: {
                year: displayOldPlans.value ? 45000 : 60000,
                month: displayOldPlans.value ? 4500 : 6000,
                je: {
                  year: displayOldPlans.value ? 40000 : 55000,
                  month: displayOldPlans.value ? 4000 : 5500
                },
                save: displayOldPlans.value ? 90 : 120,
              },
              10: {
                year: displayOldPlans.value ? 60000 : 90000,
                month: displayOldPlans.value ? 6000 : 9000,
                je: {
                  year: displayOldPlans.value ? 55000 : 85000,
                  month: displayOldPlans.value ? 5500 : 8500
                },
                save: displayOldPlans.value ? 120 : 180,
              },
              20: {
                year: 150000,
                month: 15000,
                je: {
                  year: 145000,
                  month: 14500
                },
                save: 300
              },
            },
          },
          upgradeFrom: 'business',
          mainFeatures: ['employees_full', 'timesheets', 'ocr', 'bank', 'bank_justify', 'quotes_history', 'dpgf'],
          tooltips: {
            quotes_history: 'Les 10 dernières versions de vos devis sont stockées.',
            emails_history: 'Les emails envoyés sont stockés pendant 1 an.',
          },
          upcoming: ['express_payment'],
          new: ['bank', 'bank_justify', 'timesheets'],
          subscriptionGifts: displayOldPlans.value ? ['signature_10', 'online_payment_10'] : ['signature_full', 'online_payment_full'],
        },
      }
    })

    const commonFeatures = ['electronic_signature', 'online_payment', 'batiprix']
    const newFeatures = []


    const availablePlans = computed(() =>
      Object.entries(plans.value).reduce((obj, [k, v]) => {

        if (group.value === 'solo') {
          if (['pro', 'business', 'business_plus'].includes(k)) {
            obj[k] = v
          }
        } else {
          if (['pro', 'business', 'business_plus'].includes(k)) {
            obj[k] = v
          }
        }

        return obj
      }, {})
    )

    const selectPlan = async (plan) => {
      try {
        setLoading(true, `select_plan_${plan}`)
        const { data } = await $axios.post(`api/billing/subscribe`, {
          plan,
          interval: interval.value,
          users: plan === 'business_plus' ? users.value : undefined,
          cancel_url: withQuery(`${appURL}${route.value.fullPath}`, { payment_cancel: 1 }),
          success_url: withQuery(`${appURL}`, { payment_success: 1 }),
        })
        const url = _get(data, 'data.url', null)

        if (url !== null) {
          window.location.href = url
        } else {
          app.$toast.error('stripe error', {
            duration: 2000,
          })
        }
      } catch (err) {
      } finally {
        setLoading(false, `select_plan_${plan}`)
      }
    }

    const managePlan = async (name) => {
      try {
        setLoading(true, `manage_plan_${name}`)
        const { data } = await $axios.get('api/billing/portal')
        const url = _get(data, 'data.url', null)

        if (url !== null) {
          window.location.href = url
        } else {
          app.$toast.error('stripe error', {
            duration: 2000,
          })
        }
      } catch (err) {
      } finally {
        setLoading(false, `manage_plan_${name}`)
      }
    }

    const getJEPrice = (plan) => {

      if (plan.pricing.users) {
        if (interval.value === 'year') return plan.pricing.users[users.value].je[interval.value] / 12
        else return plan.pricing.users[users.value].je[interval.value]
      } else {
        if (interval.value === 'year') return plan.pricing.je[interval.value] / 12
        else return plan.pricing.je[interval.value]
      }


    }

    const getSavings = (plan, users) =>  Math.round((plan.pricing.users ? plan.pricing.users[users].save : plan.pricing.save))

    watch(() => modal.isModalOpen(), async (val) => {
      if (val) {
        try {
          const { data } = await $axios.$get('/api/users/me')
          displayOldPlans.value = data.company.newPlans === false
        } catch (err) {

        }

      }
    })

    return {
      props,
      ...modal,
      app,
      users,
      availablePlans,
      commonFeatures,
      newFeatures,
      plans,
      plansColor,
      group,
      interval,
      company,
      subscription,
      faqItems,
      hasOldPlan,
      displayOldPlans,
      getSavings,
      getJEPrice,
      hasCurrentPlan,
      selectPlan,
      managePlan,
      isLoading,
      hasErrorState,
      _get,
    }
  },
})
