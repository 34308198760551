//be930f21721cf332511f6d449742d42d87e49676
import Vue from "vue"
import { library, config } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import {
  faSpinnerThird as falSpinnerThird,
  faCircleInfo as falCircleInfo,
  faArrowsRepeat as falArrowsRepeat,
  faChevronDown as falChevronDown,
  faUserTie as falUserTie,
  faCheck as falCheck,
  faClock as falClock,
  faPen as falPen,
  faTrashCan as falTrashCan,
  faXmark as falXmark,
  faRulerTriangle as falRulerTriangle,
  faToolbox as falToolbox,
  faPersonDigging as falPersonDigging,
  faUser as falUser,
  faHelmetSafety as falHelmetSafety,
  faGear as falGear,
  faChevronCircleRight as falChevronCircleRight,
  faEraser as falEraser,
  faPhone as falPhone,
  faEnvelope as falEnvelope,
  faLockOpen as falLockOpen,
  faPlus as falPlus,
  faSlidersUp as falSlidersUp,
  faCalendarDays as falCalendarDays,
  faLockHashtag as falLockHashtag,
  faRotateLeft as falRotateLeft,
  faCloud as falCloud,
  faCloudXmark as falCloudXmark,
  faCloudCheck as falCloudCheck,
  faRepeat as falRepeat,
  faTruckLadder as falTruckLadder,
  faCalendarDay as falCalendarDay,
  faLink as falLink,
  faListCheck as falListCheck,
  faArrowUpToLine as falArrowUpToLine,
  faArrowDownToLine as falArrowDownToLine,
  faCopy as falCopy,
  faArrowsUpDownLeftRight as falArrowsUpDownLeftRight,
  faArrowUpRightAndArrowDownLeftFromCenter as falArrowUpRightAndArrowDownLeftFromCenter,
  faCalculatorSimple as falCalculatorSimple,
  faChevronRight as falChevronRight,
  faBolt as falBolt,
  faBox as falBox,
  faFileInvoice as falFileInvoice,
  faMinus as falMinus,
  faMoneyCheck as falMoneyCheck,
  faCreditCard as falCreditCard,
  faCoins as falCoins,
  faEuroSign as falEuroSign,
  faBuildingColumns as falBuildingColumns,
  faArrowPointer as falArrowPointer,
  faChevronUp as falChevronUp,
  faPaperclip as falPaperclip,
  faMoneyBillTransfer as falMoneyBillTransfer,
  faEye as falEye,
  faPercent as falPercent,
  faFileImport as falFileImport,
  faArrowsRotate as falArrowsRotate,
  faPalette as falPalette,
  faPrint as falPrint,
  faPaperPlane as falPaperPlane,
  faCircleQuestion as falCircleQuestion,
  faRotateExclamation as falRotateExclamation,
  faPencil as falPencil,
  faPlusMinus as falPlusMinus,
  faThumbsUp as falThumbsUp,
  faThumbsDown as falThumbsDown,
  faSignature as falSignature,
  faChevronLeft as falChevronLeft,
  faMagnifyingGlass as falMagnifyingGlass,
  faDownload as falDownload,
  faHandHoldingDollar as falHandHoldingDollar,
  faFileExport as falFileExport,
  faEllipsis as falEllipsis,
  faPenField as falPenField,
  faStamp as falStamp,
  faChartGantt as falChartGantt,
  faFile as falFile,
  faBan as falBan,
  faTruck as falTruck,
  faBoxCheck as falBoxCheck,
  faCircleXmark as falCircleXmark,
  faCalendar as falCalendar,
  faClockRotateLeft as falClockRotateLeft,
  faWrench as falWrench,
  faBoxOpen as falBoxOpen,
  faShareNodes as falShareNodes,
  faEnvelopeCircleCheck as falEnvelopeCircleCheck,
  faTriangleExclamation as falTriangleExclamation,
  faCommentSms as falCommentSms,
  faEyeDropper as falEyeDropper,
  faWandMagicSparkles as falWandMagicSparkles,
  faUpload as falUpload,
  faFolderOpen as falFolderOpen,
  faFolder as falFolder,
  faHashtag as falHashtag,
  faArrowLeft as falArrowLeft,
  faReceipt as falReceipt,
  faArrowRight as falArrowRight,
  faFilePdf as falFilePdf,
  faMemoCircleInfo as falMemoCircleInfo,
  faCalendarClock as falCalendarClock,
  faMessage as falMessage,
  faNote as falNote,
  faCamera as falCamera,
  faTag as falTag,
  faLock as falLock,
  faLayerGroup as falLayerGroup,
  faLinkSlash as falLinkSlash,
  faCloudArrowUp as falCloudArrowUp,
  faRocket as falRocket,
  faArrowRightFromBracket as falArrowRightFromBracket,
  faForwardStep as falForwardStep,
  faCircleDollarToSlot as falCircleDollarToSlot,
  faBellOn as falBellOn,
  faEyeSlash as falEyeSlash
} from "@fortawesome/pro-light-svg-icons"

import {
  faCheck as farCheck,
  faSpinnerThird as farSpinnerThird,
  faUpRightFromSquare as farUpRightFromSquare,
  faArrowsRotate as farArrowsRotate,
  faLink as farLink,
  faEllipsisVertical as farEllipsisVertical,
  faLinkSlash as farLinkSlash,
  faTrashCan as farTrashCan,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronDown as farChevronDown,
  faXmark as farXmark,
  faUpload as farUpload,
  faIndustryWindows as farIndustryWindows,
  faUser as farUser,
  faEllipsis as farEllipsis,
  faDiamondTurnRight as farDiamondTurnRight,
  faPlus as farPlus,
  faTriangleExclamation as farTriangleExclamation,
  faMagnifyingGlassMinus as farMagnifyingGlassMinus,
  faMagnifyingGlassPlus as farMagnifyingGlassPlus,
  faCalculatorSimple as farCalculatorSimple,
  faPersonDolly as farPersonDolly,
  faPersonDigging as farPersonDigging,
  faMinus as farMinus,
  faImage as farImage,
  faArrowUp as farArrowUp,
  faArrowDown as farArrowDown,
  faSplit as farSplit,
  faLockOpen as farLockOpen,
  faPen as farPen,
  faWrench as farWrench,
  faFileExcel as farFileExcel,
  faCoins as farCoins,
  faClone as farClone,
  faRotateLeft as farRotateLeft,
  faNoteSticky as farNoteSticky,
  faHourglassStart as farHourglassStart,
  faSignature as farSignature,
  faEnvelope as farEnvelope,
  faFilePdf as farFilePdf,
  faArrowsUpDown as farArrowsUpDown,
  faArrowsToDottedLine as farArrowsToDottedLine,
  faMagnifyingGlass as farMagnifyingGlass,
  faBullhorn as farBullhorn,
  faCamera as farCamera,
  faCircleQuestion as farCircleQuestion,
  faChevronUp as farChevronUp,
  faToolbox as farToolbox,
  faRulerTriangle as farRulerTriangle,
  faFolderPlus as farFolderPlus,
  faFileExport as farFileExport,
  faBell as farBell,
  faScreenUsers as farScreenUsers,
  faBooks as farBooks,
  faArrowRight as farArrowRight,
  faPhone as farPhone,
  faBarsProgress as farBarsProgress,
  faSquareCheck as farSquareCheck,
  faClipboardListCheck as farClipboardListCheck,
  faPaperclip as farPaperclip,
  faArrowLeft as farArrowLeft,
  faTextSize as farTextSize,
  faAlignSlash as farAlignSlash,
  faTextSlash as farTextSlash,
  faEraser as farEraser,
  faBold as farBold,
  faItalic as farItalic,
  faStrikethrough as farStrikethrough,
  faUnderline as farUnderline,
  faAlignLeft as farAlignLeft,
  faAlignCenter as farAlignCenter,
  faAlignJustify as farAlignJustify,
  faAlignRight as farAlignRight,
  faParagraph as farParagraph,
  faH1 as farH1,
  faH2 as farH2,
  faH3 as farH3,
  faListUl as farListUl,
  faListOl as farListOl,
  faFlagCheckered as farFlagCheckered,
  faClockRotateLeft as farClockRotateLeft,
  faPaperPlane as farPaperPlane,
  faThumbsUp as farThumbsUp,
  faThumbsDown as farThumbsDown,
  faBan as farBan,
  faClock as farClock,
  faCheckDouble as farCheckDouble,
  faMessageExclamation as farMessageExclamation,
  faCalendarExclamation as farCalendarExclamation,
  faUnlock as farUnlock,
  faTruck as farTruck,
  faBoxCircleCheck as farBoxCircleCheck,
  faHelmetSafety as farHelmetSafety,
  faBlockBrick as farBlockBrick,
  faIdBadge as farIdBadge,
  faUserHelmetSafety as farUserHelmetSafety,
  faHammer as farHammer,
  faCreditCard as farCreditCard,
  faCartCircleArrowUp as farCartCircleArrowUp,
  faBox as farBox,
  faDownLeftAndUpRightToCenter as farDownLeftAndUpRightToCenter,
  faExpandWide as farExpandWide,
  faHourglass as farHourglass,
  faListCheck as farListCheck,
  faEyeSlash as farEyeSlash,
  faEye as farEye
} from "@fortawesome/pro-regular-svg-icons"

import {
  faLock as fasLock,
  faTriangleExclamation as fasTriangleExclamation,
  faFilePlus as fasFilePlus,
  faCircleCheck as fasCircleCheck,
  faFilePdf as fasFilePdf,
  faFileImage as fasFileImage,
  faCheck as fasCheck,
  faPersonDigging as fasPersonDigging,
  faRulerTriangle as fasRulerTriangle,
  faEye as fasEye,
  faStar as fasStar,
  faCaretDown as fasCaretDown,
  faCaretRight as fasCaretRight,
  faCircleInfo as fasCircleInfo,
  faPlus as fasPlus,
  faReceipt as fasReceipt,
  faUser as fasUser,
  faPen as fasPen,
  faWifiSlash as fasWifiSlash,
  faFloppyDisk as fasFloppyDisk,
  faChevronDown as fasChevronDown,
  faBolt as fasBolt,
  faFolderPlus as fasFolderPlus,
  faFolderArrowUp as fasFolderArrowUp,
  faGripDotsVertical as fasGripDotsVertical,
  faChevronUp as fasChevronUp,
  faEllipsisVertical as fasEllipsisVertical,
  faBox as fasBox,
  faFileInvoice as fasFileInvoice,
  faUserCheck as fasUserCheck,
  faWandMagicSparkles as fasWandMagicSparkles,
  faPaperPlane as fasPaperPlane,
  faMinus as fasMinus,
  faPhone as fasPhone,
  faEnvelope as fasEnvelope,
  faEarthEurope as fasEarthEurope,
  faFileExcel as fasFileExcel,
  faFileWord as fasFileWord,
  faFileLines as fasFileLines,
  faCalendarDays as fasCalendarDays,
  faPlay as fasPlay,
  faBullhorn as fasBullhorn,
  faMessageQuestion as fasMessageQuestion,
  faBarsSort as fasBarsSort,
  faBars as fasBars,
  faFolderXmark as fasFolderXmark,
  faGift as fasGift,
  faBadgePercent as fasBadgePercent,
  faSort as fasSort,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faToolbox as fasToolbox,
  faExclamation as fasExclamation
} from "@fortawesome/pro-solid-svg-icons"

import {
  faCalendarClock as fatCalendarClock,
  faCalendarCheck as fatCalendarCheck
} from "@fortawesome/pro-thin-svg-icons"

import {
  faCircleInfo as fadCircleInfo,
  faTriangleExclamation as fadTriangleExclamation,
  faLaptop as fadLaptop,
  faUserHelmetSafety as fadUserHelmetSafety,
  faCreditCard as fadCreditCard,
  faUser as fadUser,
  faRulerTriangle as fadRulerTriangle,
  faReceipt as fadReceipt,
  faBookUser as fadBookUser,
  faPersonDigging as fadPersonDigging,
  faFolders as fadFolders,
  faLightbulb as fadLightbulb,
  faGear as fadGear,
  faChartColumn as fadChartColumn,
  faFileInvoice as fadFileInvoice,
  faFileZipper as fadFileZipper,
  faHandHoldingDollar as fadHandHoldingDollar,
  faFilePen as fadFilePen,
  faSignature as fadSignature,
  faCalendarClock as fadCalendarClock,
  faPaperPlane as fadPaperPlane,
  faShareFromSquare as fadShareFromSquare,
  faFilePdf as fadFilePdf,
  faBook as fadBook,
  faDownToLine as fadDownToLine,
  faPenField as fadPenField,
  faSortUp as fadSortUp,
  faSortDown as fadSortDown,
  faFolder as fadFolder,
  faSackDollar as fadSackDollar,
  faFile as fadFile,
  faArrowRightFromBracket as fadArrowRightFromBracket,
  faChartLine as fadChartLine,
  faCalendarDays as fadCalendarDays,
  faListTimeline as fadListTimeline,
  faToolbox as fadToolbox,
  faCoins as fadCoins,
  faBuildingColumns as fadBuildingColumns,
  faBasketShopping as fadBasketShopping,
  faIndustryWindows as fadIndustryWindows,
  faHeart as fadHeart,
  faLifeRing as fadLifeRing,
  faBuilding as fadBuilding,
  faMailbox as fadMailbox,
  faHouseCrack as fadHouseCrack,
  faUsers as fadUsers,
  faRocket as fadRocket,
  faCalculatorSimple as fadCalculatorSimple,
  faPercent as fadPercent,
  faGaugeMax as fadGaugeMax,
  faTags as fadTags,
  faFileContract as fadFileContract,
  faWallet as fadWallet,
  faPen as fadPen,
  faPalette as fadPalette,
  faFileDashedLine as fadFileDashedLine,
  faEnvelope as fadEnvelope,
  faBellOn as fadBellOn
} from "@fortawesome/pro-duotone-svg-icons"

import {
  faWhatsapp as fabWhatsapp
} from "@fortawesome/free-brands-svg-icons"

library.add(
  falSpinnerThird,
  falCircleInfo,
  falArrowsRepeat,
  falChevronDown,
  falUserTie,
  falCheck,
  falClock,
  falPen,
  falTrashCan,
  falXmark,
  falRulerTriangle,
  falToolbox,
  falPersonDigging,
  falUser,
  falHelmetSafety,
  falGear,
  falChevronCircleRight,
  falEraser,
  falPhone,
  falEnvelope,
  falLockOpen,
  falPlus,
  falSlidersUp,
  falCalendarDays,
  falLockHashtag,
  falRotateLeft,
  falCloud,
  falCloudXmark,
  falCloudCheck,
  falRepeat,
  falTruckLadder,
  falCalendarDay,
  falLink,
  falListCheck,
  falArrowUpToLine,
  falArrowDownToLine,
  falCopy,
  falArrowsUpDownLeftRight,
  falArrowUpRightAndArrowDownLeftFromCenter,
  falCalculatorSimple,
  falChevronRight,
  falBolt,
  falBox,
  falFileInvoice,
  falMinus,
  falMoneyCheck,
  falCreditCard,
  falCoins,
  falEuroSign,
  falBuildingColumns,
  falArrowPointer,
  falChevronUp,
  falPaperclip,
  falMoneyBillTransfer,
  falEye,
  falPercent,
  falFileImport,
  falArrowsRotate,
  falPalette,
  falPrint,
  falPaperPlane,
  falCircleQuestion,
  falRotateExclamation,
  falPencil,
  falPlusMinus,
  falThumbsUp,
  falThumbsDown,
  falSignature,
  falChevronLeft,
  falMagnifyingGlass,
  falDownload,
  falHandHoldingDollar,
  falFileExport,
  falEllipsis,
  falPenField,
  falStamp,
  falChartGantt,
  falFile,
  falBan,
  falTruck,
  falBoxCheck,
  falCircleXmark,
  falCalendar,
  falClockRotateLeft,
  falWrench,
  falBoxOpen,
  falShareNodes,
  falEnvelopeCircleCheck,
  falTriangleExclamation,
  falCommentSms,
  falEyeDropper,
  falWandMagicSparkles,
  falUpload,
  falFolderOpen,
  falFolder,
  falHashtag,
  falArrowLeft,
  falReceipt,
  falArrowRight,
  falFilePdf,
  falMemoCircleInfo,
  falCalendarClock,
  falMessage,
  falNote,
  falCamera,
  falTag,
  falLock,
  falLayerGroup,
  falLinkSlash,
  falCloudArrowUp,
  falRocket,
  falArrowRightFromBracket,
  falForwardStep,
  falCircleDollarToSlot,
  falBellOn,
  falEyeSlash
)

library.add(
  farCheck,
  farSpinnerThird,
  farUpRightFromSquare,
  farArrowsRotate,
  farLink,
  farEllipsisVertical,
  farLinkSlash,
  farTrashCan,
  farChevronLeft,
  farChevronRight,
  farChevronDown,
  farXmark,
  farUpload,
  farIndustryWindows,
  farUser,
  farEllipsis,
  farDiamondTurnRight,
  farPlus,
  farTriangleExclamation,
  farMagnifyingGlassMinus,
  farMagnifyingGlassPlus,
  farCalculatorSimple,
  farPersonDolly,
  farPersonDigging,
  farMinus,
  farImage,
  farArrowUp,
  farArrowDown,
  farSplit,
  farLockOpen,
  farPen,
  farWrench,
  farFileExcel,
  farCoins,
  farClone,
  farRotateLeft,
  farNoteSticky,
  farHourglassStart,
  farSignature,
  farEnvelope,
  farFilePdf,
  farArrowsUpDown,
  farArrowsToDottedLine,
  farMagnifyingGlass,
  farBullhorn,
  farCamera,
  farCircleQuestion,
  farChevronUp,
  farToolbox,
  farRulerTriangle,
  farFolderPlus,
  farFileExport,
  farBell,
  farScreenUsers,
  farBooks,
  farArrowRight,
  farPhone,
  farBarsProgress,
  farSquareCheck,
  farClipboardListCheck,
  farPaperclip,
  farArrowLeft,
  farTextSize,
  farAlignSlash,
  farTextSlash,
  farEraser,
  farBold,
  farItalic,
  farStrikethrough,
  farUnderline,
  farAlignLeft,
  farAlignCenter,
  farAlignJustify,
  farAlignRight,
  farParagraph,
  farH1,
  farH2,
  farH3,
  farListUl,
  farListOl,
  farFlagCheckered,
  farClockRotateLeft,
  farPaperPlane,
  farThumbsUp,
  farThumbsDown,
  farBan,
  farClock,
  farCheckDouble,
  farMessageExclamation,
  farCalendarExclamation,
  farUnlock,
  farTruck,
  farBoxCircleCheck,
  farHelmetSafety,
  farBlockBrick,
  farIdBadge,
  farUserHelmetSafety,
  farHammer,
  farCreditCard,
  farCartCircleArrowUp,
  farBox,
  farDownLeftAndUpRightToCenter,
  farExpandWide,
  farHourglass,
  farListCheck,
  farEyeSlash,
  farEye
)

library.add(
  fasLock,
  fasTriangleExclamation,
  fasFilePlus,
  fasCircleCheck,
  fasFilePdf,
  fasFileImage,
  fasCheck,
  fasPersonDigging,
  fasRulerTriangle,
  fasEye,
  fasStar,
  fasCaretDown,
  fasCaretRight,
  fasCircleInfo,
  fasPlus,
  fasReceipt,
  fasUser,
  fasPen,
  fasWifiSlash,
  fasFloppyDisk,
  fasChevronDown,
  fasBolt,
  fasFolderPlus,
  fasFolderArrowUp,
  fasGripDotsVertical,
  fasChevronUp,
  fasEllipsisVertical,
  fasBox,
  fasFileInvoice,
  fasUserCheck,
  fasWandMagicSparkles,
  fasPaperPlane,
  fasMinus,
  fasPhone,
  fasEnvelope,
  fasEarthEurope,
  fasFileExcel,
  fasFileWord,
  fasFileLines,
  fasCalendarDays,
  fasPlay,
  fasBullhorn,
  fasMessageQuestion,
  fasBarsSort,
  fasBars,
  fasFolderXmark,
  fasGift,
  fasBadgePercent,
  fasSort,
  fasChevronLeft,
  fasChevronRight,
  fasToolbox,
  fasExclamation
)

library.add(
  fatCalendarClock,
  fatCalendarCheck
)

library.add(
  fadCircleInfo,
  fadTriangleExclamation,
  fadLaptop,
  fadUserHelmetSafety,
  fadCreditCard,
  fadUser,
  fadRulerTriangle,
  fadReceipt,
  fadBookUser,
  fadPersonDigging,
  fadFolders,
  fadLightbulb,
  fadGear,
  fadChartColumn,
  fadFileInvoice,
  fadFileZipper,
  fadHandHoldingDollar,
  fadFilePen,
  fadSignature,
  fadCalendarClock,
  fadPaperPlane,
  fadShareFromSquare,
  fadFilePdf,
  fadBook,
  fadDownToLine,
  fadPenField,
  fadSortUp,
  fadSortDown,
  fadFolder,
  fadSackDollar,
  fadFile,
  fadArrowRightFromBracket,
  fadChartLine,
  fadCalendarDays,
  fadListTimeline,
  fadToolbox,
  fadCoins,
  fadBuildingColumns,
  fadBasketShopping,
  fadIndustryWindows,
  fadHeart,
  fadLifeRing,
  fadBuilding,
  fadMailbox,
  fadHouseCrack,
  fadUsers,
  fadRocket,
  fadCalculatorSimple,
  fadPercent,
  fadGaugeMax,
  fadTags,
  fadFileContract,
  fadWallet,
  fadPen,
  fadPalette,
  fadFileDashedLine,
  fadEnvelope,
  fadBellOn
)

library.add(
  fabWhatsapp
)

config.autoAddCss = false
Vue.component("fa-icon", FontAwesomeIcon)