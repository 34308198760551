var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center",class:_vm.context === 'project' ? '!-mt-5' : 'max-w-xl mx-auto flex-col justify-center'},[(!['dashboard', 'project'].includes(_vm.context))?_c('div',{staticClass:"shrink-0",class:{
      'mx-auto': _vm.context !== 'project',
      'w-32': _vm.context === 'dashboard',
      'w-40': _vm.context !== 'dashboard',
    }},[_c('img',{attrs:{"src":require(("~/assets/img/" + _vm.image))}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-center",class:{
      textClass: true,
      'flex items-center ': _vm.context === 'project',
    }},[(_vm.$t((_vm.type + ".emptyState." + (_vm.context || 'default') + ".title" + (_vm.resourceType ? ("_" + _vm.resourceType) : ''))))?_c('h2',{staticClass:"text-gray-600",class:_vm.context !== 'default' ? 'text-base' : 'text-lg',domProps:{"innerHTML":_vm._s(_vm.$t((_vm.type + ".emptyState." + (_vm.context || 'default') + ".title" + (_vm.resourceType ? ("_" + _vm.resourceType) : ''))))}}):_vm._e(),_vm._v(" "),_vm._t("action",function(){return [(_vm.allowCreate && _vm.context !== 'transactions')?_c('div',{staticClass:"mt-4"},[_c('Button',{attrs:{"color":"brand","size":"large","icon-before":['far', 'plus']},on:{"clicked":function($event){return _vm.$emit('create')}}},[_vm._v(_vm._s(_vm.$t((_vm.type + ".emptyState." + (_vm.context || 'default') + ".action.create"))))])],1):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }