export default {
  plan: {
    starter: {
      projects: [],
      documents: ['payments'],
      products: [],
      emails: [],
      users: [],
      accounting: [],
    },
    pro: {
      projects: ['manage'],
      documents: [
        'archives',
        'name',
        'templating',
        'vat_attestation',
        'down_payment',
        'payments',
        'online_payment',
        'electronic_signature',
        'terms',
        'notes',
        'profitability',
        'total_deductions',
        'optional',
        'library',
        'export',
        'reuse_lines',
        'update_vat',
        'update_prices',
      ],
      incomes: ['manage'],
      invoices: ['edit_finalized'],
      products: ['address_autocomplete', 'rich_text', 'works', 'category', 'batiprix'],
      emails: ['history', 'customize'],
      users: [],
      accounting: [],
    },
    business: {
      projects: ['manage', 'planning', 'certificates', 'profitability'],
      documents: [
        'archives',
        'assignee',
        'name',
        'templating',
        'vat_attestation',
        'down_payment',
        'payments',
        'online_payment',
        'electronic_signature',
        'holdback',
        'situation',
        'order_forms',
        'work_orders',
        'unpaid_reminder',
        'total_deductions',
        'subtotal_deductions',
        'terms',
        'notes',
        'profitability',
        'optional',
        'files',
        'library',
        'export',
        'reuse_lines',
        'update_vat',
        'update_prices',
      ],
      incomes: ['manage'],
      invoices: ['edit_finalized'],
      quotes: ['import'],
      users: ['manage'],
      products: ['address_autocomplete', 'rich_text', 'works', 'images', 'category', 'batiprix', 'stock', 'import'],
      emails: ['history', 'customize', 'signature_logo'],
      accounting: ['export', 'access', 'supplier_invoices'],
    },
    business_plus: {
      projects: ['manage', 'planning', 'certificates', 'profitability'],
      documents: [
        'archives',
        'name',
        'templating',
        'vat_attestation',
        'down_payment',
        'payments',
        'online_payment',
        'electronic_signature',
        'holdback',
        'situation',
        'order_forms',
        'work_orders',
        'quotes_history',
        'unpaid_reminder',
        'total_deductions',
        'subtotal_deductions',
        'terms',
        'notes',
        'profitability',
        'optional',
        'files',
        'cover',
        'library',
        'export',
        'reuse_lines',
        'update_vat',
        'update_prices',
      ],
      incomes: ['manage'],
      invoices: ['edit_finalized'],
      quotes: ['import'],
      users: ['manage'],
      products: ['address_autocomplete', 'rich_text', 'images', 'batiprix', 'category', 'stock', 'import'],
      emails: ['history', 'customize', 'signature_logo'],
      accounting: ['export', 'access', 'supplier_invoices', 'banks', 'transactions'],
    },
    _rules: {
      documents: {
        unpaid_reminder: {
          trialUntil: new Date('2023-02-01'),
        },
      },
    },
  },
  role: {
    ROLE_ADMIN: {
      dashboard: ['read'],
      documents: ['read', 'write', 'export'],
      order_forms: ['read', 'write', 'export'],
      work_orders: ['read', 'write', 'create'],
      supplier_invoices: ['read', 'write', 'export'],
      company: ['manage'],
      contacts: ['read', 'write', 'export'],
      suppliers: ['read', 'write', 'export'],
      products: ['read', 'write', 'export', 'import'],
      payments: ['read', 'write', 'export'],
      projects: ['read', 'write', 'manage'],
      calendar: ['read', 'write', 'manage'],
      transactions: ['read', 'write'],
      holdbacks: ['read', 'write', 'export'],
    },
    ROLE_MANAGER: {
      documents: ['read', 'write', 'export'],
      order_forms: ['read', 'write', 'export'],
      work_orders: ['read', 'write', 'create'],
      supplier_invoices: ['read', 'write', 'export'],
      contacts: ['read', 'write', 'export'],
      suppliers: ['read', 'write', 'export'],
      products: ['read', 'write', 'export'],
      payments: ['read', 'write', 'export'],
      projects: ['read', 'write', 'manage'],
      calendar: ['read', 'write', 'manage'],
      holdbacks: ['read', 'write', 'export'],
      _home: 'projects',
    },
    ROLE_ACCOUNTANT: {
      quotes: ['read', 'export'],
      credits: ['read', 'export'],
      invoices: ['read', 'export'],
      payments: ['read', 'export'],
      supplier_invoices: ['read', 'export'],
      company: ['tax', 'accounting'],
      banks: ['read', 'write'],
      transactions: ['read', 'write'],
      incomes: ['read', 'write'],
      holdbacks: ['read', 'write', 'export'],
      _home: 'invoices',
    },
    ROLE_QUOTE_MANAGER: {
      quotes: ['read', 'write', 'export'],
      work_orders: ['read', 'write', 'create'],
      contacts: ['read', 'write'],
      suppliers: ['read', 'write'],
      products: ['read', 'write'],
      _home: 'quotes',
    },
    ROLE_SALES_MANAGER: {
      quotes: ['read', 'write', 'export'],
      invoices: ['read', 'write', 'export'],
      credits: ['read', 'write', 'export'],
      holdbacks: ['read', 'write', 'export'],
      payments: ['read', 'write', 'export'],
      contacts: ['read', 'write'],
      suppliers: ['read', 'write'],
      products: ['read', 'write'],
      _home: 'quotes',
    },
    ROLE_PURCHASES_MANAGER: {
      supplier_invoices: ['read', 'write', 'export'],
      supplier: ['read', 'write', 'export'],
      products: ['read', 'write'],
      _home: 'supplier_invoices',
    },
    ROLE_ORDERS_MANAGER: {
      order_forms: ['read', 'write', 'export'],
      quotes: ['read', 'write', 'export'],
      supplier: ['read', 'write', 'export'],
      products: ['read', 'write'],
      _home: 'order-forms',
    },
    ROLE_COMPANION: {
      calendar: ['read', 'write'],
      projects: ['read'],
      timesheet: ['read', 'write'],
      work_orders: ['read', 'write'],
      _home: 'calendar',
    },
    ROLE_USER: {
      documents: ['read', 'write'],
    },
  },
}
