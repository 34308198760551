import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { reactive, computed, set, toRefs } from '@nuxtjs/composition-api'
import { isValid } from 'date-fns'

export const makeStoreReadonlyProperties = (store, properties) => {
  const { useState } = createNamespacedHelpers(store)
  const props = useState(properties)
  const state = reactive({})

  for (const key of properties) {
    set(
      state,
      key,
      computed(() => props[key].value)
    )
  }

  return toRefs(state)
}

// Gets a list of properties from the given Store and returns computed with setters
export const makeStoreComputedProperties = (store, properties) => {
  const { useState, useMutations } = createNamespacedHelpers(store)
  const { setData } = useMutations(['setData'])

  const state = reactive({})
  const props = useState(Object.keys(properties))

  for (const [key, type] of Object.entries(properties)) {
    set(
      state,
      key,
      computed({
        get: () => props[key].value,
        set: (val) => {
          if (type === Date && val === '') {
            val = null
          }
          // This is wrong, I know! Won't last so long anyway...
          if (['deliveryAddressLabel', 'addressLabel', 'projectLabel'].includes(key)) {
            if (val !== null && val.length > 200) {
              val = val.slice(0, 200)
            }
          }
          if (passTypeCheck(type, val)) {
            setData({ key, val })
          }
        },
      })
    )
  }

  return toRefs(state)
}

const passTypeCheck = (type, val) => {
  if (type === Date) {
    return val === null || isValid(val)
  }
  return true
}
