//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, nextTick, onMounted, onUnmounted, ref, computed, watchEffect } from '@nuxtjs/composition-api'
import { Editor, EditorContent, BubbleMenu, mergeAttributes } from '@tiptap/vue-2'
import EmailContentPlaceholder from '../email/EmailContentPlaceholder'
import Document from '@tiptap/extension-document'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'
import ListItem from '@tiptap/extension-list-item'
import Placeholder from '@tiptap/extension-placeholder'
import OrderedList from '@tiptap/extension-ordered-list'
import Link from '@tiptap/extension-link'
import Strike from '@tiptap/extension-strike'
import Underline from '@tiptap/extension-underline'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import TextAlign from '@tiptap/extension-text-align'
import Paragraph from '@tiptap/extension-paragraph'
import FontSize from '@tobiasafischer/tiptap-extension-font-size'
import HardBreak from '@tiptap/extension-hard-break'
import { filter as _filter, flatten as _flatten, get as _get, has as _has } from 'lodash-es'
import { useToolbar } from '@/composables/editor'
import { anyToRgb, colors, nearestColor, rgbToHex } from '@/utils/palette'

import { saturation } from 'khroma'

export default defineComponent({
  components: {
    EditorContent,
    BubbleMenu,
  },
  props: {
    value: String,
    autoHideToolbar: {
      type: Boolean,
      default: false,
    },
    borderClass: {
      type: String,
      default: 'border-gray-200 border-b',
    },
    activeClass: {
      type: String,
      default: '',
    },
    toolbarDuration: {
      type: Number,
      default: 200,
    },
    toolbarBubble: Boolean,
    toolbarClass: String,
    hiddenToolbarClass: String,
    toolbarPosition: {
      type: String,
      default: 'top',
    },
    editorClass: {
      type: String,
      default: 'p-3 max-w-none',
    },
    proseClass: {
      type: String,
      default: 'prose-sm',
    },
    minHeight: {
      type: String,
      default: '',
    },
    extensions: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Number,
      default: 1,
    },
    autofocus: [Boolean, Number],
    data: Object,
    toolbar: {
      type: Array,
      default: () => {
        const { toolbar: val } = useToolbar([
          ['bold', 'italic', 'strike', 'underline', 'color', 'unsetAllMarks'],
          ['p', 'h1', 'h2', 'h3'],
          ['textLeft', 'textCenter', 'textJustify', 'textRight', 'unsetTextAlign'],
          ['ul', 'ol'],
          ['clearNodes'],
        ])
        return val
      },
    },
    placeholder: String,
    sticky: Boolean,
  },
  setup(props, { emit }) {
    const editor = ref(null)
    const init = ref(false)
    const activeEditor = ref(false)
    const hasActiveToolbarComponent = ref(false)
    const editorHeight = ref(null)
    let blurTimeout

    const allowLinks = computed(() => {
      const index = _flatten(props.toolbar).findIndex((el) => el.component === 'TextEditorToolbarLinkButton')
      return index !== -1
    })

    /* We can't just watch the props.value as v-model because it forces
     re-render and the cursor jumps to the end of the input */
    const updateValue = (val) => {
      if (editor.value !== null) {
        editor.value.commands.setContent(`${val}`)
      }
    }

    const cleanHTML = (str) =>
      str
        // Strips nbsp and specials chars
        .replaceAll(/\xA0/gm, ' ')
        .replaceAll(/\u200e/g, '')
        .replaceAll(/\u200f/g, '')
        // Handles Word
        .replaceAll(/<o:p>\s*<\/o:p>/g, '')
        .replaceAll(/<o:p>.*?<\/o:p>/g, ' ')
        .replaceAll(/\s*mso-[^:]+:[^;"]+;?/gi, '')
        .replaceAll(/<FONT\s*>(.*?)<\/FONT>/gi, '$1')
        .replaceAll(/<\\?\?xml[^>]*>/gi, '')
        .replaceAll(/<\/?\w+:[^>]*>/gi, '')

    onMounted(() => {
      editor.value = new Editor({
        editorProps: {
          drop: (view, e) => {
            e.preventDefault()
          },
          handleKeyDown: (editor, event) => {
            if (editor.state.selection.$anchor.pos + 1 >= editor.state.doc.content.size) {
              emit('keydown', event)
            }
          },
          handleDOMEvents: {
            keyup: (editor, event) => {
              let height = _get(event.target, 'offsetHeight')
              if (height) {
                if (editorHeight.value !== height) {
                  editorHeight.value = height
                  emit('resize', event)
                }
              }
            },
          },
          transformPastedText: (text) => text.replace(/\xA0/g, ' '),
          transformPastedHTML: (html) => {
            const toolbarFontSize = _filter(_flatten(props.toolbar), { component: 'TextEditorToolbarFontSizeButton' })
            const removeSize = toolbarFontSize.length === 0

            // Gets available heading levels
            const toolbarHeadings = _filter(_flatten(props.toolbar), { method: 'toggleHeading' })
            const headingMaxLevel = toolbarHeadings.reduce((level, data) => {
              const currentLevel = _get(data, 'methodArgs.level', null)
              if (currentLevel > level) level = currentLevel
              return level
            }, 0)

            let parsed = new DOMParser().parseFromString(cleanHTML(html), 'text/html').body

            ;(function recursiveCleaner(el) {
              if (el && el.childNodes.length > 0) {
                for (const child of el.childNodes) {
                  recursiveCleaner(child)
                }

                for (const child of el.childNodes) {
                  if (child.innerHTML) {
                    try {
                      // TODO use khroma for all colors manipulations
                      let color = anyToRgb(child.style.color)
                      if (Array.isArray(color)) {
                        // Replaces colors too close to grays by default color
                        if (saturation(child.style.color) < 30) {
                          color = anyToRgb('#555555')
                        } else {
                          // Replaces current color with nearest of available colors
                          color = anyToRgb(nearestColor(color, colors))
                        }
                      }

                      let colorString = null
                      // Don't apply color if black or default document gray
                      if (Array.isArray(color) && !['#000000', '#555555'].includes(rgbToHex(color))) {
                        colorString = `rgb(${color.join(',')})`
                      }

                      const fontWeight = child.style.fontWeight

                      // Handles Google docs content
                      if ((child.id.includes('docs-internal-guid') && child.tagName === 'B') || child.tagName === 'A') {
                        child.outerHTML = `<span>${child.innerHTML}</span>`
                      }

                      /*if (child.tagName === 'A' && child.parentNode) {
                        let newTag = document.createElement('span')
                        newTag.innerHTML = child.innerHTML
                        child.parentNode.replaceChild(child, newTag)
                        //child.outerHTML = `<span>${child.innerHTML}</span>`
                      }*/

                      child.removeAttribute('style')

                      // Replaces hn tags if not allowed in current editor
                      if (/(H([1-6]))/gi.test(child.tagName)) {
                        let level = Number(child.tagName.replace('H', ''))
                        if (level > headingMaxLevel) {
                          if (headingMaxLevel === 0) {
                            let innerStyle = colorString ? `style="color: ${colorString}"` : ''

                            if (level <= 2) {
                              child.outerHTML = `<div><strong><span ${innerStyle}>${child.innerHTML}</span></strong></div>`
                            } else {
                              child.outerHTML = `<div><span ${innerStyle}>${child.innerHTML}</span></div>`
                            }
                          } else {
                            child.outerHTML = `<h${headingMaxLevel}>${child.innerHTML}</h${headingMaxLevel}>`
                          }
                        }
                      }

                      if (colorString) {
                        child.innerHTML = `<span style="color: ${colorString}">${child.innerHTML}</span>`
                      }

                      child.style.fontWeight = fontWeight

                      if (removeSize) {
                        child.style.fontSize = undefined
                      }
                    } catch (err) {
                      console.log(err)
                    }

                    // Removes empty and whitespace-only elements

                    if (child.innerHTML.trim() === '') el.removeChild(child)
                    if (child.innerHTML.trim().match(/^\s+$/) !== null) el.removeChild(child)
                  }
                }
              }
            })(parsed)

            return parsed.innerHTML
          },
          attributes: {
            rows: props.rows,
            class: `${props.editorClass} prose ${props.proseClass} focus:outline-none`,
          },
          handleClick: (view, pos, event) => {
            let link = event.target.closest('a')
            if (link && editor.value) {
              try {
                editor.value.commands.extendMarkRange('link')
              } catch (err) {}
            }
          },
        },
        content: props.value,
        onUpdate: () => {
          emit('input', editor.value.isEmpty ? '' : editor.value.getHTML())
        },
        onFocus: ({ editor, event }) => {
          if (blurTimeout) {
            clearTimeout(blurTimeout)
          }
          activeEditor.value = true
          emit('focus')
        },
        onTransaction: ({ editor, transaction }) => {
          //console.log(editor)
          //console.log(event)
        },

        onBlur: ({ editor, event }) => {
          emit('blur')
          blurTimeout = setTimeout(() => {
            activeEditor.value = false
          }, 200)
        },
        extensions: [
          EmailContentPlaceholder,
          Document,
          Bold,
          BulletList,
          Heading,
          History,
          Italic,
          HardBreak,
          ListItem,
          OrderedList,
          Strike,
          Paragraph.extend({
            parseHTML() {
              return [{ tag: 'div' }]
            },
            renderHTML({ HTMLAttributes }) {
              return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
            },
          }),
          Underline.configure({
            HTMLAttributes: {
              class: 'wysiwyg-parent',
            },
          }),
          Text,
          TextStyle,
          FontSize,
          Color,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          Placeholder.configure({
            placeholder: props.placeholder,
          }),
        ].concat(
          allowLinks.value
            ? [
                Link.configure({
                  openOnClick: false,
                  defaultProtocol: 'https',
                  HTMLAttributes: {
                    class: 'text-brand-500 underline',
                    target: '_blank',
                  },
                }),
              ]
            : [],
          props.extensions
        ),
        autofocus: props.autofocus === true ? 'end' : props.autofocus,
      })

      setTimeout(() => (init.value = true), 0)
    })

    const handleEditorButton = (button) => {
      if (editor.value !== null && _has(button, 'method')) {
        if (button.method === 'unsetAllMarks') {
          editor.value.chain().focus().selectAll().unsetAllMarks().run()
        } else {
          editor.value
            .chain()
            .focus()
            [button.method](...[button.methodArgs]) // Calls button method with arguments
            .run()
        }
      }
    }

    const handleEditorAction = ([method, ...args]) => {
      if (editor.value !== null && method) {
        editor.value
          .chain()
          .focus()
          [method](...args)
          .run()
      }
    }

    const focusEditor = () => {
      if (editor.value !== null) {
        editor.value.commands.focus()
      }
    }

    onUnmounted(async () => {
      await nextTick()
      editor.value.destroy()
    })

    const showBorder = ref(false)
    let borderTimeout = null
    watchEffect(() => {
      if (hasActiveToolbarComponent.value || (props.autoHideToolbar && activeEditor.value) || !props.autoHideToolbar) {
        if (borderTimeout) {
          clearTimeout(borderTimeout)
        }
        showBorder.value = true
      } else {
        borderTimeout = setTimeout(() => (showBorder.value = false), 150)
      }
    })

    return {
      props,
      editor,
      init,
      allowLinks,
      activeEditor,
      showBorder,
      hasActiveToolbarComponent,
      focusEditor,
      updateValue,
      handleEditorButton,
      handleEditorAction,
    }
  },
})
