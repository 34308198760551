/*import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'*/

import { get as _get } from 'lodash-es'

import {
  faMoneyCheck,
  faBuildingColumns,
  faEuroSign,
  faCreditCard,
  faCoins,
  faArrowPointer,
} from '@fortawesome/pro-light-svg-icons'

import {
  useDocumentDeductions,
  useDocumentDownPayments,
  useDocumentOptions,
  useDocumentTotal,
} from '@/composables/document'
import {
  AMOUNT_TYPE_PERCENT,
  AMOUNT_TYPE_TOTAL,
  AMOUNT_TYPE_SUBTOTAL,
  DOCUMENT,
  DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE,
  DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL,
  DOCUMENT_OPTION_DISPLAY_NUMBERING,
  DOCUMENT_OPTION_DISPLAY_REFERENCE,
  DOCUMENT_OPTION_MERGE_QUANTITY_UNITS,
  DOCUMENT_STATUS_CANCELLED,
  DOCUMENT_SUBTYPE_FINAL,
  QUOTE,
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_CHECK,
  PAYMENT_METHOD_WIRE,
  LINE_TYPE_TEXT,
  DOCUMENT_SUBTYPE_SITUATION,
  DOCUMENT_SUBTYPE_DOWN_PAYMENT,
  PAYMENT_METHOD_PAYBYLINK,
  DOCUMENT_OPTION_HIDE_WORK_DETAILS,
  DOWN_PAYMENT_TIME_CUSTOM,
  CREDIT,
  DISCOUNT_TYPE_AMOUNT,
  DISCOUNT_TYPE_PERCENT,
  INVOICE,
  DOCUMENT_OPTION_DISPLAY_TAX_DETAILS,
  DOCUMENT_OPTION_DISPLAY_SECTION_SUMMARY,
  DOCUMENT_OPTION_DISPLAY_SITUATION_SUMMARY,
  DOCUMENT_OPTION_HIDE_SITUATION_UNIT,
  DOCUMENT_OPTION_DISPLAY_WORK_PRICES,
  DOCUMENT_OPTION_DISPLAY_WORK_QTY,
  DOCUMENT_OPTION_HIDE_SITUATION_EMPTY_LINES,
  LINE_TYPE_GROUP,
  LINE_TYPE_PRODUCT,
  LINE_TYPE_PAGE_BREAK,
  LINE_TYPE_LINE_BREAK,
  LINE_TYPE_SUBTOTAL,
  LINE_TYPE_DOWN_PAYMENT,
  DOCUMENT_OPTION_HIDE_PRICES,
  ORDER_FORM,
  LINE_TYPE_SITUATION,
  PAYMENT_METHOD_LOAN,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_TYPES,
  DEDUCTION_TYPE_HOLDBACK,
  DOCUMENT_OPTION_DISPLAY_SITUATION_PRICE,
  DOCUMENT_OPTION_ALWAYS_DISPLAY_TAX,
  WORK_ORDER,
} from '@/constants/documents'

import { makeStoreReadonlyProperties } from '@/utils/reactivity'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { PRODUCT_TYPE_WORK, PRODUCT_TYPE_WORK_DETAILED, PRODUCT_TYPES_WORKS } from '@/constants/products'
import { useUser } from '@/composables/user'
import { formatTax, formatTaxAmount, formatNumber, getCompanyTax } from '@/utils/documents'
import { computed, useContext } from '@nuxtjs/composition-api'
import { chunk_split, nl2br } from '@/utils/preview'

import { fr } from 'date-fns/locale'
import { format } from 'date-fns'
import { lighten, opacify, toRgba, transparentize } from 'khroma'

const { useGetters, useActions, useState } = createNamespacedHelpers('documents')
const { useGetters: useTemplateGetters } = createNamespacedHelpers('templates')

export function useDom() {
  const targetHasClass = ($target, className) =>
    $target.classList.contains(className) || $target.closest('.' + className)

  return { targetHasClass }
}

export function useDocumentDom() {
  const { app, $format } = useContext()
  const { company, can } = useUser()
  const downPayments = useDocumentDownPayments()
  const downPaymentsValues = computed(() => downPayments.downPaymentsValues(total.value))
  const totalDeductions = useDocumentDeductions()
  const totalDeductionsValues = computed(() => totalDeductions.totalDeductionsValues(total.value))
  const subtotalDeductionsValues = computed(() => totalDeductions.subtotalDeductionsValues(total.value))

  const { tree, number, lines } = useState(['tree', 'number', 'lines'])
  const hasLines = !!Object.keys(tree.value).length

  const document = makeStoreReadonlyProperties('documents', [
    'id',
    'final',
    'object',
    'objectType',
    'subtype',
    'subNumber',
    'issuedAt',
    'quote',
    'customer',
    'detailed',
    'deductedDocuments',
    'totalDeductions',
    'subtotalDeductions',
    'downPaymentsDeduction',
    'invoicesSummary',
    'amountOff',
    'percentOff',
    'holdback',
    'remainingSubtotal',
    'remainingTotal',
    'holdbackReleasedAt',
    'discountType',
    'paymentMethods',
    'paymentConditions',
    'downPaymentsProrated',
    'bankAccount',
    'grossSubtotal',
    'footer',
    'vatNotice',
    'taxable',
    'reverseCharge',
    'roundingMethod',
  ])

  const {
    getLine,
    getLineTotal,
    getGroupTotal,
    getChildrenRecursive,
    getChildTree,
    getInvoicesSummary,
    hasDiscountedLines,
    hasOptionalLines,
    getMaxDepth,
    isSituationComplete,
  } = useGetters([
    'getLine',
    'getLineTotal',
    'getGroupTotal',
    'getChildrenRecursive',
    'getChildTree',
    'getInvoicesSummary',
    'hasDiscountedLines',
    'hasOptionalLines',
    'getMaxDepth',
    'isSituationComplete',
  ])

  const {
    getTemplate: template,
    getOption: getTemplateOption,
    getTerms,
  } = useTemplateGetters(['getTemplate', 'getOption', 'getTerms'])

  const { hasOption } = useDocumentOptions()
  const total = useDocumentTotal()

  const maxDepth = computed(() => getMaxDepth.value)

  const SHOULD_DISPLAY_TAX = computed(
    () => hasOption(DOCUMENT, DOCUMENT_OPTION_ALWAYS_DISPLAY_TAX) || Object.keys(total.value.taxAmounts).length > 1
  )

  const SHOULD_DISPLAY_NUMBERING = computed(
    () =>
      hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_NUMBERING) &&
      (![DOCUMENT_SUBTYPE_DOWN_PAYMENT, DOCUMENT_SUBTYPE_SITUATION].includes(document.subtype.value) ||
        (document.subtype.value === DOCUMENT_SUBTYPE_SITUATION && document.detailed.value))
  )

  const IS_DETAILED_SITUATION =
    document.subtype.value === DOCUMENT_SUBTYPE_SITUATION && document.detailed.value === true

  const IS_ORDER_FORM = document.object.value === ORDER_FORM

  const HIDE_PRICES = computed(() => hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_PRICES) && IS_ORDER_FORM)

  const makeDocumentDom = () => {
    const fragment = window.document.createDocumentFragment()

    let index = 1
    let index0 = 0

    // Main table
    if (hasLines) {
      for (const key of tree.value) {
        let contrastClass =
          maxDepth.value === 1 && getTemplateOption.value('row_contrast') === true && index0 % 2 === 0
            ? 'bg-gray-100'
            : ''

        let { content } = makeLineDom(key, { contrastClass, index, index0, totalLines: tree.value.length })

        if (content !== null) {
          fragment.appendChild(content)

          const line = getLine.value[key]
          if (!['text', 'page_break', 'line_break'].includes(line.type)) {
            index++
          }

          index0++
        }
      }
    } else {
      const emptyTable = window.document.createElement('table')
      emptyTable.classList.add('document-lines', `table-${template.tableStyle}`)

      emptyTable.innerHTML = `<tbody>
        <tr>
          <td colspan="99" class="text-center">
            <div class="p-2">${app.i18n.t('documents.text.empty')}</div>
          </td>
        </tr>
      </tbody>`

      fragment.appendChild(emptyTable)
    }

    const optionsContainer = window.document.createElement('div')

    if (hasOptionalLines.value > 0) {
      optionsContainer.innerHTML = `<div class="${
        template.value.fontSize === 'normal' ? 'text-[10px]' : 'text-[12px]'
      } text-right text-gray-600 italic pt-1">${app.i18n.tc(
        'documents.text.optionsNotIncluded',
        hasOptionalLines.value
      )}</div>`

      fragment.appendChild(optionsContainer)
    }

    const summary = makeSectionsSummaryDom()
    if (summary !== null) fragment.appendChild(summary)

    const bottom = makeBottomDom()
    fragment.appendChild(bottom)

    if (hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_SITUATION_SUMMARY) && IS_DETAILED_SITUATION) {
      fragment.appendChild(makeInvoicesSummaryDom())
    }

    /*const terms = makeTermsDom()
    fragment.appendChild(terms)*/

    if (document.objectType.value === WORK_ORDER) {
      const signatureBlock = makeWorkOrderSignatureDom()
      fragment.appendChild(signatureBlock)
    } else if (hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE) && document.object.value === QUOTE) {
      const signatureBlock = makeCompanySignatureDom()
      fragment.appendChild(signatureBlock)
    }

    return fragment
  }

  const makeGroup = (key, line, props) => {
    const table = window.document.createElement('table')
    table.classList.add('document-lines', 'table-group-container', 'w-full', template.tableStyle)

    const recursiveChildren = getChildrenRecursive.value(key)

    let optionableChildren = [].concat(recursiveChildren).reduce((arr, key) => {
      let line = lines.value.find((el) => el.key === key)
      if (line && [LINE_TYPE_PRODUCT].includes(line.type)) {
        arr.push(line.optional)
      }
      return arr
    }, [])

    let children = []

    let visibleChildren = 0
    let visibleTextChildren = 0

    let childIndex = 1
    let childIndex0 = 0

    function getChildrenVisibility(group, makeContent) {
      let { content, meta } = makeLineDom(group, {
        contrastClass:
          /*maxDepth.value === 1 && getTemplateOption.value('row_contrast') === true && childIndex0 % 2 === 0
          ? 'bg-gray-100'
          : ''*/ '',
        totalLines: getChildTree.value[key].length,
        index: childIndex,
        index0: childIndex0,
        isOptional: line.optional || props.groupIsOptional,
        groupIsOptional: line.optional || props.groupIsOptional,
        parent: key,
        parentIndex: props.parentIndex ? `${props.parentIndex}.${props.index}` : props.index,
      })

      const childLine = getLine.value[group]
      if (childLine) {
        if (childLine.type === LINE_TYPE_TEXT) {
          visibleTextChildren++
        }

        if (content !== null) {
          if (makeContent) {
            children.push(content.innerHTML)
            childIndex0++
          }
        }

        if (_get(meta, 'quantity', 0) !== 0) {
          visibleChildren++
        }

        if (makeContent) {
          if (meta.type !== 'text') {
            childIndex++
          }
        }

        if (getChildTree.value[group]) {
          for (let child of getChildTree.value[group]) {
            getChildrenVisibility(child, false)
          }
        }
      }
    }

    if (getChildTree.value[key]) {
      for (let child of getChildTree.value[key]) {
        getChildrenVisibility(child, true)
      }
    }

    const groupTotal = computed(() => getGroupTotal.value({ key, groupIsOptional: props.groupIsOptional }))

    if (
      !IS_DETAILED_SITUATION ||
      hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_EMPTY_LINES) === false ||
      (visibleChildren > 0 && (visibleTextChildren === 0 || visibleChildren !== visibleTextChildren))
      // Don't show group if there's only text lines to show on situation invoices
    ) {
      const compositeIndex = props.parentIndex ? `${props.parentIndex}.${props.index}` : props.index
      const indexLength = compositeIndex.toString().split('.').length

      let classLevel = 4 + indexLength - maxDepth.value

      let mergeQuantityUnit = IS_DETAILED_SITUATION || hasOption(DOCUMENT, DOCUMENT_OPTION_MERGE_QUANTITY_UNITS)

      let content = ''

      if (!IS_DETAILED_SITUATION) {
        content +=
          hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_REFERENCE) &&
          document.objectType.value !== CREDIT &&
          ![DOCUMENT_SUBTYPE_DOWN_PAYMENT, DOCUMENT_SUBTYPE_SITUATION].includes(document.subtype.value)
            ? `<td class="${makeTdClass('col-reference')}">${line.reference ? line.reference : ''}</td>`
            : ''

        content += `<td class="${makeTdClass(mergeQuantityUnit ? 'col-quantity-unit' : 'col-quantity')}"></td>`

        // Unit
        if (!mergeQuantityUnit) {
          content += `<td class="${makeTdClass('col-unit')}"></td>`
        }

        if (!HIDE_PRICES.value) {
          content += `<td class="${makeTdClass('col-sell-price')}" ></td>`
        }
      }

      let borderClass = getTemplateOption.value('row_border_h') === true ? 'border-b border-gray-200' : ''

      if (getTemplateOption.value('row_contrast')) {
        table.innerHTML = []
          .concat(
            `<tr  class="relative group-head-element thead group font-medium color-level-${
              indexLength <= 4 ? indexLength : 4
            } level-${indexLength <= 4 ? indexLength : 4} ">

                `,
            hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_NUMBERING)
              ? `<td colspan="1">
                  <div>${compositeIndex}</div>
                </td>`
              : '',
            `<td colspan="50">

            <div class="flex">
            ${
              line.optional && !props.groupIsOptional
                ? `<div class="option-container flex items-center mr-1">
                <span class="inline-flex items-center text-[11px] bg-color text-white rounded-full px-1.5 py-[3px] leading-none mr-1">Option</span>
            </div>`
                : ''
            }
            <div><div style="white-space: pre-wrap">${line.description}</div></div>
                ${
                  !HIDE_PRICES.value &&
                  getTemplateOption.value('wrap_subtotal') &&
                  hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL)
                    ? `<div class="ml-auto pl-2 translate-x-px shrink-0 whitespace-nowrap">

                    <span class="${line.optional || props.groupIsOptional ? 'italic' : ''}">${
                        line.optional || props.groupIsOptional ? '*' : ''
                      } ${$format.currency(groupTotal.value ? groupTotal.value : 0)}</span>
                  </div>`
                    : ''
                }
            </div>
                </td>
              </tr>
          `,

            '</tr>',
            children,
            !HIDE_PRICES.value &&
              getTemplateOption.value('wrap_subtotal') === false &&
              hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL)
              ? `<tr class="group-subtotal color-level-${indexLength <= 4 ? indexLength : 4}">
              <td colspan="50" class="relative text-right !px-0 !py-1">

                <div class="flex">

                  <div class="ml-auto px-2 py-1 font-normal total-background ${
                    getTemplateOption.value('rounded_borders') ? 'rounded-b-lg' : ''
                  }">
                    <span class="block translate-x-px">
                      ${line.description.length > 100 ? 'Sous-total' : line.description} :
                      <span class="font-semibold ${line.optional ? 'italic' : ''}">${
                  line.optional ? '*' : ''
                } ${$format.currency(groupTotal.value ? groupTotal.value : 0)}</span>
                    </span>
                  </div>
                </div>
              </td>
            </tr>`
              : ''
            //makeSpacerRow()
          )
          .join('')
      } else {
        table.innerHTML = []
          .concat(
            `<tr class="group-head-element thead group font-medium level-${indexLength <= 4 ? indexLength : 4} ">`,
            hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_NUMBERING)
              ? `<td class="${makeTdClass('col-num')} border-r border-gray-100">
                  <div class="font-semibold">${compositeIndex}</div>
                </td>`
              : '',
            `<td class="${makeTdClass('col-label')}">
                <div class="flex w-full">
                ${
                  line.optional
                    ? `<div class="option-container flex items-center mr-1">
                <span class="inline-flex items-center text-[11px] bg-color text-white rounded-full px-1.5 py-[3px] leading-none mr-1">Option</span>
            </div>`
                    : ''
                }
                     <span class="whitespace-pre-wrap ${
                       can('plan', 'products.rich_text') && false ? 'text-xs prose prose-docs' : 'font-semibold'
                     } ">${line.description}</span>
              </div>
            </td>`,
            content,
            IS_DETAILED_SITUATION
              ? `
          <td class="${makeTdClass('col-quoted')}"></td>
          ${document.subNumber.value > 1 ? `<td class="${makeTdClass('col-already_invoiced')}"></td>` : ''}
          <td class="${makeTdClass('col-progression')}"></td>
          <td class="${makeTdClass('col-accumulation')}"></td>
          `
              : '',
            SHOULD_DISPLAY_TAX.value && document.taxable.value && !document.reverseCharge.value && !HIDE_PRICES.value
              ? `<td class="${makeTdClass('col-vat')}"></td>`
              : '',
            hasDiscountedLines.value && !HIDE_PRICES.value ? `<td class="${makeTdClass('col-discount')}"></td>` : '',
            `<td class="${makeTdClass('col-total', true)}">
            ${
              hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL) &&
              !HIDE_PRICES.value &&
              getTemplateOption.value('wrap_subtotal')
                ? `<div class="ml-auto translate-x-px shrink-0 whitespace-nowrap">
                    <span class="font-semibold ${line.optional ? 'italic' : ''}">${
                    line.optional ? '*' : ''
                  } ${$format.currency(groupTotal.value ? groupTotal.value : 0)}</span>
                  </div>`
                : ''
            }
          </td>`,
            '</tr>',
            children,
            !HIDE_PRICES.value &&
              getTemplateOption.value('wrap_subtotal') === false &&
              hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_GROUP_SUBTOTAL)
              ? `<tr class="group-subtotal">
              <td colspan="50" class="text-right !px-0 !py-1">
                <div class="flex">
                  <div class="ml-auto px-2 py-1 font-normal ${
                    getTemplateOption.value('rounded_borders') ? 'rounded-b-lg' : ''
                  }" style="background: #e2e8f0">
                    <span class="block translate-x-px">
                      ${line.description.length > 100 ? 'Sous-total' : line.description} :
                      <span class="font-semibold ${line.optional ? 'italic' : ''}">${
                  line.optional ? '* ' : ''
                }${$format.currency(groupTotal.value ? groupTotal.value : 0)}</span>
                    </span>
                  </div>
                </div>
              </td>
            </tr>`
              : ''
            //makeSpacerRow()
          )
          .join('')
      }
    }

    return table
  }

  const makeRowImagesHTML = (line, classes) => {
    const container = window.document.createElement('div')
    container.classList.add('flex', ...classes)

    let imageClass = 'h-[120px]'
    if (line.images.length === 2) {
      imageClass = 'h-[90px]'
    }

    switch (line.imagesSize) {
      case 'sm':
        imageClass = 'h-[90px]'
        if (line.images.length === 2) {
          imageClass = 'h-[70px]'
        }
        break
      case 'md':
        imageClass = 'h-[120px]'
        if (line.images.length === 2) {
          imageClass = 'h-[90px]'
        }
        break
      case 'lg':
        imageClass = 'h-[180px]'
        if (line.images.length === 2) {
          imageClass = 'h-[140px]'
        }
        break
    }

    container.innerHTML = line.images
      .map(
        (image) => `<div
      class="group flex items-center mr-2 mb-2 ${imageClass}"
    >
      <img src="${image.url}" class="line-image object-scale-down pointer-events-none h-full" />
    </div>`
      )
      .join('')

    return container.outerHTML
  }

  const makePageBreak = (key, line, props) => {
    const container = window.document.createElement('table')
    const row = window.document.createElement('tr')
    row.classList.add('page-break')
    container.appendChild(row)
    return container
  }

  const makeLineBreak = (key, line, props) => {
    const container = window.document.createElement('table')
    const row = window.document.createElement('tr')
    row.classList.add('h-[26px]')
    container.appendChild(row)
    return container
  }

  const makeSubtotalRow = (key, line, props) => {
    const parentTree = props.parent ? getChildTree.value[props.parent] : tree.value

    const lineTotal = computed(() => getLineTotal.value[line.key])
    const table = window.document.createElement('table')

    table.innerHTML = `<tr class="group-subtotal">
      <td colspan="50" class="text-right !p-0">
        <div class="flex" >
          <div class="ml-auto p-2 font-normal ${getTemplateOption.value('rounded_borders') ? 'rounded-b-lg' : ''}">
            <span class="block translate-x-px">
              Sous-total :
              <span class="font-semibold">${$format.currency(lineTotal.value ? lineTotal.value.subtotal : 0)}</span>
            </span>
          </div>
        </div>
      </td>
    </tr>`

    return table
  }

  const makeTextRow = (key, line, props) => {
    const container = window.document.createElement('table')

    const row = window.document.createElement('tr')
    row.classList.add('content-row', 'product-row')

    if (props.contrastClass) {
      row.classList.add(...props.contrastClass.split(' ').filter(Boolean))
    }

    if (getTemplateOption.value('row_border_h') === true && props.index0 < props.totalLines - 1) {
      row.classList.add('border-b', 'border-gray-200')
    }

    let mergeQuantityUnit = IS_DETAILED_SITUATION || hasOption(DOCUMENT, DOCUMENT_OPTION_MERGE_QUANTITY_UNITS)

    let content = ''

    if (!IS_DETAILED_SITUATION) {
      content +=
        hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_REFERENCE) &&
        document.objectType.value !== CREDIT &&
        ![DOCUMENT_SUBTYPE_DOWN_PAYMENT, DOCUMENT_SUBTYPE_SITUATION].includes(document.subtype.value)
          ? `<td class="${makeTdClass('col-reference')}">${line.reference ? line.reference : ''}</td>`
          : ''

      content += `<td class="${makeTdClass(mergeQuantityUnit ? 'col-quantity-unit' : 'col-quantity')}"></td>`

      // Unit
      if (!mergeQuantityUnit) {
        content += `<td class="${makeTdClass('col-unit')}"></td>`
      }

      if (!HIDE_PRICES.value) {
        content += `<td class="${makeTdClass('col-sell-price')}" ></td>`
      }
    }

    let numbering = SHOULD_DISPLAY_NUMBERING.value
      ? `<td class="${makeTdClass('col-num')}">
              ${line.type !== 'text' ? `${props.parentIndex ? `${props.parentIndex}.` : ''}${props.index || ''}` : ''}
                </td>`
      : ''

    row.innerHTML = []
      .concat(
        numbering,
        `<td class="${makeTdClass('col-label')}">
 ${
   line.imagesPosition === 'top' && line.images.length > 0 ? makeRowImagesHTML(line, ['mb-3', 'mt-1', 'flex-wrap']) : ''
 }
                <div class="flex w-full">
                 ${
                   line.imagesPosition === 'left' && line.images.length > 0
                     ? makeRowImagesHTML(line, ['shrink-0', 'max-w-[35%]'])
                     : ''
                 }
                  <div class="w-full">
                    <div class="prose-docs">${line.description}</div>
                  </div>
              </div>
               ${
                 line.imagesPosition === 'bottom' && line.images.length > 0
                   ? makeRowImagesHTML(line, ['mt-3', 'mb-1', 'flex-wrap'])
                   : ''
               }
            </td>`,
        content,
        IS_DETAILED_SITUATION
          ? `
          <td class="${makeTdClass('col-quoted')}"></td>
          ${
            hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
              ? `<td class="${makeTdClass('col-situation-quantity')}"></td>`
              : ''
          }
          ${document.subNumber.value > 1 ? `<td class="${makeTdClass('col-already_invoiced')}"></td>` : ''}
          <td class="${makeTdClass('col-progression')}"></td>
          <td class="${makeTdClass('col-accumulation')}"></td>
          `
          : '',
        SHOULD_DISPLAY_TAX.value &&
          document.taxable.value &&
          line.tax &&
          !document.reverseCharge.value &&
          !HIDE_PRICES.value
          ? `<td class="${makeTdClass('col-vat')}"></td>`
          : '',
        hasDiscountedLines.value && !HIDE_PRICES.value ? `<td class="${makeTdClass('col-discount')}"></td>` : '',
        !HIDE_PRICES.value ? `<td class="${makeTdClass('col-total', true)}"></td>` : ''
      )
      .join('')

    container.appendChild(row)
    return container
  }

  const makeSpacerRow = () => {
    const container = window.document.createElement('table')

    const row = window.document.createElement('tr')
    row.classList.add('spacer')

    let mergeQuantityUnit = IS_DETAILED_SITUATION || hasOption(DOCUMENT, DOCUMENT_OPTION_MERGE_QUANTITY_UNITS)

    let content = ''

    if (!IS_DETAILED_SITUATION) {
      content +=
        hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_REFERENCE) &&
        document.objectType.value !== CREDIT &&
        ![DOCUMENT_SUBTYPE_DOWN_PAYMENT, DOCUMENT_SUBTYPE_SITUATION].includes(document.subtype.value)
          ? `<td class="${makeTdClass('col-reference')}"></td>`
          : ''

      content += `<td class="${makeTdClass(mergeQuantityUnit ? 'col-quantity-unit' : 'col-quantity')}"></td>`

      // Unit
      if (!mergeQuantityUnit) {
        content += `<td class="${makeTdClass('col-unit')}"></td>`
      }

      if (!HIDE_PRICES.value) {
        content += `<td class="${makeTdClass('col-sell-price')}"></td>`
      }
    }

    let numbering = SHOULD_DISPLAY_NUMBERING.value ? `<td class="${makeTdClass('col-num')}"></td>` : ''

    row.innerHTML = []
      .concat(
        numbering,
        `<td class="${makeTdClass('col-label')}"></td>`,
        content,
        IS_DETAILED_SITUATION
          ? `
          <td class="${makeTdClass('col-quoted')}"></td>
           ${document.subNumber.value > 1 ? `<td class="${makeTdClass('col-already_invoiced')}"></td>` : ''}
          <td class="${makeTdClass('col-progression')}"></td>
          <td class="${makeTdClass('col-accumulation')}"></td>
          `
          : '',
        SHOULD_DISPLAY_TAX.value && document.taxable.value && !document.reverseCharge.value
          ? `<td class="${makeTdClass('col-vat')}"></td>`
          : '',
        hasDiscountedLines.value ? `<td class="${makeTdClass('col-discount')}"></td>` : '',
        !HIDE_PRICES.value ? `<td class="${makeTdClass('col-total', true)}"></td>` : ''
      )
      .join('')

    container.appendChild(row)
    return container.innerHTML
  }

  const makeSituationSuppliesDom = (line, contrastClass, borderClass) => {
    if (line.productType !== PRODUCT_TYPE_WORK_DETAILED || hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_WORK_DETAILS)) {
      return null
    }

    const mergeQuantityUnit = IS_DETAILED_SITUATION || hasOption(DOCUMENT, DOCUMENT_OPTION_MERGE_QUANTITY_UNITS)

    const spacer = window.document.createElement('tr')

    let numbering = SHOULD_DISPLAY_NUMBERING.value ? `<td class="${borderClass} ${makeTdClass('col-num')}"></td>` : ''

    spacer.classList.add('supply-row')
    spacer.classList.add(...contrastClass.split(' ').filter(Boolean))

    spacer.innerHTML = `${numbering}
      <td class="!pl-3 ${borderClass} ${makeTdClass('col-description')}"></td>
       ${
         hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
           ? `<td class="${borderClass} ${makeTdClass('col-quoted')}"></td><td class="${makeTdClass(
               'col-situation-quantity'
             )}"></td>`
           : `<td class="${borderClass} ${makeTdClass('col-quoted')}"></td>`
       }

      ${document.subNumber.value > 1 ? `<td class="${borderClass} ${makeTdClass('col-already_invoiced')}"></td>` : ''}
      <td class="${borderClass} ${makeTdClass('col-progression')}"></td>
      <td class="${borderClass} ${makeTdClass('col-accumulation')}"></td>
      ${
        SHOULD_DISPLAY_TAX.value && document.taxable.value && line.tax && !document.reverseCharge.value
          ? `<td class="${borderClass} ${makeTdClass('col-vat')}"></td>`
          : ''
      }
      ${hasDiscountedLines.value ? `<td class="${borderClass} ${makeTdClass('col-discount')} text-right"></td>` : ''}

      <td class="${borderClass} ${makeTdClass('col-total', true)}"></td>
      `

    let supplies = []

    for (const [index, supply] of line.supplies
      .filter((el) => (el.element && el.element.name !== '') || (el.name && el.name !== ''))
      .entries()) {
      let progression = '&ndash;'
      if (supply.quantity * line.quantity !== 0) {
        progression = `${$format.number(supply.quantity * line.quantity, 5)} ${
          supply.element && supply.element.unit ? supply.element.unit.symbol : ''
        }`
      }

      let accumulation = '&ndash;'
      if (supply.quantity * line.invoicedQuantity + line.quantity * supply.quantity !== 0) {
        accumulation = `${$format.number(
          supply.quantity * line.invoicedQuantity + line.quantity * supply.quantity,
          7
        )} ${supply.element && supply.element.unit ? supply.element.unit.symbol : ''}`
      }

      let numbering = SHOULD_DISPLAY_NUMBERING.value ? `<td class="${makeTdClass('col-num')}"></td>` : ''

      const row = window.document.createElement('tr')
      row.classList.add('supply-row')
      row.classList.add(...contrastClass.split(' ').filter(Boolean))

      // Quantity

      row.innerHTML = `
          ${numbering}
          <td class="!pl-2 ${makeTdClass('col-description')}"><div class="flex">&ndash; <div class="ml-1">${_get(
        supply,
        'element.name'
      )}</div></div></td>

    ${
      hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
        ? `<td class="${makeTdClass('col-quoted')}"></td><td class="${makeTdClass('col-situation-quantity')}">
    ${
      hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_QTY)
        ? `${$format.number(supply.quantity, 5)} ${
            supply.element && supply.element.unit ? supply.element.unit.symbol : ''
          }`
        : ``
    }


</td>`
        : `<td class="${makeTdClass('col-quoted')}">${$format.number(supply.quantity, 5)} ${
            supply.element && supply.element.unit ? supply.element.unit.symbol : ''
          }</td>`
    }




      ${
        document.subNumber.value > 1
          ? `<td class="${makeTdClass('col-already_invoiced')}">
${
  hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_QTY) && !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
    ? `${$format.number(supply.quantity * line.invoicedQuantity, 5)} ${
        supply.element && supply.element.unit ? supply.element.unit.symbol : ''
      }`
    : ''
}
</td>`
          : ''
      }

      <td class="${makeTdClass('col-progression')}">
      ${
        hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_QTY) &&
        !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
          ? progression
          : ''
      }
</td>
      <td class="${makeTdClass('col-accumulation')}">
      ${
        hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_QTY) &&
        !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
          ? accumulation
          : ''
      }

</td>


            ${hasDiscountedLines.value ? `<td class="${makeTdClass('col-discount')}"></td>` : ''}
            ${SHOULD_DISPLAY_TAX.value ? `<td class="${makeTdClass('col-tax')}"></td>` : ''}
          <td class="${makeTdClass('col-total', true)}">${
        hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_PRICES)
          ? $format.currency(_get(supply, 'element.sellPrice', 0) * supply.quantity * line.quantity)
          : ''
      }</td>
        `

      supplies.push(row)
    }

    return [].concat(supplies, [spacer])
  }

  const makeSuppliesDom = (line, contrastClass, borderClass) => {
    if (line.productType !== PRODUCT_TYPE_WORK_DETAILED || hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_WORK_DETAILS)) {
      return null
    }

    const mergeQuantityUnit = IS_DETAILED_SITUATION || hasOption(DOCUMENT, DOCUMENT_OPTION_MERGE_QUANTITY_UNITS)

    const spacer = window.document.createElement('tr')

    let numbering = SHOULD_DISPLAY_NUMBERING.value ? `<td class="${makeTdClass('col-num')}"></td>` : ''
    let quantityUnit = `<td class="${borderClass} ${makeTdClass(
      mergeQuantityUnit ? 'col-quantity-unit' : 'col-quantity'
    )}"></td>`
    if (!mergeQuantityUnit) {
      quantityUnit = `<td class="${borderClass} ${makeTdClass('col-unit')}"> </td>`
    }

    spacer.classList.add('supply-row')
    spacer.classList.add(...contrastClass.split(' ').filter(Boolean))

    spacer.innerHTML = `${numbering}
      <td class="!pl-3 ${borderClass} ${makeTdClass('col-description')}"></td>
      ${
        hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_REFERENCE) &&
        document.objectType.value !== CREDIT &&
        ![DOCUMENT_SUBTYPE_DOWN_PAYMENT, DOCUMENT_SUBTYPE_SITUATION].includes(document.subtype.value)
          ? `<td class="${borderClass} ${makeTdClass('col-reference')}"></td>`
          : ''
      }

      ${mergeQuantityUnit ? '' : `<td class="${borderClass} ${makeTdClass('col-quantity')}"></td>`}
      ${quantityUnit}
      ${
        SHOULD_DISPLAY_TAX.value && document.taxable.value && line.tax && !document.reverseCharge.value
          ? `<td class="${borderClass} ${makeTdClass('col-vat')}"></td>`
          : ''
      }
      ${hasDiscountedLines.value ? `<td class="${borderClass} ${makeTdClass('col-discount')} text-right"></td>` : ''}

       ${
         HIDE_PRICES.value
           ? ``
           : `<td class="${borderClass} ${makeTdClass('col-sell-price')}"></td>
      <td class="${borderClass} ${makeTdClass('col-total', true)}"></td>`
       }


      `

    let supplies = []

    for (const [index, supply] of line.supplies
      .filter((el) => (el.element && el.element.name !== '') || (el.name && el.name !== ''))
      .entries()) {
      let numbering = SHOULD_DISPLAY_NUMBERING.value ? `<td class="${makeTdClass('col-num')}"></td>` : ''

      const row = window.document.createElement('tr')
      row.classList.add('supply-row')
      row.classList.add(...contrastClass.split(' ').filter(Boolean))

      // Quantity
      let quantityUnit = `<td class="${makeTdClass(mergeQuantityUnit ? 'col-quantity-unit' : 'col-quantity')}">
              ${$format.number(supply.quantity * line.quantity, 5)} ${
        mergeQuantityUnit && supply.element && supply.element.unit ? supply.element.unit.symbol : ''
      }
          </td>`

      // Unit
      if (!mergeQuantityUnit) {
        quantityUnit = `<td class="${makeTdClass('col-unit')}">
              ${supply.element && supply.element.unit ? `<span>${supply.element.unit.symbol}</span>` : ''}
            </td>`
      }

      if (!hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_QTY)) {
        quantityUnit = `<td class="${makeTdClass(mergeQuantityUnit ? 'col-quantity-unit' : 'col-quantity')}"></td>`
      }

      row.innerHTML = `
          ${numbering}
          <td class="!pl-2 ${makeTdClass('col-description')}"><div class="flex">&ndash; <div class="ml-1">${_get(
        supply,
        'element.name'
      )}</div></div></td>
          ${
            hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_REFERENCE) &&
            document.objectType.value !== CREDIT &&
            ![DOCUMENT_SUBTYPE_DOWN_PAYMENT, DOCUMENT_SUBTYPE_SITUATION].includes(document.subtype.value)
              ? `<td class="${makeTdClass('col-reference')}">${_get(supply, 'element.reference', '')}</td>`
              : ''
          }
          ${
            mergeQuantityUnit
              ? ''
              : `<td class="${makeTdClass('col-quantity')}">${
                  hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_QTY)
                    ? $format.number(supply.quantity * line.quantity, 5)
                    : ''
                }</td>`
          }
          ${quantityUnit}


        ${
          HIDE_PRICES.value
            ? ``
            : `<td class="${makeTdClass('col-sell-price')}">${
                hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_PRICES)
                  ? $format.currency(_get(supply, 'element.sellPrice', 0))
                  : ''
              }</td>`
        }


            ${
              SHOULD_DISPLAY_TAX.value && document.taxable.value && !document.reverseCharge.value
                ? `<td class="${makeTdClass('col-vat')}"></td>`
                : ''
            }
            ${hasDiscountedLines.value ? `<td class="${makeTdClass('col-discount')} text-right"></td>` : ''}



           ${
             HIDE_PRICES.value
               ? ``
               : `<td class="${makeTdClass('col-total', true)}">${
                   hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_PRICES)
                     ? $format.currency(_get(supply, 'element.sellPrice', 0) * supply.quantity * line.quantity)
                     : ''
                 }</td>`
           }

        `

      supplies.push(row)
    }

    return [].concat(supplies, [spacer])
  }

  const makeOptional = (description, isOptional) => {
    if (isOptional) {
      if (description) {
        if (!description.startsWith('<div>')) description = `<div>${description}</div>`
        return description.replace(
          '<div>',
          '<div><span class="bg-color text-white rounded-full px-1.5 py-[3px] leading-none mr-1 translate-y-px">Option</span>'
        )
      } else {
        return '<div><span class="bg-color text-white rounded-full px-1.5 py-[3px] leading-none mr-1 translate-y-px">Option</span></div>'
      }
    }
    return description
  }

  const lineTotal = computed(() => getLineTotal.value)

  const makeProductRow = (key, line, props) => {
    if (
      IS_DETAILED_SITUATION &&
      hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_EMPTY_LINES) &&
      line.quantity === 0
    ) {
      return null
    }

    const container = window.document.createElement('table')
    const row = window.document.createElement('tr')
    row.classList.add(
      'content-row',
      'product-row',
      !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_WORK_DETAILS) && getTemplateOption.value('row_border_h') === true
        ? 'border-b'
        : null
    )

    let numbering = SHOULD_DISPLAY_NUMBERING.value
      ? `<td class="${makeTdClass('col-num')}">
              ${line.type !== 'text' ? `${props.parentIndex ? `${props.parentIndex}.` : ''}${props.index || ''}` : '-'}
                </td>`
      : ''

    let simpleSupplies =
      line.productType === PRODUCT_TYPE_WORK && !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_WORK_DETAILS)
        ? `<ul class="list-none pl-2 mt-1">
                    ${line.supplies
                      .filter((el) => el.name)
                      .map((el) => `<li class="mb-0.5">&ndash; ${el.name}</li>`)
                      .join('')}
                  </ul>`
        : ''

    let standardDocumentData = ''
    let standardDocumentDataPlaceholder = ''
    let mergeQuantityUnit = IS_DETAILED_SITUATION || hasOption(DOCUMENT, DOCUMENT_OPTION_MERGE_QUANTITY_UNITS)

    // Quantity
    standardDocumentData += `<td class="${makeTdClass(mergeQuantityUnit ? 'col-quantity-unit' : 'col-quantity')}">
            ${$format.number(line.quantity || 0, 5)} ${mergeQuantityUnit && line.unit ? line.unit.symbol : ''}
        </td>`

    standardDocumentDataPlaceholder += `<td class="${makeTdClass(
      mergeQuantityUnit ? 'col-quantity-unit' : 'col-quantity'
    )}"></td>`

    // Unit
    if (!mergeQuantityUnit) {
      standardDocumentData += `<td class="${makeTdClass('col-unit')}">
            ${line.unit ? `<span>${line.unit.symbol}</span>` : ''}
          </td>`

      standardDocumentDataPlaceholder += `<td class="${makeTdClass('col-unit')}"></td>`
    }

    if (!HIDE_PRICES.value) {
      standardDocumentData += `<td class="${makeTdClass('col-sell-price')}" >${$format.currency(line.sellPrice)}</td>`
      standardDocumentDataPlaceholder += `<td class="${makeTdClass('col-sell-price')}" ></td>`
    }

    let discountAmount = '&ndash;'

    let percentOff = Number(line.percentOff)
    let amountOff = Number(line.amountOff)

    if (line.discountType === DISCOUNT_TYPE_PERCENT && percentOff > 0) {
      discountAmount = `${percentOff} %`
    }

    if (line.discountType === DISCOUNT_TYPE_AMOUNT && amountOff > 0) {
      discountAmount = `${$format.currency(amountOff)}`
    }

    if (IS_DETAILED_SITUATION) {
      let supplies =
        PRODUCT_TYPES_WORKS.includes(line.productType) &&
        line.supplies &&
        line.supplies.filter((el) => (el.element && el.element.name !== '') || (el.name && el.name !== '')).length &&
        !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_WORK_DETAILS)
          ? `<ul class="pl-1 mt-1">
              ${line.supplies
                .filter((el) => (el.element && el.element.name !== '') || (el.name && el.name !== ''))
                .map(
                  (el) =>
                    `<li class="flex mb-0.5"><span class="mr-1">&ndash;</span> ${
                      el.element ? el.element.name : el.name
                    }</li>`
                )
                .join('')}
            </ul>`
          : ''

      let progress = '&ndash;'
      let accumulation = '&ndash;'
      let invoiced = '&ndash;'

      const invoicedPercent = line.invoicedPercent / 100

      if (Math.abs(line.invoicedQuantity) || (0 < Math.abs(line.quoteQuantity) && line.quantity !== 0)) {
        progress = `${
          hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_SITUATION_PRICE)
            ? `<div>${$format.currency(line.quantity * line.sellPrice)}</div>`
            : ''
        }
        <div>${$format.number(line.percent || 0, 6)} %</div>
        ${
          !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
            ? `<div>${$format.number(line.quantity || 0, 7)} ${
                line.unit ? `<span>${line.unit.symbol}</span>` : ''
              }</div>`
            : ''
        }`

        accumulation = ` ${
          hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_SITUATION_PRICE)
            ? `<div>${$format.currency((line.invoicedQuantity + line.quantity) * line.sellPrice)}</div>`
            : ''
        }
        <div>${$format.number(line.invoicedPercent + line.percent, 6)} %</div>
        ${
          !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
            ? `<div>${$format.number(line.invoicedQuantity + line.quantity, 7)} ${
                line.unit ? `<span>${line.unit.symbol}</span>` : ''
              }</div>`
            : ''
        }
        `
      }

      if (line.invoicedQuantity !== null && line.invoicedQuantity !== 0) {
        invoiced = `${
          hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_SITUATION_PRICE)
            ? `<div>${$format.currency(line.invoicedQuantity * line.sellPrice)}</div>`
            : ''
        }
        <div>${$format.number(line.invoicedPercent || 0, 6)} %</div>
        ${
          !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
            ? `<div>${$format.number(line.invoicedQuantity || 0, 7)} ${
                line.unit ? `<span>${line.unit.symbol}</span>` : ''
              }</div>`
            : ''
        }`
      }

      row.innerHTML = []
        .concat(
          numbering,
          `<td class="${makeTdClass('col-label')}">
                <div class="flex w-full">
                  <div class="w-full">
                    <div class="prose-docs">
                    ${line.description}</div>
                    ${
                      hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_WORK_DETAILS) ||
                      (!hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_PRICES) &&
                        !hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_QTY))
                        ? supplies
                        : ''
                    }
                  </div>
              </div>
            </td>`,
          `<td class="${makeTdClass('col-quoted')}">
            <div>${$format.currency(line.sellPrice)}</div>
            ${
              !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
                ? `<div>${$format.number(line.quoteQuantity, 4)} ${
                    line.unit ? `<span>${line.unit.symbol}</span>` : ''
                  }</div>`
                : ''
            }
        </td>
        ${
          hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT)
            ? `<td class="${makeTdClass('col-situation-quantity')}">${$format.number(line.quoteQuantity, 4)} ${
                line.unit ? `<span>${line.unit.symbol}</span>` : ''
              }</td>`
            : ''
        }
         ${
           document.subNumber.value > 1
             ? `<td class="${makeTdClass('col-already_invoiced')}">
            ${invoiced}
        </td>`
             : ''
         }

        <td class="${makeTdClass('col-progression')}">
         ${progress}
        </td>
        <td class="${makeTdClass('col-accumulation')}">
          ${accumulation}
        </td>`,
          SHOULD_DISPLAY_TAX.value && document.taxable.value && line.tax && !document.reverseCharge.value
            ? `<td class="${makeTdClass('col-vat')}">${line.type !== LINE_TYPE_TEXT ? formatTax(line.tax) : ''}</td>`
            : '',
          hasDiscountedLines.value
            ? `<td class="${makeTdClass('col-discount')} text-right">${
                line.type !== LINE_TYPE_TEXT ? discountAmount : ''
              }</td>`
            : '',
          `<td class="col-grossSubtotal">${
            line.type !== LINE_TYPE_TEXT ? $format.currency(line.quantity * line.sellPrice) : ''
          }</td>`
        )
        .join('')
    } else {
      row.innerHTML = []
        .concat(
          numbering,
          `<td class="${makeTdClass('col-label')}">
                ${
                  line.imagesPosition === 'top' && line.images.length > 0
                    ? makeRowImagesHTML(line, ['mb-3', 'mt-1', 'flex-wrap'])
                    : ''
                }
                <div class="flex w-full">
                 ${
                   line.imagesPosition === 'left' && line.images.length > 0
                     ? makeRowImagesHTML(line, ['shrink-0', 'max-w-[35%]'])
                     : ''
                 }
                  <div class="w-full">
                    <div class="prose-docs"> ${makeOptional(
                      line.description,
                      document.objectType.value === QUOTE && line.optional && !props.groupIsOptional
                    )}</div>
                    ${simpleSupplies}
                  </div>
              </div>
               ${
                 line.productType !== PRODUCT_TYPE_WORK_DETAILED &&
                 line.imagesPosition === 'bottom' &&
                 line.images.length > 0
                   ? makeRowImagesHTML(line, ['mt-3', 'mb-1', 'flex-wrap'])
                   : ''
               }
            </td>`,
          hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_REFERENCE) &&
            ![DOCUMENT_SUBTYPE_DOWN_PAYMENT, DOCUMENT_SUBTYPE_SITUATION].includes(document.subtype.value)
            ? `<td class="${makeTdClass('col-reference')}">${line.reference ? line.reference : ''}</td>`
            : '',
          standardDocumentData,
          SHOULD_DISPLAY_TAX.value &&
            document.taxable.value &&
            line.tax &&
            !document.reverseCharge.value &&
            !HIDE_PRICES.value
            ? `<td class="${makeTdClass('col-vat')}">${line.type !== LINE_TYPE_TEXT ? formatTax(line.tax) : ''}</td>`
            : '',
          hasDiscountedLines.value && !HIDE_PRICES.value
            ? `<td class="${makeTdClass('col-discount')} text-right">${
                line.type !== LINE_TYPE_TEXT ? discountAmount : ''
              }</td>`
            : '',
          !HIDE_PRICES.value
            ? `<td class="${makeTdClass('col-total', true)} ${line.optional || props.isOptional ? 'italic' : ''}">${
                line.type !== LINE_TYPE_TEXT
                  ? (line.optional || props.isOptional ? '* ' : '') +
                    $format.currency(_get(lineTotal.value, `${line.key}.subtotal`, 0))
                  : ''
              }</td>`
            : ''
        )
        .join('')
    }

    container.appendChild(row)

    if (!IS_DETAILED_SITUATION) {
      let borderClass = getTemplateOption.value('row_border_h') === true ? 'border-b border-gray-200' : ''

      // Don't add border bottom if there's images after
      if (
        line.productType === PRODUCT_TYPE_WORK_DETAILED &&
        line.imagesPosition === 'bottom' &&
        line.images.length > 0
      ) {
        borderClass = ''
      }

      let supplies = null
      if (line.productType === PRODUCT_TYPE_WORK_DETAILED) {
        supplies = makeSuppliesDom(line, props.contrastClass, borderClass)
      }

      if (supplies) {
        for (const supply of supplies) {
          container.appendChild(supply)
        }
      }

      if (
        line.productType === PRODUCT_TYPE_WORK_DETAILED &&
        line.imagesPosition === 'bottom' &&
        line.images.length > 0
      ) {
        let images = window.document.createElement('tr')
        if (props.contrastClass) {
          images.classList.add(props.contrastClass)
        }

        images.innerHTML = [
          SHOULD_DISPLAY_NUMBERING.value ? `<td class="${makeTdClass('col-num')}"></td>` : '',
          `<td class="${makeTdClass('col-label')}">
                ${makeRowImagesHTML(line, ['flex-wrap'])}
            </td>`,
          hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_REFERENCE) &&
          ![DOCUMENT_SUBTYPE_DOWN_PAYMENT, DOCUMENT_SUBTYPE_SITUATION].includes(document.subtype.value)
            ? `<td class="${makeTdClass('col-reference')}"></td>`
            : '',
          standardDocumentDataPlaceholder,
          SHOULD_DISPLAY_TAX.value &&
          document.taxable.value &&
          line.tax &&
          !document.reverseCharge.value &&
          !HIDE_PRICES.value
            ? `<td class="${makeTdClass('col-vat')}"></td>`
            : '',
          hasDiscountedLines.value && !HIDE_PRICES.value ? `<td class="${makeTdClass('col-discount')}"></td>` : '',
          !HIDE_PRICES.value ? `<td class="${makeTdClass('col-total', true)}"></td>` : '',
        ].join('')

        if (getTemplateOption.value('row_border_h') === true && props.index0 < props.totalLines - 1) {
          images.classList.add('border-b', 'border-gray-200')
        }

        container.appendChild(images)
      } else {
        if (!supplies) {
          if (getTemplateOption.value('row_border_h') === true && props.index0 < props.totalLines - 1) {
            row.classList.add('border-b', 'border-gray-200')
          }
        }
      }
    } else {
      if (
        !hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_WORK_DETAILS) &&
        //!hasOption(DOCUMENT, DOCUMENT_OPTION_HIDE_SITUATION_UNIT) &&
        (hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_PRICES) ||
          hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_WORK_QTY))
      ) {
        let borderClass = getTemplateOption.value('row_border_h') === true ? 'border-b border-gray-200' : ''

        let supplies = null
        if (line.productType === PRODUCT_TYPE_WORK_DETAILED) {
          supplies = makeSituationSuppliesDom(line, props.contrastClass, borderClass)
        }

        if (supplies) {
          for (const supply of supplies) {
            container.appendChild(supply)
          }
        }
      } else {
        if (getTemplateOption.value('row_border_h') === true) {
          row.classList.add('border-b', 'border-gray-200')
        }
      }
    }

    if (props.contrastClass) {
      row.classList.add(...props.contrastClass.split(' ').filter(Boolean))
    }

    return container
  }

  const makeCompanySignatureBlock = () => {
    if (hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE) && document.object.value !== QUOTE) {
      const signatureUrl = _get(company.value, 'signature.url', null)
      const signature =
        signatureUrl !== null ? `<img src="${signatureUrl}" class="translate-y-2 mx-auto h-full" />` : ''

      return `<div class="mt-4 border-2 border-gray-300 ${
        getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
      } p-3 text-gray-500 text-xs">
        <span>Signature de l'entreprise</span>
        <div class="h-24">
            ${signature}
        </div>
      </div>`
    }
    return ''
  }

  const makeBottomDom = () => {
    const container = window.document.createElement('div')
    container.classList.add('document-page-unbreakable', 'page-bottom')

    /*let noTaxSentence =
      !document.taxable.value && company.value.country === 'fr'
        ? `<p class="text-center text-xs text-gray-500 italic mt-2">
            ${app.i18n.t('documents.text.noTaxable')}
          </p>`
        : ''

    let reverseChargeSentence = document.reverseCharge.value
      ? `<div class="text-center text-xs text-gray-500 italic mt-2">
            ${
              app.i18n.te(`documents.text.hasReverseCharge.${company.value.country}`)
                ? app.i18n.t(`documents.text.hasReverseCharge.${company.value.country}`)
                : app.i18n.t(`documents.text.hasReverseCharge.default`)
            }
          </div>`
      : ''*/

    let vatNotice = document.vatNotice.value
      ? `<div class="text-center text-xs text-gray-500 italic mt-2">${document.vatNotice.value}</div>`
      : ''

    let clientSignatureBlock =
      !hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_COMPANY_SIGNATURE) &&
      document.object.value === QUOTE &&
      document.objectType.value !== WORK_ORDER
        ? `<div class="mt-4 border-2 border-gray-300 ${
            getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
          } p-3 text-gray-500 text-xs">
            <div>${nl2br(
              company.value.quoteDefaultClientSignatureLabel || app.i18n.t('quotes.label.defaultClientSignatureLabel')
            )}</div>
            <div class="h-24"></div>
          </div>`
        : ''

    container.innerHTML = `${
      HIDE_PRICES.value
        ? `<div class="${hasOptionalLines.value > 0 ? 'mt-3' : 'mt-5'} flex flex-nowrap">
            <div class="shrink-0 leading-tight w-[56%] mr-[2%]">
                ${makeNotesDom()}
            </div>
            <div class="grow shrink-0 w-[42%]">
            ${document.objectType.value === ORDER_FORM ? makeCompanySignatureBlock() : ''}
            </div>
        </div>`
        : `<div class="${hasOptionalLines.value > 0 ? 'mt-3' : 'mt-5'} flex flex-nowrap">
        <div class="shrink-0 leading-tight w-[56%] mr-[2%]">
          <div class="space-y-4">
            ${document.objectType.value !== WORK_ORDER ? makeTaxTotalDom() : ''}
            ${document.objectType.value !== WORK_ORDER ? makeDownPaymentsDom() : ''}
            ${document.objectType.value !== WORK_ORDER ? makePaymentMethodsDom() : ''}

            ${document.objectType.value !== WORK_ORDER ? makePaymentConditionsTextDom() : ''}
            ${makeNotesDom()}
          </div>
        </div>
        <div class="grow shrink-0 w-[42%] ${
          (hasLines || document.objectType.value !== WORK_ORDER) &&
          (Object.keys(downPaymentsValues.value).length > 0 || Object.keys(document.paymentMethods.value).length > 0)
            ? 'mt-6'
            : ''
        }" >
            ${hasLines || document.objectType.value !== WORK_ORDER ? makeTotalDom() : ''}
            ${hasLines || document.objectType.value !== WORK_ORDER ? vatNotice : ''}
            ${clientSignatureBlock}
            ${makeCompanySignatureBlock()}
        </div>
      </div>`
    }

      <div>`

    return container
  }

  const makeTermsDom = () => {
    const terms = getTerms.value

    if (terms.termsDocuments.includes(document.objectType.value)) {
      const container = window.document.createElement('div')
      container.innerHTML = ` <div class="prose prose-terms max-w-none" style="page-break-before: always">
        ${terms.terms}
    </div>`

      return container
    }

    return ''
  }

  const makeWorkOrderSignatureDom = () => {
    const container = window.document.createElement('div')
    container.classList.add('flex', 'mt-8', 'space-x-4', 'justify-center')

    const signatureUrl = _get(company.value, 'signature.url', null)

    const signature = signatureUrl !== null ? `<img src="${signatureUrl}" class="translate-y-2 mx-auto h-full" />` : ''

    container.innerHTML = `<div class="w-[280px] border-2 border-gray-300 ${
      getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
    } p-3 text-gray-500 text-xs">
        <span>Signature de l'intervenant</span>
        <div class="h-24">

        </div>
      </div>
      <div class="w-[280px] border-2 border-gray-300 ${
        getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
      } p-3 text-gray-500 text-xs">
        <div>${nl2br(
          company.value.workOrderDefaultClientSignatureLabel ||
            app.i18n.t('work_orders.label.defaultClientSignatureLabel')
        )}</div>
        <div class="h-24"></div>
      </div>`

    return container
  }

  const makeCompanySignatureDom = () => {
    const container = window.document.createElement('div')
    container.classList.add('flex', 'mt-8', 'space-x-4', 'justify-center')

    const signatureUrl = _get(company.value, 'signature.url', null)

    const signature = signatureUrl !== null ? `<img src="${signatureUrl}" class="translate-y-2 mx-auto h-full" />` : ''

    container.innerHTML = `<div class="w-[280px] border-2 border-gray-300 ${
      getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
    } p-3 text-gray-500 text-xs">
        <span>Signature de l'entreprise</span>
        <div class="h-24">
            ${signature}
        </div>
      </div>
      <div class="w-[280px] border-2 border-gray-300 ${
        getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
      } p-3 text-gray-500 text-xs">
        <div>${nl2br(
          company.value.quoteDefaultClientSignatureLabel || app.i18n.t('quotes.label.defaultClientSignatureLabel')
        )}</div>
        <div class="h-24"></div>
      </div>`

    return container
  }

  const makeSectionsSummaryDom = () => {
    if (
      hasLines &&
      lines.value.filter((el) => el.type === 'group').length > 0 &&
      hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_SECTION_SUMMARY)
    ) {
      const container = window.document.createElement('div')
      container.classList.add('my-6')

      let inner = window.document.createElement('div')

      let rows = []

      const walkSections = (sections, index = 1, parentIndex = null) => {
        for (const key of sections) {
          const line = getLine.value[key]

          const compositeIndex = parentIndex ? `${parentIndex}.${index}` : index
          const indexLength = compositeIndex.toString().split('.').length

          if (line.type === 'group') {
            const groupTotal = getGroupTotal.value({ key, groupIsOptional: line.optional })
            rows.push(`<tr>
          <td class="px-2 py-1" style="padding-left: ${0.5 + indexLength * 0.25}rem">${
              hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_NUMBERING)
                ? `<span class="mr-1">${compositeIndex}</span>`
                : ''
            } ${line.description}</td>
          <td class="px-2 py-1 text-right">${$format.currency(groupTotal)}</td>
        </tr>`)
            walkSections(getChildTree.value[key], 1, compositeIndex)
          }

          if (!['text', 'page_break', 'line_break'].includes(line.type)) {
            index++
          }
        }
      }

      walkSections(tree.value, 1, null)

      inner.innerHTML = `<table>
        <thead>
          <tr>
            <th class="px-2 py-1 bg-color font-semibold font-color">Section</th>
            <th class="px-2 py-1 bg-color font-semibold font-color text-right">Montant HT</th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td class="py-0.5"></td>
            </tr>
            ${rows.join('')}
            <tr>
              <td class="py-0.5"></td>
            </tr>
        </tbody>
      </table>`

      container.innerHTML = `<span class="block head-title text-xs uppercase font-semibold h-4">${app.i18n.t(
        `documents.preview.label.sectionsSummary`
      )}</span>
    <div class="text-xs mt-2 ${
      getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
    } border border-gray-200 overflow-hidden">
    ${inner.outerHTML}
    </div>`

      return container
    }

    return null
  }

  const makeTaxTotalDom = () => {
    if (IS_ORDER_FORM || !document.taxable.value || document.reverseCharge.value) {
      return ''
    }

    if (hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_TAX_DETAILS) && total.value.taxAmounts) {
      const container = window.document.createElement('div')

      let inner = window.document.createElement('div')
      let rows = Object.values(total.value.taxAmounts)
        .sort((a, b) => (a.rate > b.rate ? 1 : -1))
        .reduce((arr, tax) => {
          arr.push(`<tr>
          <td class="px-2 py-1">${tax.acronym} ${formatTaxAmount(tax.rate / 100)} %</td>
          <td class="px-2 py-1 text-right">${$format.currency(tax.total)}</td>
          <td class="px-2 py-1 text-right">${$format.currency(tax.amount)}</td>
        </tr>`)
          return arr
        }, [])

      inner.innerHTML = `<table>
        <thead>
          <tr>
            <th class="px-2 py-1 bg-color font-semibold font-color">Taux</th>
            <th class="px-2 py-1 bg-color font-semibold font-color text-right">Base HT</th>
            <th class="px-2 py-1 bg-color font-semibold font-color text-right">Montant</th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td class="py-0.5"></td>
            </tr>
            ${rows.join('')}
            <tr>
              <td class="py-0.5"></td>
            </tr>
        </tbody>
      </table>`

      container.innerHTML = `<span class="block head-title text-xs uppercase font-semibold h-4">${app.i18n.t(
        `documents.preview.label.taxDetails`,
        { tax: getCompanyTax(company.value) }
      )}</span>
    <div class="text-xs mt-2 mr-4 ${
      getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
    } border border-gray-200 overflow-hidden">
    ${inner.outerHTML}
    </div>`

      return container.outerHTML
    }

    return ''
  }

  const makeDownPaymentsDom = () => {
    if (Object.keys(downPaymentsValues.value).length > 0) {
      const container = window.document.createElement('div')

      let inner = window.document.createElement('div')

      const makeDownPayment = (downPayment) =>
        `<div>
        <span class="font-semibold">${$format.currency(downPayment.total)}</span>
        ${
          document.taxable.value && !document.reverseCharge.value
            ? `${downPayment.type === AMOUNT_TYPE_PERCENT ? `<span>TTC (${downPayment.amount} %)</span>` : ''}
        ${downPayment.type === AMOUNT_TYPE_SUBTOTAL ? `<span>HT</span>` : ''}
        ${downPayment.type === AMOUNT_TYPE_TOTAL ? `<span>TTC</span>` : ''}`
            : `${downPayment.type === AMOUNT_TYPE_PERCENT ? `<span> (${downPayment.amount} %)</span>` : ''}`
        }

        ${
          downPayment.time === DOWN_PAYMENT_TIME_CUSTOM
            ? downPayment.label
            : app.i18n.t(`documents.label.downPayment.time.${downPayment.time}`)
        }
      </div>`

      if (Object.keys(downPaymentsValues.value).length > 1) {
        inner.classList.add('flow-root', 'text-xs')

        inner.innerHTML = `<ul role="list" class="-mb-2">
        ${downPaymentsValues.value
          .map(
            (downPayment, i) =>
              `<li>
                <div class="relative pb-2">
                ${
                  i < Object.keys(downPaymentsValues.value).length - i
                    ? `<span
                        class="absolute top-1 left-1 -ml-px h-full w-px bg-color"
                        aria-hidden="true"
                      ></span>`
                    : ''
                }
                <div class="relative flex items-center space-x-3">
                <div>
                  <span class="h-2 w-2 rounded-full bg-color flex items-center justify-center ring-2 ring-white">
                  </span>
                </div>
                <div class="min-w-0 flex-1 flex justify-between space-x-4">
                  ${makeDownPayment(downPayment)}
                </div>
              </div>
            </div>
            </div>
          </li>`
          )
          .join('')}
      </ul>`
      } else {
        inner.innerHTML = downPaymentsValues.value.map((downPayment) => makeDownPayment(downPayment)).join('')
      }

      container.innerHTML = `<span class="block head-title text-xs uppercase font-semibold h-4">${app.i18n.tc(
        `documents.preview.label.downPayments`,
        Object.keys(downPaymentsValues.value).length
      )}</span>
    <div class="text-xs mt-2 p-3 mr-4 ${
      getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
    } border border-gray-200">
    ${inner.outerHTML}
    </div>`

      return container.outerHTML
    }

    return ''
  }

  const getPaymentMethodIcon = {
    [PAYMENT_METHOD_CHECK]: faMoneyCheck,
    [PAYMENT_METHOD_CARD]: faCreditCard,
    [PAYMENT_METHOD_CASH]: faCoins,
    [PAYMENT_METHOD_WIRE]: faBuildingColumns,
    [PAYMENT_METHOD_DIRECT_DEBIT]: faBuildingColumns,
    [PAYMENT_METHOD_LOAN]: faEuroSign,
    [PAYMENT_METHOD_PAYBYLINK]: faArrowPointer,
  }

  const getPaymentMethodPath = {
    [PAYMENT_METHOD_CHECK]: faMoneyCheck.icon[4],
    [PAYMENT_METHOD_CARD]: faCreditCard.icon[4],
    [PAYMENT_METHOD_CASH]: faCoins.icon[4],
    [PAYMENT_METHOD_WIRE]: faBuildingColumns.icon[4],
    [PAYMENT_METHOD_DIRECT_DEBIT]: faBuildingColumns.icon[4],
    [PAYMENT_METHOD_LOAN]: faEuroSign.icon[4],
    [PAYMENT_METHOD_PAYBYLINK]: faArrowPointer.icon[4],
  }

  const makePaymentConditionsDom = () => {
    if (
      document.customer.value &&
      document.objectType.value === INVOICE &&
      document.customer.value.legalStatus === 'company'
    ) {
      let sentence = null

      let compensation = company.value.invoicePaymentConditions.compensation
        ? `une indemnité de ${formatNumber(
            company.value.invoicePaymentConditions.compensation
          )} ${$format.currencySymbol()}`
        : null

      let penalty = company.value.invoicePaymentConditions.penalty
        ? `des pénalités de retard au taux de ${formatNumber(company.value.invoicePaymentConditions.penalty)} % par an`
        : null

      if (compensation) {
        if (penalty) {
          sentence = `${compensation} ainsi que ${penalty} sont dues`
        } else {
          sentence = `${compensation} est due`
        }
      } else if (penalty) {
        sentence = `${penalty} sont dues`
      }

      return sentence
        ? `<div class="mb-4">
                    <span class="block head-title text-xs uppercase font-semibold h-4">Conditions de paiement</span>
                    <p class="text-xs tracking-tight text-gray-500 mt-2 pr-4">Conformément à l'article L. 441-10 du code de commerce, ${sentence} en cas de retard de paiement.</p>
                </div>`
        : ''
    } else {
      return ''
    }
  }

  const makePaymentMethodsDom = () => {
    if (Object.keys(document.paymentMethods.value).length > 0) {
      const container = window.document.createElement('div')

      const inner = window.document.createElement('div')

      for (const paymentMethod of []
        .concat(document.paymentMethods.value)
        .sort((a, b) => PAYMENT_METHOD_TYPES.indexOf(a) - PAYMENT_METHOD_TYPES.indexOf(b))) {
        const el = window.document.createElement('div')
        el.classList.add('flex')
        let payableTo = ''

        if (paymentMethod === PAYMENT_METHOD_CHECK && company.value.checkPayableTo) {
          payableTo = `<span >à l'ordre de <span class="font-semibold">${company.value.checkPayableTo}</span></span>`
        }

        if (paymentMethod === PAYMENT_METHOD_WIRE && _get(document, 'bankAccount.value.account', null)) {
          payableTo = `<div class="mt-0.5">
                <span class="flex"
                ><span class="font-semibold w-10">IBAN</span>
                  ${chunk_split(
                    '' + _get(document, 'bankAccount.value.account', '').replaceAll(' ', '').trim(),
                    4,
                    ' '
                  )}</span
                >
          ${
            _get(document, 'bankAccount.value.code', null)
              ? `<span class="flex"><span class="font-semibold w-10">BIC</span> ${_get(
                  document,
                  'bankAccount.value.code',
                  null
                )}</span>`
              : ''
          }
        </div>`
        }

        el.innerHTML = `<span class="text-sm mr-2">
          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="coins" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-w-16">
            <path fill="currentColor" d="${getPaymentMethodPath[paymentMethod]}"></path>
            </svg>
          </span>
          <div class="pt-0.5">
           ${app.i18n.t(`documents.label.paymentMethod.${paymentMethod}`)}
           ${payableTo}
          </div>`

        inner.appendChild(el)
      }

      container.innerHTML = `<span class="block head-title text-xs uppercase font-semibold h-4">${app.i18n.tc(
        `documents.preview.label.paymentMethods`,
        Object.keys(document.paymentMethods.value).length
      )}</span>
    <div class="space-y-1 text-xs mt-2 p-3 mr-4 ${
      getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
    } border border-gray-200">${inner.innerHTML}</div>`

      return container.outerHTML
    }

    return ''
  }

  const toDeduct = computed(() => {
    let amount = 0

    const summary = getInvoicesSummary.value(total)

    if (summary.invoices) {
      for (const invoice of summary.invoices) {
        for (const item of Object.values(invoice.deductedDocuments)) {
          if (item.deduction === 'total') {
            amount -= item.deducted
          }
        }
      }
    }

    if (document.downPaymentsProrated.value) {
      for (const item of Object.values(total.value.deductedDocuments)) {
        if (item.deduction === 'total') {
          amount += item.deductedInvoice.total
          amount -= item.deducted
        }
      }
    }

    return amount
  })

  const makeInvoicesSummaryDom = () => {
    const container = window.document.createElement('div')
    container.classList.add('mt-6')
    container.innerHTML = `<span class="block head-title text-xs uppercase font-semibold h-4 mb-2">Décompte général${
      document.final.value ? ' définitif' : ''
    }</span>`

    const quote = document.quote.value
    const table = window.document.createElement('table')
    const tableContainer = window.document.createElement('div')

    tableContainer.classList.add('border', 'border-gray-200', 'overflow-hidden')

    if (getTemplateOption.value('rounded_borders')) {
      tableContainer.classList.add('rounded-lg')
    }

    table.innerHTML = `<thead>
          <th class="bg-color p-2 font-semibold font-color">Document</th>
          <th class="bg-color p-2 font-semibold font-color">Numéro</th>
          <th class="bg-color p-2 font-semibold font-color">Date</th>
          <th class="bg-color p-2 font-semibold font-color text-right">Montant HT</th>
           ${
             document.taxable.value && !document.reverseCharge.value
               ? `<th class="bg-color p-2 font-semibold font-color text-right">Montant TTC</th>`
               : ''
           }
        </thead>`

    if (quote) {
      table.innerHTML += `<tr class="align-bottom ">
          <td class="p-2 pb-0 font-semibold">
           Devis
          </td>
          <td class="p-2 pb-0">
          ${quote.number}
          </td>
          <td class="p-2 pb-0">
            <div>
              ${format(new Date(quote.issuedAt || quote.createdAt), 'dd/MM/yyyy', {
                locale: fr,
              })}
            </div>
          </td>
          <td class="p-2 pb-0 text-right">
            ${$format.currency(quote.subtotal)}
          </td>
          ${
            document.taxable.value && !document.reverseCharge.value
              ? `<td class="p-2 pb-0 text-right">
            ${$format.currency(quote.total)}
          </td>`
              : ''
          }
        </tr>`
    }

    if (total.value.additionalQuoted !== 0) {
      table.innerHTML += `<tr class="align-bottom ">
          <td class="p-2 py-0 font-semibold">
           Plus-values
          </td>
          <td class="p-2 py-0"></td>
          <td class="p-2 py-0"></td>
          <td class="p-2 py-0 text-right">
            ${$format.currency(total.value.additionalQuoted)}
          </td>
          ${
            document.taxable.value && !document.reverseCharge.value
              ? `<td class="p-2 py-0 text-right">
            ${$format.currency(total.value.additionalQuoted + total.value.additionalQuotedAmount)}
          </td>`
              : ''
          }
        </tr>`
    }

    if (total.value.depreciationQuoted !== 0) {
      table.innerHTML += `<tr class="align-bottom ">
          <td class="p-2 py-0 font-semibold">
           Moins-values
          </td>
          <td class="p-2 py-0"></td>
          <td class="p-2 py-0"></td>
          <td class="p-2 py-0 text-right">
            -${$format.currency(total.value.depreciationQuoted)}
          </td>
          ${
            document.taxable.value && !document.reverseCharge.value
              ? `<td class="p-2 py-0 text-right">
            -${$format.currency(total.value.depreciationQuoted + total.value.depreciationQuotedAmount)}
          </td>`
              : ''
          }
        </tr>`
    }

    if (quote || total.value.additionalQuoted !== 0 || total.value.depreciationQuoted !== 0) {
      table.innerHTML += `<tr>
            <td colspan="${document.taxable.value ? 5 : 4}" class="py-2 px-0"><hr class="border-gray-200" /></td>
          </tr>`
    }

    const hasLeftToDeduct =
      document.downPaymentsDeduction.value === 'total' && document.downPaymentsProrated.value && toDeduct.value !== 0

    const summary = getInvoicesSummary.value(total)

    if (summary.invoices) {
      let index = 1
      for (const invoice of summary.invoices) {
        const issuedAt = `<div class="text-gray-500">${format(
          new Date(invoice.issuedAt || invoice.createdAt),
          'dd/MM/yyyy',
          {
            locale: fr,
          }
        )}</div>`

        table.innerHTML += `<tr  class="align-top">
            <td class="p-2 ${quote ? 'py-0' : 'pb-0'} font-semibold">
               ${app.i18n.t(`invoices.label.subtype.${invoice.subtype || 'default'}`)} #${invoice.subNumber}
            </td>
            <td class="p-2 ${quote ? 'py-0' : 'pb-0'}">${invoice.number || ''}</td>
            <td class="p-2 ${quote ? 'py-0' : 'pb-0'}">
                ${format(new Date(invoice.issuedAt || invoice.createdAt), 'dd/MM/yyyy', {
                  locale: fr,
                })}
            </td>
            <td class="p-2 ${quote ? 'py-0' : 'pb-0'} text-right">
              ${$format.currency(invoice.subtotal)}
            </td>
            ${
              document.taxable.value && !document.reverseCharge.value
                ? `<td class="p-2 ${quote ? 'py-0' : 'pb-0'} text-right">
              ${$format.currency(invoice.total)}
            </td>`
                : ''
            }
          </tr>
          ${
            invoice.additionalSubtotal
              ? `<tr class="text-gray-500 italic">
            <td class="p-2 py-0">dont plus-values</td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0 text-right">
              ${$format.currency(invoice.additionalSubtotal)}
            </td>
            ${
              document.taxable.value && !document.reverseCharge.value
                ? `<td  class="p-2 py-0 text-right">
              ${$format.currency(invoice.additionalTotal)}
            </td>`
                : ''
            }
          </tr>`
              : ''
          }
          ${
            invoice.depreciationSubtotal
              ? `<tr class="text-gray-500 italic">
            <td class="p-2 py-0">dont moins-values</td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0 text-right">
              -${$format.currency(invoice.depreciationSubtotal)}
            </td>
            ${
              document.taxable.value && !document.reverseCharge.value
                ? `<td  class="p-2 py-0 text-right">
              -${$format.currency(invoice.depreciationTotal)}
            </td>`
                : ''
            }
          </tr>`
              : ''
          }
          ${
            (document.downPaymentsDeduction.value === 'subtotal' && index > 1) ||
            (document.downPaymentsDeduction.value === 'total' &&
              invoice.subtype !== DOCUMENT_SUBTYPE_DOWN_PAYMENT &&
              invoice.subNumber > 1)
              ? `<tr>
            <td class="p-2 py-0">Cumul facturé</td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0 text-right">
              ${$format.currency(invoice.acc.subtotal)}
            </td>
             ${
               document.taxable.value && !document.reverseCharge.value
                 ? `<td class="p-2 py-0 text-right">
              ${$format.currency(invoice.acc.total)}
            </td>`
                 : ''
             }
          </tr>`
              : ''
          }
          ${
            invoice.holdbackAmount
              ? ` <tr >
            <td class="p-2 py-0">Retenue de garantie</td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0"></td>
             ${document.taxable.value && !document.reverseCharge.value ? `<td class="p-2 py-0 text-right"></td>` : ''}
            <td class="p-2 py-0 text-right">
              ${$format.currency(invoice.holdbackAmount)}
            </td>
          </tr>`
              : ''
          }
          <tr>
            <td colspan="${
              document.taxable.value && !document.reverseCharge.value ? 5 : 4
            }" class="py-2 px-0"><hr class="border-gray-200" /></td>
          </tr>`

        ++index
      }

      const leftToCharge = isSituationComplete.value
        ? 0
        : quote.subtotal +
            total.value.additionalQuoted +
            total.value.additionalQuotedAmount -
            total.value.depreciationQuoted -
            total.value.depreciationQuotedAmount -
            summary.acc.subtotal -
            total.value.subtotal <=
          0
        ? 0
        : quote.total +
          total.value.additionalQuoted +
          total.value.additionalQuotedAmount -
          total.value.depreciationQuoted -
          total.value.depreciationQuotedAmount -
          summary.acc.total -
          total.value.total

      table.innerHTML += `
        <tr>
          <td class="p-2 py-0 font-semibold">
           ${app.i18n.t(`invoices.label.subtype.${document.subtype.value || 'default'}`)}
           #${document.subNumber.value}
          </td>
          <td class="p-2 py-0">
          ${number.value}
          </td>
          <td class="p-2 py-0">
          ${format(document.issuedAt.value || new Date(), 'dd/MM/yyyy', {
            locale: fr,
          })}
          </td>
          <td class="p-2 py-0 text-right">
            ${$format.currency(total.value.subtotal)}
          </td>
           ${
             document.taxable.value && !document.reverseCharge.value
               ? `<td class="p-2 py-0 text-right">
          ${$format.currency(total.value.total)}
          </td>`
               : ''
           }
        </tr>
        ${
          total.value.additionalSubtotal
            ? `<tr class="text-gray-500 italic">
            <td class="p-2 py-0">dont plus-values</td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0 text-right">
              ${$format.currency(total.value.additionalSubtotal)}
            </td>
            ${
              document.taxable.value && !document.reverseCharge.value
                ? `<td  class="p-2 py-0 text-right">
              ${$format.currency(total.value.additionalTotal)}
            </td>`
                : ''
            }

          </tr>`
            : ''
        }
        ${
          total.value.depreciationSubtotal
            ? `<tr class="text-gray-500 italic">
            <td class="p-2 py-0">dont moins-values</td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0 text-right">
              -${$format.currency(total.value.depreciationSubtotal)}
            </td>
            ${
              document.taxable.value && !document.reverseCharge.value
                ? `<td  class="p-2 py-0 text-right">
              -${$format.currency(total.value.depreciationTotal)}
            </td>`
                : ''
            }

          </tr>`
            : ''
        }
        ${
          document.downPaymentsDeduction.value !== 'total' || document.subNumber.value > 1
            ? `<tr>
            <td class="p-2 py-0">Cumul facturé</td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0"></td>
            <td class="p-2 py-0 text-right">
              ${$format.currency(summary.acc.subtotal + total.value.subtotal)}
            </td>
             ${
               document.taxable.value && !document.reverseCharge.value
                 ? `<td class="p-2 py-0 text-right">
              ${$format.currency(summary.acc.total + total.value.total)}
            </td>`
                 : ''
             }
          </tr>`
            : ''
        }

          ${
            total.value.holdbackAmount
              ? ` <tr >
                      <td class="p-2 py-0">Retenue de garantie</td>
                      <td class="p-2 py-0"></td>
                      <td class="p-2 py-0"></td>
                       ${
                         document.taxable.value && !document.reverseCharge.value
                           ? `<td class="p-2 py-0 text-right"></td>`
                           : ''
                       }
                      <td class="p-2 py-0 text-right">
                        ${$format.currency(total.value.holdbackAmount)}
                      </td>
                    </tr>`
              : ''
          }
          <tr>
            <td colspan="${
              document.taxable.value && !document.reverseCharge.value ? 5 : 4
            }" class="py-2 px-0"><hr class="border-gray-200" /></td>
          </tr>
        <tr class="align-bottom">
          <td class="p-2 ${hasLeftToDeduct ? 'pb-0.5' : ''} pt-0 font-semibold">
           Reste à facturer
          </td>
          <td class="p-2 ${hasLeftToDeduct ? 'pb-0.5' : ''} pt-0 "></td>
          <td class="p-2 ${hasLeftToDeduct ? 'pb-0.5' : ''} pt-0"></td>
          <td class="p-2 ${hasLeftToDeduct ? 'pb-0.5' : ''} pt-0 text-right">
            ${$format.currency(document.remainingSubtotal.value)}
          </td>
           ${
             document.taxable.value && !document.reverseCharge.value
               ? `<td class="p-2 ${hasLeftToDeduct ? 'pb-0.5' : ''} pt-0 text-right">
          ${$format.currency(document.remainingTotal.value)}
          </td>`
               : ''
           }
        </tr>`

      const downPayments = _get(summary, 'invoices', []).filter(
        (el) => el.subtype === DOCUMENT_SUBTYPE_DOWN_PAYMENT
      ).length
      if (hasLeftToDeduct) {
        table.innerHTML += `<tr class="align-bottom italic">
          <td class="p-2 pt-0">
            ${downPayments > 1 ? 'Acomptes restants à déduire' : 'Acompte restant à déduire'}
          </td>
          <td class="p-2 pt-0"></td>
          <td class="p-2 pt-0"></td>

           ${document.taxable.value && !document.reverseCharge.value ? `<td class="p-2 pt-0 text-right"></td>` : ''}
           <td class="p-2 pt-0 text-right">
          ${$format.currency(toDeduct.value)}
          </td>
        </tr>`
      }
    }

    tableContainer.innerHTML = table.outerHTML

    container.innerHTML += tableContainer.outerHTML
    return container
  }

  const makeTotalDom = () => {
    return document.taxable.value && !document.reverseCharge.value
      ? makeTaxableTotal().outerHTML
      : makeTotal().outerHTML
  }

  const discountAmount = document.amountOff.value || document.percentOff.value

  const makeDeducedHTML = (type) => {
    if (document.downPaymentsDeduction.value !== type) return null

    if (total.value.deductedDocuments.length) {
      return total.value.deductedDocuments
        .map(
          (el) => `<tr class="align-top">
                <th class="font-normal text-left">
                  <span class="block">${app.i18n.t(`invoices.label.subtype.${el.deductedInvoice.subtype}`)} ${
            el.deductedInvoice.number
          } ${
            document.taxable.value && document.downPaymentsDeduction.value === 'subtotal'
              ? `<br />(${$format.currency(el.deductedInvoice.total)} TTC)`
              : ''
          }</span>
                </th>
                <td class="text-right whitespace-nowrap">-${$format.currency(el.deducted)}</td>
              </tr>`
        )
        .join('')
    }
    return null
  }

  const makeHoldbackHTML = () => {
    if (document.holdback.value) {
      return document.objectType.value === QUOTE
        ? `<tr class="align-top">
        <th class="font-normal !py-2">
        Retenue de garantie
        </th>
        <td class="text-right !py-2">${document.holdback.value} %</td>
        </tr>`
        : `${totalSpacer()}<tr class="align-top">
        <th class="font-normal">
        <span class="block">Retenue de garantie (${document.holdback.value} %)</span>
        <span class="block">Dûe le ${format(document.holdbackReleasedAt.value, 'dd/MM/yyyy', {
          locale: fr,
        })}</span>
        </th>
        <td class="text-right"><span class="block">-${$format.currency(total.value.holdbackAmount)}</span></td>
        </tr>`
    }
    return ''
  }

  const makeNetToPayHTML = () => {
    let messageKey = 'netToPay'

    if (document.objectType.value === CREDIT) messageKey = 'netToDeduct'
    if (document.objectType.value === ORDER_FORM) messageKey = 'taxable.totalWithTax'

    return `<tr>
          <th colspan="2" class="!p-0">
            <div class="tableTotal bg-color">

              <span class="text-base font-semibold"> ${app.i18n.t(`documents.total.${messageKey}`)}</span>
              ${
                total.value
                  ? `<span class="text-right ml-auto font-semibold">${$format.currency(total.value.netToPay)}</span>`
                  : ''
              }
            </div>
          </th>
        </tr>`
  }

  const makeTotalDeductionsHTML = () => {
    const deducted = makeDeducedHTML('total')
    let content = ''

    if (totalDeductionsValues.value.length || (document.downPaymentsDeduction.value === 'total' && deducted !== null)) {
      content = totalSpacer()
    }

    if (document.downPaymentsDeduction.value === 'total' && deducted !== null) {
      content += deducted
    }

    if (totalDeductionsValues.value.length) {
      content +=
        totalDeductionsValues.value
          .map(
            (deduction) => ` <tr class="align-top">
        <td class="break-all"
          >${deduction.name || app.i18n.t(`documents.label.deductionType.${deduction.type}`)}
          ${
            deduction.amountType === AMOUNT_TYPE_PERCENT
              ? `<span>(${
                  ['minus', undefined].includes(deduction.direction) && deduction.type !== DEDUCTION_TYPE_HOLDBACK
                    ? '-'
                    : ''
                }${deduction.amount} %)</span>`
              : ''
          }

          ${
            deduction.type === DEDUCTION_TYPE_HOLDBACK && deduction.releasedAt
              ? `<span class="block">Dûe le ${format(new Date(deduction.releasedAt), 'dd/MM/yyyy', {
                  locale: fr,
                })}</span>`
              : ''
          }
          </td>
        <td class="text-right">${['minus', undefined].includes(deduction.direction) ? '-' : ''}${$format.currency(
              deduction.total
            )}</td>
      </tr>`
          )
          .join('') + '<tr><td colspan="2" class="!p-0 !pb-2"></td></tr>'
    } else {
      content += '<tr><td colspan="2" class="!p-0 !pb-2"></td></tr>'
    }

    return content
  }

  const makeSubtotalDeductionsHTML = () => {
    const deducted = makeDeducedHTML('subtotal')
    const hasContent = subtotalDeductionsValues.value.length > 0 || deducted !== null
    const hasTotalDeductions = totalDeductionsValues.value.length > 0

    // if deducted
    /* `<tr>
            <th class="font-semibold">
              ${app.i18n.t(`documents.total.netTotal`)}
            </th>
            ${total.value ? `<td class="text-right">${$format.currency(total.value.subtotal)}</td>` : ''}
          </tr>`*/

    return `
    ${
      subtotalDeductionsValues.value.length
        ? (document.taxable.value && document.reverseCharge.value === false ? '' : totalSpacer()) +
          subtotalDeductionsValues.value
            .map(
              (deduction) => `<tr>
                <td class="break-all"
                  >${deduction.name || app.i18n.t(`documents.label.deductionType.${deduction.type}`)}
                  ${
                    deduction.amountType === AMOUNT_TYPE_PERCENT
                      ? `<span>(${['minus', undefined].includes(deduction.direction) ? '-' : ''}${
                          deduction.amount
                        } %)</span>`
                      : ''
                  }
                  </td>
                <td class="text-right">${
                  ['minus', undefined].includes(deduction.direction) ? '-' : ''
                }${$format.currency(deduction.total)}</td>
              </tr>`
            )
            .join('') +
          ''
        : ''
    }
    ${
      (document.downPaymentsDeduction.value === 'subtotal' || document.taxable.value === false) && deducted !== null
        ? deducted
        : ''
    }
    ${
      hasContent
        ? `<tr>
          <th class="!font-semibold pt-1 pl-2">
            ${app.i18n.t(`documents.total.netTotal`)}
          </th>
          <td class="!font-semibold text-right">
            ${$format.currency(total.value.subtotal)}
          </td>
        </tr>`
        : ''
    }
    ${hasContent && document.taxable.value && document.reverseCharge.value === false ? totalSpacer() : ''}
    `
  }

  const totalSpacer = () => {
    return '<tr><td colspan="2" class="!py-2 !px-0"><hr class="border-gray-200" /></td></tr>'
  }

  const makeTotal = () => {
    const container = window.document.createElement('div')
    container.classList.add(
      'border',
      'border-gray-200',
      'overflow-hidden',
      'pt-2',
      getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
    )

    const hasDeducted = subtotalDeductionsValues.value.length || document.deductedDocuments.value.length

    const discountHTML =
      discountAmount > 0
        ? `<tr>
            <th class="font-normal">
              Remise HT
              ${
                document.discountType.value === AMOUNT_TYPE_PERCENT
                  ? `<span>(${formatNumber(discountAmount)} %)</span>`
                  : ''
              }
            </th>
            <td class="text-right">-${$format.currency(total.value.discountTotal)}</td>
          </tr>
          <tr class="!font-semibold">
            <th class="!font-semibold pb-1">
              ${app.i18n.t(`documents.total.${hasDeducted ? 'total' : 'netTotal'}`)}
            </th>
            ${total.value ? `<td class="text-right" >${$format.currency(total.value.discountedSubtotal)}</td>` : ''}
          </tr>
            `
        : ''

    container.innerHTML = `<table class="document-total-table table-topless w-full">
      <tbody>
        <tr>
          <th class="font-semibold">
            ${app.i18n.t(`documents.total.${discountAmount > 0 ? 'subtotal' : 'total'}`)}
          </th>
          <td class="text-right font-semibold">
            ${$format.currency(total.value ? total.value.grossSubtotal : document.value.grossSubtotal)}
          </td>
        </tr>
        ${discountHTML}
        ${makeSubtotalDeductionsHTML()}
        ${document.objectType.value !== QUOTE ? makeHoldbackHTML() : ''}
        ${makeTotalDeductionsHTML()}
        ${makeNetToPayHTML()}
        ${document.objectType.value === QUOTE ? makeHoldbackHTML() : ''}
      </tbody>
    </table>`

    return container
  }

  const makeTaxableTotal = () => {
    const container = window.document.createElement('div')
    container.classList.add(
      'border',
      'border-gray-200',
      'overflow-hidden',
      'pt-2',
      getTemplateOption.value('rounded_borders') ? 'rounded-lg' : null
    )

    const discountHTML =
      discountAmount > 0
        ? `<tr>
            <th class="font-normal">
              Remise HT
              ${
                document.discountType.value === AMOUNT_TYPE_PERCENT
                  ? `<span>(${formatNumber(discountAmount)} %)</span>`
                  : ''
              }
            </th>
            <td class="text-right">-${$format.currency(total.value.discountTotal)}</td>
          </tr>
          <tr class="!font-semibold">
            <th class="!font-semibold pb-1">
              ${app.i18n.t(`documents.total.total`)}
            </th>
            ${total.value ? `<td class="text-right" >${$format.currency(total.value.discountedSubtotal)}</td>` : ''}
          </tr>`
        : ''

    let vatHTML =
      total.value && (IS_ORDER_FORM || !hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_TAX_DETAILS))
        ? Object.values(total.value.taxAmounts)
            .sort((a, b) => (a.rate > b.rate ? 1 : -1))
            .map(
              (taxAmount) => `<tr>
            <th class="font-normal">${taxAmount.acronym} ${formatTaxAmount(taxAmount.rate / 100)} %</th>
            <td class="text-right">${$format.currency(taxAmount.amount)}</td>
          </tr>`
            )
            .join('')
        : ''

    vatHTML +=
      Object.keys(total.value.taxAmounts).length > 1 || hasOption(DOCUMENT, DOCUMENT_OPTION_DISPLAY_TAX_DETAILS)
        ? `<tr>
            <th class="font-semibold">Total ${getCompanyTax(company.value)}</th>
            <td class="text-right font-semibold">${$format.currency(total.value.taxTotal)}</td>
          </tr>${document.objectType.value !== ORDER_FORM ? totalSpacer() : ''}`
        : ''

    container.innerHTML = `<table class="document-total-table table-topless" style="width: 100%">
      <tbody>
        <tr>
          <th class="font-normal">
            ${app.i18n.t(`documents.total.taxable.${discountAmount > 0 ? 'grossSubtotal' : 'total'}`)}
          </th>
          <td class="text-right">${$format.currency(total.value.grossSubtotal)}</td>
        </tr>
        ${discountHTML}
        ${totalSpacer()}
        ${makeSubtotalDeductionsHTML()}
        ${vatHTML}
        ${
          document.objectType.value !== ORDER_FORM
            ? `<tr>
          <th class="font-semibold pb-1">
            ${app.i18n.t(`documents.total.taxable.totalWithTax`)}
          </th>
          ${total.value ? `<td class="text-right font-semibold">${$format.currency(total.value.total)}</td>` : ''}
        </tr>`
            : ''
        }
        ${document.objectType.value !== QUOTE ? makeHoldbackHTML() : ''}
        ${makeTotalDeductionsHTML()}
        ${makeNetToPayHTML()}
        ${document.objectType.value === QUOTE ? makeHoldbackHTML() : ''}
      </tbody>
    </table>`

    return container
  }

  const makeNotesDom = () => {
    if (document.footer.value && document.footer.value !== '<div></div>') {
      return `<div class="notes">
      <span class="block head-title text-xs uppercase font-semibold h-4">Notes</span>
      <div class="prose-docs mt-1 text-xs">${document.footer.value}</div>
    </div>`
    }
    return ''
  }

  const makePaymentConditionsTextDom = () => {
    if (document.paymentConditions.value && document.paymentConditions.value !== '<div></div>') {
      return `<div class="notes">
      <span class="block head-title text-xs uppercase font-semibold h-4">Conditions de paiement</span>
      <div class="prose-docs mt-1 text-xs">${document.paymentConditions.value}</div>
    </div>`
    }
    return ''
  }

  const makeLineDom = (key, props) => {
    const line = getLine.value[key]
    let container = ''

    if (typeof line !== 'undefined') {
      switch (line.type) {
        case LINE_TYPE_GROUP:
          container = makeGroup(key, line, props)
          break
        case LINE_TYPE_TEXT:
          container = makeTextRow(key, line, props)
          break
        case LINE_TYPE_PAGE_BREAK:
          container = makePageBreak(key, line, props)
          break
        case LINE_TYPE_LINE_BREAK:
          container = makeLineBreak(key, line, props)
          break
        case LINE_TYPE_PRODUCT:
        case LINE_TYPE_DOWN_PAYMENT:
          container = makeProductRow(key, line, props)
          break
        case LINE_TYPE_SUBTOTAL:
          container = makeSubtotalRow(key, line, props)
          break
        default:
      }

      return { content: container || null, meta: line }
    }

    return { content: null, meta: {} }
  }

  const makeTdClass = (classes, noBorder = false) =>
    `${classes}${
      getTemplateOption.value('row_border_v') === true && noBorder === false ? ' border-r border-gray-200' : ''
    }`

  return { makeDocumentDom }
}
