//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, nextTick, onMounted, ref, watch } from '@nuxtjs/composition-api'
import * as noUiSlider from 'nouislider'
import 'nouislider/dist/nouislider.css'

export default defineComponent({
  props: {
    limit: Number,
    min: Number,
    max: Number,
    value: Number,
    total: Number,
    step: Number,
    available: Number,
    styles: Object,
  },
  setup(props, { emit }) {
    const range = ref(null)
    const slider = ref(null)

    const handleSlide = (values, handle, unencoded, tap, positions, noUiSlider) => {
      emit('input', unencoded[0])
      emit('slide')
      // values: Current slider values (array);
      // handle: Handle that caused the event (number);
      // unencoded: Slider values without formatting (array);
      // tap: Event was caused by the user tapping the slider (boolean);
      // positions: Left offset of the handles (array);
      // noUiSlider: slider public Api (noUiSlider);
    }

    const emitUpdate = (values, handle, unencoded, tap, positions, noUiSlider) => {
      emit('input', unencoded[0])
    }

    onMounted(async () => {
      await nextTick()
      if (range.value) {
        slider.value = noUiSlider.create(range.value, {
          start: props.value,
          step: props.step,
          padding: [0, 0],
          range: {
            min: props.min,
            max: props.max,
          },
          connect: [true, false],
        })

        slider.value.on('slide', handleSlide)
        slider.value.on('set', emitUpdate)

        let end = props.total - props.available - props.value
        if (end < 0 || end > props.total) end = 0
        slider.value.updateOptions({ padding: [0, end] })
      }
    })

    watch(
      () => props.value,
      (val) => {
        if (slider.value) {
          slider.value.updateOptions({ start: val })
        }
      }
    )

    watch(
      () => props.step,
      (val) => {
        if (slider.value) {
          slider.value.updateOptions({ step: val })
        }
      }
    )

    watch(
      () => props.available,
      (val) => {
        if (slider.value) {
          let end = props.total - val - props.value
          if (end < 0 || end > props.total) end = 0
          slider.value.updateOptions({ padding: [0, end] })
        }
      }
    )

    return { props, range }
  },
})
