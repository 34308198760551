//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    loading: Boolean,
  },
  setup(props) {
    return { props }
  },
})
